import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {v1URL} from '../../shared/constants/urls.constants';
import {CommonService} from '../../shared/services/common/common.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private commonServices: CommonService, private router: Router) {
  }

  login(payload): Observable<any> {
    /**
     * Actual API Call
     */
    const {login} = v1URL;
    return this.commonServices.callAPI(login.method, login.url, payload, {withCredentials: true});
  }

  logout(): Observable<any> {
    const {logout} = v1URL;
    return this.commonServices.callAPI(
      logout.method,
      logout.url,
      {},
      {withCredentials: true}
    ) as Observable<any>;
  }

  createAccount(payload): Observable<any> {
    /**
     * Actual API Call
     */
    const {account} = v1URL;
    const createAccount = account.create;
    const url = `${createAccount.url}/${payload.token}`;
    return this.commonServices.callAPI(createAccount.method, url, payload.data);
  }

  updateAccount(payload): Observable<any> {
    return of({
      success: true,
      message: 'Profile Updated Successful',
      data: {
        first_name: 'first name',
        last_name: 'last name',
        title: 'Dr.',
        organization: 'Name of organization',
      },
    });
  }

  forgotPassword(payload): Observable<any> {
    /**
     * Actual API Call
     */
    const {password} = v1URL;
    const forgotPassword = password.forgot;
    return this.commonServices.callAPI(
      forgotPassword.method,
      forgotPassword.url,
      payload
    );
  }

  resetPassword(payload): Observable<any> {
    const {password} = v1URL;
    const resetPassword = password.reset;
    const url = `${resetPassword.url}/${payload.token}`;
    return this.commonServices.callAPI(resetPassword.method, url, payload.data);
  }

  updatePassword(payload): Observable<any> {
    return of({
      success: true,
      message: 'Password Updated Successfully',
      data: {},
    });
  }

  getOrganizationDetails(orgId) {
    const {getOrganizationDetails} = v1URL;
    return this.commonServices.callAPI(
      getOrganizationDetails.method,
      getOrganizationDetails.url(orgId)
    );
  }

  updateProfile(data) {
    const {updateProfile} = v1URL;
    return this.commonServices.callAPI(
      updateProfile.method,
      updateProfile.url,
      data
    ) as Observable<any>;
  }

  getSasTokenForTheProfileImageUpload() {
    const {getSasTokenForProfileImageUpload} = v1URL;
    return this.commonServices.callAPI(
      getSasTokenForProfileImageUpload.method,
      getSasTokenForProfileImageUpload.url
    ) as Observable<any>;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MilestoneConversationThreadService {
  currentConversationId: BehaviorSubject<any> = new BehaviorSubject(null);
  newConversationCreatedSubject: Subject<any> = new Subject();
  setCurrentConversationId(conversationId) {
    this.currentConversationId.next(conversationId);
  }
  newConversationCreated(data) {
    this.newConversationCreatedSubject.next(data);
  }
}

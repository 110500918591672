import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css']
})
export class WorkspaceComponent implements OnInit {
  @Input() workspace:any
  @Output() onWorkspaceSelection:EventEmitter<number>=new EventEmitter()

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  onSelectWorkspace(){
    this.onWorkspaceSelection.emit(this.workspace?.id);
  }

}

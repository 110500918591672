import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-phase-actionsteps',
  templateUrl: './phase-actionsteps.component.html',
  styleUrls: ['./phase-actionsteps.component.css'],
})
export class PhaseActionstepsComponent implements OnInit {
  @Input() actionsteps: any;
  @Input() milestoneName: string;
  @Input() phaseId: any;
  @Input() milestoneId: any;
  @Input() workspaceId: any;
  throttle = 300;
  scrollDistance = 1;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToDetails() {
    this.router.navigate([
      `/changemakers/action-details/workspace/${this.workspaceId}/phase/${this.actionsteps?.phaseId}/milestone/${this.actionsteps?.milestoneId}/actionstep/${this.actionsteps?.id}`,
    ]);
  }
}

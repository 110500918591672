import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import { AuthenticatedWrapperService } from 'src/app/modules/shared/components/application-wrappers/authenticated-wrapper/service/authenticated-wrapper.service';
import * as BaseActions from '../../core/base.actions';
import { MilestoneFilesService } from '../service/milestone-files.service';
import { MilestonePageService } from '../service/milestonePage.service';
import { CommonService } from '../../../shared/services/common/common.service';
import * as BaseSelector from '../../core/base.selectors';

@Component({
  selector: 'app-milestone-page-container',
  templateUrl: './milestone-page-container.component.html',
  styleUrls: ['./milestone-page-container.component.css'],
})
export class MilestonePageContainerComponent implements OnInit, OnDestroy {
  activatedRouteSubscription: Subscription = new Subscription();
  milestoneId: string = '';
  workspaceId: string;
  phaseId: string;
  teamRoles: number[] = [];
  fetchNextActionStepsSubscription: Subscription = new Subscription();
  currentTeamRolesSubscription: Subscription = new Subscription();

  constructor(
    private store: Store,
    private authWrapper: AuthenticatedWrapperService,
    private activatedRoute: ActivatedRoute,
    private milestonePageService: MilestonePageService,
    private fileService: MilestoneFilesService,
    private commonService: CommonService
  ) {
    this.store.dispatch(BaseActions.FetchPhasesMilestonePagesRequest());
    this.activatedRouteSubscription = this.activatedRoute.paramMap
      .pipe(
        tap((params: any) => {
          this.milestoneId = params.params.milestoneId;
          this.workspaceId = params.params.workspaceId;
          this.phaseId = params.params.phaseId;
          this.store.dispatch(
            BaseActions.FetchMilestoneRelatedConversationRequest({
              milestoneId: this.milestoneId,
            })
          );

          this.authWrapper.setBreadCrumb(
            'Back To Collection',
            `/changemakers/collection/workspace/${this.workspaceId}`,
            '#495CEA'
          );
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.fetchNextActionStepsSubscription =
      this.milestonePageService.fetchActionStepsNextPage.subscribe((res) => {
        this.store.dispatch(
          BaseActions.FetchMilestoneActionStepsNextPageRequest({
            milestoneId: this.milestoneId,
            organizationId: this.workspaceId,
            phaseId: this.phaseId,
            teamRoles:this.teamRoles
          })
        );
      });
    this.fetchAllTeamRoles();

    this.currentTeamRolesSubscription = this.store
      .select(BaseSelector.getCurrentSelectedFilterByRoleForCollectionPage)
      .subscribe((data) => {
        if (data) {
          this.teamRoles = data;
          this.fetchMilestones(
            this.workspaceId,
            this.phaseId,
            this.milestoneId,
            this.teamRoles
          );
        }
      });
  }

  fetchMilestones(workspaceId, phaseId, milestoneId, teamRoles) {
    this.store.dispatch(
      BaseActions.FetchMilestonesRequest({
        milestoneId: milestoneId,
        organizationId: workspaceId,
        phaseId: phaseId,
        teamRoles:teamRoles
      })
    );
  }

  fetchAllTeamRoles() {
    const props = { organizationId: this.workspaceId };
    this.store.dispatch(BaseActions.FetchAllTeamRolesRequest(props));
  }

  ngOnDestroy(): void {
    this.authWrapper.cleanBreadCrumb();
    this.activatedRouteSubscription?.unsubscribe();
    this.fetchNextActionStepsSubscription?.unsubscribe();
  }
}

import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../../shared/services/common/common.service';
import {DummyComponent} from '../dummy/dummy.component';
import {UploadFileComponent} from '../../../../shared/components/upload-file/upload-file.component';

@Component({
  selector: 'app-dialogbox-panel',
  templateUrl: './dialogbox-panel.component.html',
  styleUrls: ['./dialogbox-panel.component.css']
})
export class DialogboxPanelComponent implements OnInit {

  constructor(
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
  }

  openBasicDialog() {
    this.commonService.openDialog({data: {component: DummyComponent}})
  }

  openUploadDialog() {
    this.commonService.openDialog({
      data: {
        component: UploadFileComponent,
        maxAllowedUploads: 5
      }
    })
  }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as BaseActions from '../../../core/base.actions';
import {ClearThreads} from '../../../core/base.actions';
import * as BaseSelector from '../../../core/base.selectors';
import {tap} from 'rxjs/operators';
import {MessageService} from '../../service/message.service';
import {ThreadCountService} from '../../service/threads-count.service';
import {GroupsNavigationService} from '../../service/groups-navigation.service';

@Component({
  selector: 'app-chat-threads',
  templateUrl: './chat-threads.component.html',
  styleUrls: ['./chat-threads.component.css'],
})
export class ChatThreadsComponent implements OnInit, OnDestroy {

  isChatOpen = false;

  queryParamsSubscription: Subscription = new Subscription();
  conversationId: string = '';
  conversationType: string = '';
  conversationName: string = '';
  groupId: string = '';
  groupThreadSubscription: Subscription = new Subscription();
  @Output() sendMessageHandler: EventEmitter<any> = new EventEmitter();
  @Input() workspaceId: any;
  threadData: any = {};

  @Output() readLastMessageHandler: EventEmitter<any> = new EventEmitter();
  hasMoreThread: boolean = false;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  threadCountSubscription: Subscription = new Subscription();
  lastMessageReadId: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router,
    private messageService: MessageService,
    private threadCountService: ThreadCountService,
    private groupNavigationService: GroupsNavigationService
  ) {
    this.store.dispatch(ClearThreads({}));
  }

  ngOnDestroy(): void {
    this.threadCountSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.groupThreadSubscription = this.store
      .select(BaseSelector.getAllThreadsForAConversation)
      .pipe(
        tap((res) => {
          this.hasMoreThread = res.hasMore;

          this.threadData = res;
        })
      )
      .subscribe();

    this.queryParamsSubscription = this.activatedRoute.queryParamMap.subscribe(
      (params: any) => {
        const limit = params?.params?.page_size || 10;


        this.conversationId = params.params.conversationId
          ? params.params.conversationId
          : '';
        this.conversationType = params.params.conversationType
          ? params.params.conversationType
          : '';
        this.conversationName = params.params.conversationName
          ? params.params.conversationName
          : '';
        this.groupId = params.params.groupId;

        if (this.conversationType === 'groups') {
          if (params.params.fetchThreads === 'no') {
            /* nothing */
          } else {
            this.messageService.setThreadCurrentPage(1);
            this.store.dispatch(
              BaseActions.FetchAllThreadsForGroupsConversationRequest({
                groupId: this.groupId,
              })
            );
          }
        }
        if (this.conversationType === 'direct' && this.conversationId) {
          this.store.dispatch(
            BaseActions.fetchAllMessageForConversationIdRequest({
              conversationId: this.conversationId,
            })
          );
        }
        if (this.conversationType === 'groups' && this.conversationId) {
          this.store.dispatch(
            BaseActions.fetchAllMessageForConversationIdRequest({
              conversationId: this.conversationId,
            })
          );
        }

        // if (this.conversationType === 'groups' && this.groupId && !this.threadData?.threads?.length) {
        //   this.messageService.setThreadCurrentPage(0);
        // }
        //
        // if (this.messageService.threadCurrentPage.value === 0 || this.hasMoreThread) {
        //   this.store.dispatch(
        //     BaseActions.FetchAllThreadsForGroupsConversationRequest({
        //       groupId: this.groupId,
        //       limit: Number(limit)
        //     })
        //   );
        // }
      }
    );

    this.updateThreadReadUnreadCount();
  }

  openChat(thread) {
    const params: any = {
      conversationType: 'groups',
      conversationId: thread.id,
      conversationName: thread.name,
      // fetchThreads: 'no',
      groupId: +this.groupId,
    };
    this.groupNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: params,
        fragment: 'thread-' + thread.id
      }
    ).then(() => {
      this.isChatOpen = true;
    });
  }

  closeChatHandler() {
    const data = {
      conversationType: 'groups',
      fetchThreads: 'no',
      groupId: +this.groupId,
    };
    const previousSetPrams = {
      conversationType: 'groups',

      groupId: +this.groupId,
    };
    this.groupNavigationService.setGroupPreviousSelected(previousSetPrams);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: data,
      }
    ).then(() => {
      this.isChatOpen = false;
    });
  }

  createNewThread(event) {
    const updatedData = {...event, group_id: this.groupId};
    this.store.dispatch(
      BaseActions.CreateNewChatThreadForGroupRequest({payload: updatedData})
    );
  }

  loadMoreThreads() {
    if (this.hasMoreThread === true) {
      this.store.dispatch(
        BaseActions.FetchAllThreadsForGroupsConversationNextPageRequest({
          groupId: this.groupId,
        })
      );
    }
  }

  searchUserHandler(event) {
    const updatedData = {...event, workspaceId: this.workspaceId};
    this.store.dispatch(BaseActions.SearchUserRequest(updatedData));
  }

  sendMessage(event) {
    this.sendMessageHandler.emit(event);
  }

  readMessageHandler(event) {
    if (event !== this.lastMessageReadId) {
      this.readLastMessageHandler.emit(event);
      this.lastMessageReadId = event;
    }
  }

  updateThreadReadUnreadCount() {
    this.threadCountSubscription =
      this.threadCountService.readUnreadEventSubject.subscribe(
        (threadEventData) => {
          if (threadEventData) {
            let updatedThreads = {...this.threadData};
            const updatedAllThreads = this.threadData.threads.map((thread) =>
              thread.id == threadEventData.conversation_id
                ? {...thread, unreadMessages: threadEventData.unread_messages}
                : {...thread}
            );
            updatedThreads = {
              ...updatedThreads,
              threads: updatedAllThreads,
            };
            this.threadData = updatedThreads;
          }
        }
      );
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GroupChatService {
  chatSubject: BehaviorSubject<any[]> = new BehaviorSubject(null);
  newConversationChat: BehaviorSubject<any> = new BehaviorSubject(null);
  markDirectConversationAsReadSubject: BehaviorSubject<any> = new BehaviorSubject(null)
  markGroupThreadConversationAsReadSubject: BehaviorSubject<any> = new BehaviorSubject(null)

  constructor() {
  }

  addMessage(data) {
    this.chatSubject.next(data);
  }

  addNewConversation(data) {
    this.newConversationChat.next(data);
  }

  markConversationAsRead(data) {
    this.markDirectConversationAsReadSubject.next(data)
  }

  markGroupThreadConversationAsRead(data) {
    this.markGroupThreadConversationAsReadSubject.next(data)
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mention-user',
  templateUrl: './mention-user.component.html',
  styleUrls: ['./mention-user.component.css']
})
export class MentionUserComponent implements OnInit {

  constructor() { }
  @Input() userData:any

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-date-picker-panel',
  templateUrl: './date-picker-panel.component.html',
  styleUrls: ['./date-picker-panel.component.css']
})
export class DatePickerPanelComponent implements OnInit {
  datePickerForm: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.datePickerForm = this.fb.group({
      date: new FormControl({value: '', disabled: false}, [Validators.required])
    });
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, Subscription, tap} from 'rxjs';
import {CommonService} from 'src/app/modules/shared/services/common/common.service';
import {FetchFileAccordingToMilestoneRequest, ResetMilestoneFileList,} from '../../../core/base.actions';
import {getAllFilesRelatedToMilestone} from '../../../core/base.selectors';
import {MilestoneFilesService} from '../../service/milestone-files.service';

@Component({
  selector: 'app-milestone-files',
  templateUrl: './milestone-files.component.html',
  styleUrls: ['./milestone-files.component.css'],
})
export class MilestoneFilesComponent implements OnInit, OnDestroy {
  milestoneId;
  phaseId;
  workspaceId;
  milestoneRelatedFiles$: Observable<any> = new Observable();
  throttle = 300;
  scrollDistance = 1;
  hasMore: boolean = false;
  lastActionStepId: number = 0;
  actionStepDocument;
  paramsSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store,
    public commonService: CommonService,
    private fileService: MilestoneFilesService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.paramsSubscription = this.activatedRoute.paramMap.subscribe(
      (params: any) => {
        this.store.dispatch(ResetMilestoneFileList());
        this.fileService.setCurrentPageNumber(0);
        this.milestoneId = params?.params?.milestoneId;
        this.phaseId = params?.params?.phaseId;
        this.workspaceId = params?.params?.workspaceId;
        this.store.dispatch(
          FetchFileAccordingToMilestoneRequest({
            milestoneId: this.milestoneId,
          })
        );
      }
    );

    this.milestoneRelatedFiles$ = this.store
      .select(getAllFilesRelatedToMilestone)
      .pipe(
        tap((files) => {
          if (files?.documents?.length) {
            const lastIndex = files?.documents.length - 1;
            this.hasMore = files.hasMore;
            this.lastActionStepId = files.documents[lastIndex]?.actionStepId;
            this.actionStepDocument = files.documents;
          }
        })
      );
  }

  navigateToActionStep(actionStepId) {
    this.router.navigate([
      `/changemakers/action-details/workspace/${this.workspaceId}/phase/${this.phaseId}/milestone/${this.milestoneId}/actionstep/${actionStepId}`,
    ]);
  }

  onScrollDown() {
    if (this.hasMore) {
      this.store.dispatch(
        FetchFileAccordingToMilestoneRequest({
          milestoneId: this.milestoneId,
        })
      );
    }
  }

  checkVisibility(currentIndex, ActionStepId) {
    if (currentIndex === 0) {
      return true;
    } else if (
      this.actionStepDocument[currentIndex - 1]?.actionStepId !== ActionStepId
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.fileService.setCurrentPageNumber(0);
    this.store.dispatch(ResetMilestoneFileList());
    this.paramsSubscription?.unsubscribe();
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {CreateAccountService} from '../../services/create-account.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators,} from '@angular/forms';
import {CommonService} from '../../../../../shared/services/common/common.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.css'],
})
export class CreateAccountFormComponent implements OnInit {
  @Input() formValues: any;
  @Input() token: any;
  @Input() organizationId: any;

  form: FormGroup;

  errorMessageMap = {
    firstName: {
      required: 'This field is required.',
    },
    lastName: {
      required: 'This field is required.',
    },
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email',
    },
    organization: {
      required: 'This field is required.',
    },
    title: {
      required: 'This field is required.',
    },
    password: {
      required: 'This field is required.',
      pattern: 'Invalid Password',
      minlength: 'Password should be at least 8 characters long',
    },
    confirmPassword: {
      required: 'This field is required.',
      pattern: 'Invalid Password',
      minlength: 'Password should be at least 8 characters long',
      passwordMatchFailed: 'Password and confirm password do not match',
    },
  };

  errorMessages: any = {};

  constructor(
    private createAccountService: CreateAccountService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        firstName: new FormControl(
          {
            value: this.formValues?.firstName || '',
            disabled: false,
          },
          [Validators.required]
        ),
        lastName: new FormControl(
          {
            value: this.formValues?.lastName || '',
            disabled: false,
          },
          [Validators.required]
        ),
        email: new FormControl(
          {
            value: this.formValues?.email || '',
            disabled: !!this.formValues?.email,
          },
          [
            Validators.required,
            Validators.pattern(this.commonService.emailRegex),
          ]
        ),
        organization: new FormControl(
          {
            value: this.formValues?.organization || '',
            disabled: !!this.formValues?.organization,
          },
          [Validators.required]
        ),
        title: new FormControl(
          {
            value: this.formValues?.title || '',
            disabled: !!this.formValues?.title,
          },
          [Validators.required]
        ),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.commonService.passwordRegex),
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.commonService.passwordRegex),
        ]),
      },
      {validators: [this.validatePassword]}
    );
  }

  createAccount(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      firstName,
      lastName,
      email,
      organization,
      title,
      password,
      confirmPassword,
    } = this.form.getRawValue();

    const payload = {
      token: this.token,
      data: {
        email,
        user: {
          password,
          password_confirmation: confirmPassword,
          profile_attributes: {
            first_name: firstName,
            last_name: lastName,
            title: title,
          },
        },
      },
    };

    this.createAccountService.createAccount.emit(payload);
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('confirmPassword')) {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;

      if (password && confirmPassword && password !== confirmPassword) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors
            ? control.get('confirmPassword').errors
            : {}),
          passwordMatchFailed: true,
        });
      }
    }
    return null;
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { v1URL } from '../../shared/constants/urls.constants';
import { CommonService } from '../../shared/services/common/common.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(private commonServices: CommonService, private store: Store) {}

  fetchActionDetails(
    organizationId: string,
    phaseId: string,
    milestoneId: string,
    actionStepId: string
  ): Observable<any> {
    const { actionDetailsPage } = v1URL;
    return this.commonServices.callAPI(
      actionDetailsPage.method,
      actionDetailsPage.url(organizationId, phaseId, milestoneId, actionStepId)
    );
  }

  /* needs to be rewritten  */
  fetchMilestoneDetails(
    organizationId: string,
    phaseId: string,
    milestoneId: string,
    page: number,
    teamRoles: string
  ): Observable<any> {
    const { milestoneDetailsActionSteps } = v1URL;
    /*   return this.store.select(BaseSelector.getDetailsConfig).pipe(
      exhaustMap((state) => {
        return (

      })
    ); */
    return (
      this.commonServices.callAPI(
        milestoneDetailsActionSteps.method,
        milestoneDetailsActionSteps.url(
          organizationId,
          phaseId,
          milestoneId,
          page,
          teamRoles
        )
      ) as Observable<any>
    ).pipe(
      mergeMap((actionsSteps) => {
        const { milestoneDetails } = v1URL;
        return (
          this.commonServices.callAPI(
            milestoneDetails.method,
            milestoneDetails.url(organizationId, phaseId, milestoneId)
          ) as Observable<any>
        ).pipe(
          map((milestoneDetails) => {
            return {
              milestoneDetails: {
                ...milestoneDetails.data,
              },
              ...actionsSteps,
            };
          })
        );
      })
    );
  }

  fetchMilestoneActionStepsNextPage(
    organizationId,
    phaseId,
    milestoneId,
    page,
    teamRoles
  ) {
    const { milestoneDetailsActionSteps } = v1URL;
    return this.commonServices.callAPI(
      milestoneDetailsActionSteps.method,
      milestoneDetailsActionSteps.url(
        organizationId,
        phaseId,
        milestoneId,
        page,
        teamRoles
      )
    );
  }

  fetchAllPhases(orgId: string) {
    const { allPhases } = v1URL;
    return this.commonServices.callAPI(
      allPhases.method,
      allPhases.url(orgId)
    ) as Observable<any>;
  }

  fetchPhasesMilestone(orgId: string, phaseId: string, page: any, teamRoles) {
    const { phasesMileStone } = v1URL;
    return this.commonServices.callAPI(
      phasesMileStone.method,
      phasesMileStone.url(orgId, phaseId, page, teamRoles)
    ) as Observable<any>;
  }

  fetchActionsStepsAccordingToPhases(
    organizationId,
    phaseId,
    milestoneId,
    page?,
    teamRole?
  ) {
    const pageNo = page ? page : 1;
    const { milestoneDetailsActionSteps } = v1URL;
    return this.commonServices.callAPI(
      milestoneDetailsActionSteps.method,
      milestoneDetailsActionSteps.url(
        organizationId,
        phaseId,
        milestoneId,
        pageNo,
        teamRole
      )
    ) as Observable<any>;
  }

  fetchAllNextPhases(orgId: string, pageNumber: any) {
    const { allNextPhases } = v1URL;
    return this.commonServices.callAPI(
      allNextPhases.method,
      allNextPhases.url(orgId, pageNumber)
    ) as Observable<any>;
  }

  fetchDashboardMileStones(workspace, page?) {
    const pageNumber = page ? page : 1;
    const { fetchDashboardMilestone } = v1URL;
    return this.commonServices.callAPI(
      fetchDashboardMilestone.method,
      fetchDashboardMilestone.url(workspace, pageNumber)
    ) as Observable<any>;
  }

  fetchAllTeamRoles(workspaceId: string) {
    const { fetchAllTeamRoles } = v1URL;
    return this.commonServices.callAPI(
      fetchAllTeamRoles.method,
      fetchAllTeamRoles.url(workspaceId)
    ) as Observable<any>;
  }

  fetchDashboardWeekData(workspace, date) {
    const data = {
      workspace: workspace,
      date: date,
    };
    const { fetchDashBoardWeekData } = v1URL;
    return this.commonServices.callAPI(
      fetchDashBoardWeekData.method,
      fetchDashBoardWeekData.url(data)
    ) as Observable<any>;
  }

  fetchSasToken(payload) {
    const { fetchSasToken } = v1URL;
    return this.commonServices.callAPI(
      fetchSasToken.method,
      fetchSasToken.url(payload)
    ) as Observable<any>;
  }

  submitParticipantMilestoneDeliverableSuccess(payload) {
    const { submitParticipantMilestoneDeliverableSuccess } = v1URL;
    return this.commonServices.callAPI(
      submitParticipantMilestoneDeliverableSuccess.method,
      submitParticipantMilestoneDeliverableSuccess.url(payload.urlParams),
      payload.data
    ) as Observable<any>;
  }

  fetchDirectoryTeams(workspaceId, page) {
    const { getDirectoryTeams } = v1URL;
    return this.commonServices.callAPI(
      getDirectoryTeams.method,
      getDirectoryTeams.url(workspaceId, page)
    ) as Observable<any>;
  }

  fetchDirectoryCohort(workspaceId, page, filter?) {
    const { getDirectoryCohort } = v1URL;
    return this.commonServices.callAPI(
      getDirectoryCohort.method,
      getDirectoryCohort.url(workspaceId, page, filter)
    ) as Observable<any>;
  }

  fetchDirectoryCohortWithoutFilters(workspaceId, page) {
    const { getDirectoryCohort } = v1URL;
    return this.commonServices.callAPI(
      getDirectoryCohort.method,
      getDirectoryCohort.url(workspaceId, page)
    ) as Observable<any>;
  }

  fetchAllTeams(workspace) {
    const { getAllTeams } = v1URL;
    return this.commonServices.callAPI(
      getAllTeams.method,
      getAllTeams.url(workspace)
    ) as Observable<any>;
  }

  fetchAllDirectMessage() {
    const { getAllDirectMessages } = v1URL;
    return this.commonServices.callAPI(
      getAllDirectMessages.method,
      getAllDirectMessages.url
    ) as Observable<any>;
  }

  fetchAllMessages(conversationId) {
    const { getAllMessages } = v1URL;
    return this.commonServices.callAPI(
      getAllMessages.method,
      getAllMessages.url(conversationId)
    ) as Observable<any>;
  }

  fetchAllMessagesNextPage(conversationId, pageNumber) {
    const { getAllMessagesNextPage } = v1URL;
    return this.commonServices.callAPI(
      getAllMessagesNextPage.method,
      getAllMessagesNextPage.url(conversationId, pageNumber)
    ) as Observable<any>;
  }

  fetchAllGroups() {
    const { getAllGroupsForConversation } = v1URL;
    return this.commonServices.callAPI(
      getAllGroupsForConversation.method,
      getAllGroupsForConversation.url
    ) as Observable<any>;
  }

  fetchAllThreadsForGroupsConversation(groupId, page, limit = 10) {
    const { getAllThreadsForGroups } = v1URL;
    return this.commonServices.callAPI(
      getAllThreadsForGroups.method,
      getAllThreadsForGroups.url(groupId, page)
    ) as Observable<any>;
  }

  createNewConversation(payload) {
    const { createConversation } = v1URL;
    return this.commonServices.callAPI(
      createConversation.method,
      createConversation.url,
      payload
    ) as Observable<any>;
  }

  searchUser(workspace, searchedTerm) {
    const { searchUser } = v1URL;
    return this.commonServices.callAPI(
      searchUser.method,
      searchUser.url(workspace, searchedTerm)
    ) as Observable<any>;
  }
  searchUserForMentions(workspace, searchedTerm, conversationId) {
    const { searchUserForMentions } = v1URL;
    return this.commonServices.callAPI(
      searchUserForMentions.method,
      searchUserForMentions.url(workspace, searchedTerm, conversationId)
    ) as Observable<any>;
  }
  searchUserForMentionInTheGroup(workspace, searchedTerm, conversationId) {
    const { searchUserForMentionsForNewGroups } = v1URL;
    return this.commonServices.callAPI(
      searchUserForMentionsForNewGroups.method,
      searchUserForMentionsForNewGroups.url(
        workspace,
        searchedTerm,
        conversationId
      )
    ) as Observable<any>;
  }

  fetchUserProfileForDirectoryPage(workspace, userId) {
    const { userProfileDirectory } = v1URL;
    return this.commonServices.callAPI(
      userProfileDirectory.method,
      userProfileDirectory.url(workspace, userId)
    ) as Observable<any>;
  }

  fetchChatSasToken(payload) {
    const { fetchChatSasToken } = v1URL;
    return this.commonServices.callAPI(
      fetchChatSasToken.method,
      fetchChatSasToken.url(payload)
    ) as Observable<any>;
  }
  fetchMilestoneRelatedConversation(milestoneId) {
    const { fetchMilestoneRelatedConversation } = v1URL;
    return this.commonServices.callAPI(
      fetchMilestoneRelatedConversation.method,
      fetchMilestoneRelatedConversation.url(milestoneId)
    ) as Observable<any>;
  }
  fetchAllThreadForMilestoneConversation(groupId, milestoneId) {
    const { fetchAllMilestoneRelatedThreads } = v1URL;
    return this.commonServices.callAPI(
      fetchAllMilestoneRelatedThreads.method,
      fetchAllMilestoneRelatedThreads.url(groupId, milestoneId)
    ) as Observable<any>;
  }
  fetchCurrentLoggedInUserProfile() {
    const { fetchUsersProfile } = v1URL;
    return this.commonServices.callAPI(
      fetchUsersProfile.method,
      fetchUsersProfile.url
    ) as Observable<any>;
  }
  getTheUnreadCountForTheGroups() {
    const { fetchUnreadMessageCountForTheGroupIcon } = v1URL;
    return this.commonServices.callAPI(
      fetchUnreadMessageCountForTheGroupIcon.method,
      fetchUnreadMessageCountForTheGroupIcon.url
    ) as Observable<any>;
  }

  getFilesAccordingToMilestone(milestoneId, pageNumber) {
    const { fetchFilesAccordingToMilestone } = v1URL;
    return this.commonServices.callAPI(
      fetchFilesAccordingToMilestone.method,
      fetchFilesAccordingToMilestone.url(milestoneId, pageNumber)
    ) as Observable<any>;
  }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-date-time-picker-panel',
  templateUrl: './date-time-picker-panel.component.html',
  styleUrls: ['./date-time-picker-panel.component.css']
})
export class DateTimePickerPanelComponent implements OnInit {
  dateTimePickerForm: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.dateTimePickerForm = this.fb.group({
      dateTime: new FormControl({value: '', disabled: false}, [Validators.required])
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../shared/services/common/common.service';

@Component({
  selector: 'app-input-panel',
  templateUrl: './input-panel.component.html',
  styleUrls: ['./input-panel.component.css']
})
export class InputPanelComponent implements OnInit {
  inputForm: FormGroup;

  errorMessageMap = {
    textValue: {
      required: 'This field is required.',
    },
    numericValue: {
      required: 'This field is required.',
    },
    passwordValue: {
      required: 'This field is required.',
    },
    phoneNumberValue: {
      required: 'This field is required.',
    },
    textWithIconValue: {
      required: 'This field is required.',
    },
    numericWithIconValue: {
      required: 'This field is required.',
    },
    passwordWithIconValue: {
      required: 'This field is required.',
    },
    phoneNumberWithIconValue: {
      required: 'This field is required.',
    }
  };

  errorMessages: any = {};

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.inputForm = this.fb.group({
      textValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      numericValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      passwordValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      phoneNumberValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      textWithIconValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      numericWithIconValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      passwordWithIconValue: new FormControl({value: '', disabled: false}, [Validators.required]),
      phoneNumberWithIconValue: new FormControl({value: '', disabled: false}, [Validators.required])
    });
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.inputForm, this.errorMessageMap, currentField))
    };
  }

  validateForm() {
    if (this.inputForm.invalid) {
      this.checkForErrors();
      return;
    }
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {getAllWorkspaces, getSelectedWorkspace,} from 'src/app/modules/authentication/core/authentication.selectors';
import * as BaseAction from '../../../../../../base/core/base.actions';
import {SetSelectedWorkSpaceId} from '../../../../../../base/core/base.actions';
import * as BaseSelector from '../../../../../../base/core/base.selectors';
import * as AuthActions from '../../../../../../authentication/core/authentication.actions';
import {Router} from '@angular/router';
import {GroupsNavigationService} from '../../../../../../base/groups-page/service/groups-navigation.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() workspaceId: any;

  menuItems$: any = {
    mainMenu: [
      {
        name: 'Dashboard',
        icon: 'explore',
        path: (workspaceId) => '/changemakers/dashboard/workspace/' + workspaceId
      },
      {
        name: 'Collections',
        icon: 'book-open',
        path: (workspaceId) => '/changemakers/collection/workspace/' + workspaceId
      },
      {
        name: 'My Groups',
        icon: 'users',
        path: (workspaceId) => `/workspace/${workspaceId}/groups`
      },
      {
        name: 'Directory',
        icon: 'folder',
        path: (workspaceId) => '/changemakers/directory/workspace/' + workspaceId
      }
    ],
    config: []
  };
  unreadMessage$: Observable<any> = new Observable();

  AllPossibleWorkspaces: any[] = [];
  allWorkspaceSubscription: Subscription = new Subscription();
  currentWorkspaceSubscription: Subscription;
  currentWorkspace: any;

  constructor(
    private store: Store,
    private router: Router,
    private groupsNavigationService: GroupsNavigationService
  ) {
  }

  ngOnInit(): void {
    this.currentWorkspaceSubscription = this.store.select(getSelectedWorkspace).subscribe(data => {
      if (data) {
        this.currentWorkspace = data;
      } else {
        this.router.navigate(['/workspace']);
      }
    })
    this.store.dispatch(BaseAction.FetchUnreadCountGroupsIconsRequest());
    this.unreadMessage$ = this.store.select(
      BaseSelector.getAllSideNavUnreadMessageCount
    );
    this.allWorkspaceSubscription = this.store.select(getAllWorkspaces).subscribe((data) => {
      this.AllPossibleWorkspaces = data;
    });
  }

  onChangeWorkspace(workspace) {
    this.store.dispatch(
      AuthActions.ChangeSelectedWorkspace({workspace})
    );
    this.store.dispatch(
      SetSelectedWorkSpaceId({SelectedWorkspaceId: workspace.id})
    );
    this.store.dispatch(BaseAction.ResetPhasesMilestoneToDefaultState());
    this.router.navigate([`/changemakers/dashboard/workspace/${workspace.id}`]).then(() => {
      this.groupsNavigationService.groupsPreviousNavigationSubject.next(null)
    });
  }

  ngOnDestroy(): void {
    this.allWorkspaceSubscription?.unsubscribe();
    this.currentWorkspaceSubscription?.unsubscribe();
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MessageService {
  messageCurrentPage: BehaviorSubject<any> = new BehaviorSubject(1);
  threadCurrentPage: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
  }

  setCurrentMessagePageNumber(number: any) {
    this.messageCurrentPage.next(number);
  }

  setThreadCurrentPage(page) {
    this.threadCurrentPage.next(page);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MilestoneMessageService {
  constructor() {}
  milestoneConversationThreadMessagesCurrentPage: BehaviorSubject<number> =
    new BehaviorSubject(1);
  setMilestoneConversationThreadMessageCurrentPageNumber(pageNumber: number) {
    this.milestoneConversationThreadMessagesCurrentPage.next(pageNumber);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-samples-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SamplesSidenavListComponent implements OnInit {
  @Input() selectedType: string;
  @Output() selectType = new EventEmitter();

  sidenavListOptions = [
    {
      type: 'input',
      name: 'Input'
    },
    {
      type: 'button',
      name: 'Button'
    },
    {
      type: 'dropdown',
      name: 'Dropdown'
    },
    {
      type: 'date-picker',
      name: 'Date Picker'
    },
    {
      type: 'date-time-picker',
      name: 'Date-Time Picker'
    },
    {
      type: 'loading-screen',
      name: 'Loading Screen'
    },
    {
      type: 'dialog-box-screen',
      name: 'Dialog Box Screen'
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

  selectItem(type) {
    this.selectedType = type;
    this.selectType.emit(type);
  }

}

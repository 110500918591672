import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as BaseSelector from '../../core/base.selectors';
import * as BaseAction from '../../core/base.actions';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard-screen',
  templateUrl: './dashboard-screen.component.html',
  styleUrls: ['./dashboard-screen.component.css'],
})
export class DashboardScreenComponent implements OnInit {
  phases$: Observable<any> = new Observable();
  @Input() workspace: string

  constructor(private store: Store, private router: Router) {
  }

  ngOnInit(): void {
    this.phases$ = this.store.select(BaseSelector.getDashboardPhases);
  }

  loadMoreHandler() {
    this.store.dispatch(BaseAction.FetchDashboardPhasesNextPageRequest({organizationId: this.workspace}));
  }

  onClickPhasesHandler(event) {
    const params = {
      phase: event.id,
    };
    this.router.navigate(['/changemakers/collection/workspace/' + this.workspace], {queryParams: params});
  }

  milestoneClickedHandler(event) {
    this.store.dispatch(BaseAction.SetSelectedPhase({phaseId: event.phaseId}))
    this.router.navigate([`/changemakers/milestone-details/workspace/${this.workspace}/phase/${event.phaseId}/milestone/${event.milestoneId}`]);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventEmitter } from 'stream';

@Injectable({
  providedIn: 'root',
})
export class DirectoryService {
  teamsCurrentPage: BehaviorSubject<number> = new BehaviorSubject(1);
  cohortCurrentPage: BehaviorSubject<number> = new BehaviorSubject(1);
  directMessageToUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  updateEventEmitter: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {}

  setTeamsCurrentPageNumber(pageNumber) {
    this.teamsCurrentPage.next(pageNumber);
  }

  setCohortCurrentPageNumber(pageNumber) {
    this.cohortCurrentPage.next(pageNumber);
  }

  setDirectMessageToUser(userData) {
    this.directMessageToUserSubject.next(userData);
  }

  setEmptyToDirectMessage() {
    this.directMessageToUserSubject.next(null);
  }
  updateDirectoryPage() {
    this.updateEventEmitter.next(true);
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractFormGroupDirective,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';

@Component({
  selector: 'app-milestone-new-conversation-channel',
  templateUrl: './milestone-new-conversation-channel.component.html',
  styleUrls: ['./milestone-new-conversation-channel.component.css'],
})
export class MilestoneNewConversationChannelComponent implements OnInit {
  ngOnInit(): void {}
  @Output() messageSenderHandler: EventEmitter<any> = new EventEmitter();
  onMessageSendHandler(event) {
    this.messageSenderHandler.emit(event);
  }
}

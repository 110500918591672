import * as BaseActions from './base.actions';
import {createReducer, on} from '@ngrx/store';
import {DashboardPageState} from './interface/dashboardPageState.interface';
import {DirectoryStateModal} from './interface/directory-state.interface';
import {GroupPageModel} from './interface/groupPage.interface';
import {milestoneConversation} from './interface/milestone-conversation.interface';
import * as AuthActions from '../../authentication/core/authentication.actions';

interface ActionSteps {
  name?: string;
  description?: string;
  assignToRoles?: string;
  approximateTime?: string;
  dueDate?: string;
  materialsNeeded?: string;
  content?: string;
  id?: string;
}

interface MilestoneDetails {
  name?: string;
  description?: string;
  assignToRoles?: any;
  assignToMembers?: any;
  shortDescription?: string;
  dueDate?: string;
  deliverableAttributes?: MilestoneDeliverableAttributes[];
  actionSteps?: ActionSteps[];
  actionStepsLoading?: boolean;
  id?: string;
  actionHasMore?: boolean;
  deliverables?: any;
}

interface MilestoneDeliverableAttributes {
  id?: string;
  name?: NamedCurve;
  category?: string;
  description?: string;
  deliverableContent?: any;
}

interface Phase {
  id: string;
  name: string;
  shortDescription: string;
  active: boolean;
  selected?: boolean;
}

interface CollectionPage {
  phase: {
    phases: Phase[];
    hasMore: boolean;
    preSelected: string;
  };
  milestones: {
    hasMore: boolean;
    milestones: MilestoneDetails[];
  };
  teamRoles: any[];
  milestoneLoaded: boolean;
  selectedFilterRole: any;
}

interface SasToken {
  host: string;
  container: string;
  token: string;
}

export interface MilestoneDocuments {
  actionStepId: number;
  actionStepName: string;
  actionStepDocuments: ActionStepDocument[];
}

interface ActionStepDocument {
  type: string;
  name: string;
  description: string;
  link: string;
  uploadedFileName: string;
  uploadedFileType: string;
}

interface MilestoneFiles {
  documents: MilestoneDocuments[];
  hasMore: boolean;
}

// State for this feature (User)
export interface BaseState {
  dummy: string;
  actionStepPage: {
    name: string;
    description: string;
    assignToRoles: any;
    assignToMembers: any;
    approximateTime: string;
    dueDate: string;
    materialsNeeded: string;
    content: string;
    nextActionStep: any;
    previousActionStep: any;
  };
  milestonePage: {
    milestoneDetails: MilestoneDetails;
    actionSteps?: ActionSteps[];
    hasMore: boolean;
    page: number;
    phase: {
      phases: Phase[];
      hasMore: boolean;
    };
    milestoneFiles: MilestoneFiles;
  };
  detailConfig: {
    selectedWorkspaceId: string;
    selectedPhaseId: string;
    selectedMilestoneId: string;
    selectedActionStepId: string;
  };
  collectionPage: CollectionPage;
  dashboardPage: DashboardPageState;
  sasToken: SasToken;
  directoryPage: DirectoryStateModal;
  groupPage: GroupPageModel;
  chatSasToken: SasToken;
  milestoneConversation: milestoneConversation;
  userProfile: any;
  workspaceUnreadMessages: boolean;
}

const initialState: BaseState = {
  dummy: '',
  actionStepPage: {
    name: '',
    description: '',
    assignToRoles: '',
    assignToMembers: '',
    approximateTime: '',
    dueDate: '',
    materialsNeeded: '',
    content: '',
    nextActionStep: null,
    previousActionStep: null,
  },
  milestonePage: {
    actionSteps: [],
    hasMore: true,
    milestoneDetails: {},
    page: 1,
    phase: {
      hasMore: null,
      phases: [],
    },

    milestoneFiles: {
      documents: [],
      hasMore: false,
    },
  },
  detailConfig: {
    selectedWorkspaceId: '',
    selectedPhaseId: '',
    selectedMilestoneId: '',
    selectedActionStepId: '',
  },
  collectionPage: {
    phase: {
      hasMore: true,
      phases: [],
      preSelected: '',
    },
    milestones: {
      hasMore: true,
      milestones: [],
    },
    teamRoles: [],
    milestoneLoaded: false,
    selectedFilterRole: null,
  },
  dashboardPage: {
    phase: {hasMore: true, phases: []},
    milestone: {hasMore: true, milestones: []},
    calender: {
      loading: false,
      calender: [],
    },
  },
  sasToken: null,
  directoryPage: {
    cohort: {
      cohorts: [],
      hasMore: false,
    },
    team: {
      hasMore: false,
      teams: [],
    },
    teamRoles: [],
    teams: [],
    userProfileData: {},
    userProfileLoadingState: false,
  },
  groupPage: {
    directMessage: [],
    groupsMessage: [],
    allTeams: [],
    message: {
      hasMore: false,
      messages: [],
    },
    threads: {
      hasMore: false,
      threads: [],
    },
    myCohort: {},
    myTeam: {},
    searchUsers: [],
    mentionsOptions: [],
  },
  chatSasToken: null,
  milestoneConversation: {conversation: {}, groups: {}, thread: {}},
  userProfile: {},
  workspaceUnreadMessages: false,
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(BaseActions.ClearState, (state) => initialState),
  on(BaseActions.ClearThreads, (state) => ({
    ...state,
    groupPage: {
      ...state.groupPage,
      threads: initialState.groupPage.threads
    }
  })),

  on(BaseActions.FetchActionDetailsRequest, (state, props) => {
    return {
      ...state,
      detailConfig: {
        ...state.detailConfig,
        selectedActionStepId: props.payload.actionId,
      },
      actionStepPage: {
        ...state.actionStepPage,
      },
    };
  }),
  on(BaseActions.FetchActionDetailsSuccess, (state, props) => ({
    ...state,
    actionStepPage: {...state.actionStepPage, ...props},
  })),
  on(BaseActions.FetchActionDetailsFailure, (state) => state),
  on(BaseActions.FetchMilestonesRequest, (state, props) => ({
    ...state,
    detailConfig: {
      ...state.detailConfig,
      selectedMilestoneId: props.milestoneId,
    },
  })),
  on(BaseActions.FetchMilestonesSuccess, (state, props) => ({
    ...state,
    milestonePage: {
      ...state.milestonePage,
      milestoneDetails: {
        ...state.milestonePage.milestoneDetails,
        ...props.milestoneDetails,
      },
      actionSteps: props.data.actionSteps,
      hasMore: props.data.hasMore,
    },
  })),
  on(BaseActions.FetchMilestoneFailure, (state) => state),
  on(BaseActions.FetchMilestoneActionStepsNextPageSuccess, (state, props) => {
    return {
      ...state,
      milestonePage: {
        ...state.milestonePage,
        actionSteps: [
          ...state.milestonePage.actionSteps,
          ...props.data.actionSteps,
        ],
        hasMore: props?.data?.hasMore,
      },
    };
  }),
  on(BaseActions.FetchPhasesSuccess, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        phase: {
          ...state.collectionPage?.phase,
          phases: [...props.data.phases],
          hasMore: props.data.hasMore,
          preSelected: props.data.preselect,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesRequest, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestoneLoaded: false,
      },
    };
  }),
  on(BaseActions.FetchPhasesMilestonesRequest, (state, props) => {
    const selectedPhaseId: any = props.phase;

    const phase = state.collectionPage.phase.phases.map((eachPhase) =>
      selectedPhaseId !== eachPhase?.id.toString()
        ? {...eachPhase, selected: false}
        : {...eachPhase, selected: true}
    );

    return {
      ...state,
      detailConfig: {
        ...state.detailConfig,
        selectedPhaseId: props.phase,
        selectedMilestoneId: '',
        selectedActionStepId: '',
      },
      collectionPage: {
        ...state.collectionPage,
        phase: {...state.collectionPage.phase, phases: [...phase]},
        milestoneLoaded: false,
        /* milestones: { hasMore: true, milestones: [] }, */
      },
    };
  }),
  on(BaseActions.FetchPhasesMilestonesSuccess, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          hasMore: props.data.hasMore,
          milestones: [...props.data.milestones],
        },
        milestoneLoaded: true,
      },
    };
  }),
  on(BaseActions.FetchPhasesMilestonesRequest, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          hasMore: false,
          milestones: [],
        },
        milestoneLoaded: false,
      },
    };
  }),
  on(BaseActions.FetchPhasesActionStepsRequest, (state, props) => {
    let mileStonesCopy = [...state.collectionPage.milestones.milestones];
    mileStonesCopy = mileStonesCopy.map((mileStones: any) => {
      if (mileStones.id === props.milestoneId) {
        return {...mileStones, actionStepsLoading: true};
      } else {
        return {...mileStones};
      }
    });

    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          ...state.collectionPage.milestones,
          milestones: mileStonesCopy,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesActionStepsSuccess, (state, props) => {
    let mileStonesCopy = [...state.collectionPage.milestones.milestones];
    mileStonesCopy = mileStonesCopy.map((mileStones: MilestoneDetails) => {
      if (mileStones.id === props.milestoneId) {
        return {
          ...mileStones,
          actionStepsLoading: false,
          actionSteps: props.data.actionSteps,
          actionHasMore: props.data.hasMore,
          ActionStepPage: 1,
        };
      } else {
        return {...mileStones};
      }
    });

    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          ...state.collectionPage.milestones,
          milestones: mileStonesCopy,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesActionStepsFail, (state, props) => {
    let mileStonesCopy = [...state.collectionPage.milestones.milestones];
    mileStonesCopy = mileStonesCopy.map((mileStones: MilestoneDetails) => {
      if (mileStones.id === props.milestoneId) {
        return {
          ...mileStones,
          actionStepsLoading: false,
          actionSteps: [],
          actionHasMore: false,
          ActionStepPage: 1,
        };
      } else {
        return {...mileStones};
      }
    });

    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          ...state.collectionPage.milestones,
          milestones: mileStonesCopy,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesNextPageSuccess, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        phase: {
          ...state.collectionPage.phase,
          phases: [...state.collectionPage.phase.phases, ...props.data.phases],
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesMilestoneNextPageSuccess, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          hasMore: props.data.hasMore,
          milestones: [
            ...state.collectionPage.milestones.milestones,
            ...props.data.milestones,
          ],
        },
      },
    };
  }),
  on(BaseActions.FetchDashboardPhasesSuccess, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        phase: {
          ...state.dashboardPage?.phase,
          phases: [...props.data.phases],
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchActionStepCollectionPageSuccess, (state, props) => {
    let updatedMilestone: any;
    const UpdatedMileStonesWithAllActionSteps =
      state.collectionPage.milestones.milestones.map((mile) => {
        if (mile.id && props.milestoneId) {
          updatedMilestone = {...mile};
          updatedMilestone.actionHasMore = props.data.hasMore;
          updatedMilestone.actionSteps = [
            ...updatedMilestone.actionSteps,
            ...props.data.actionSteps,
          ];
          updatedMilestone.ActionStepPage = props.currentPage + 1;
          return updatedMilestone;
        } else {
          return mile;
        }
      });

    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        milestones: {
          ...state.collectionPage.milestones,
          milestones: UpdatedMileStonesWithAllActionSteps,
        },
      },
    };
  }),
  on(BaseActions.FetchDashboardPhasesNextPageSuccess, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        phase: {
          ...state.dashboardPage.phase,
          phases: [...state.dashboardPage.phase.phases, ...props.data.phases],
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchMilestoneDashboardPageSuccess, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        milestone: {...props.data},
      },
    };
  }),
  on(BaseActions.FetchMilestoneDashboardNextPageSuccess, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        milestone: {
          ...state.dashboardPage.milestone,
          hasMore: props.data.hasMore,
          milestones: [
            ...state.dashboardPage.milestone.milestones,
            ...props.data.milestones,
          ],
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesRequest, (state, props) => {
    return {
      ...state,
      detailConfig: {
        ...state.detailConfig,
        selectedPhaseId: props.phase ? props.phase : '',
      },
    };
  }),
  on(BaseActions.SetSelectedPhase, (state, props) => {
    const updatedMilestonePagePhases = state.milestonePage.phase.phases.map(
      (phase) =>
        phase.id === props.phaseId
          ? {...phase, selected: true}
          : {...phase, selected: false}
    );
    const updatedCollectionPagePhases = state.collectionPage.phase.phases.map(
      (phase) =>
        phase.id === props.phaseId
          ? {...phase, selected: true}
          : {...phase, selected: false}
    );

    return {
      ...state,
      detailConfig: {
        ...state.detailConfig,
        selectedPhaseId: props.phaseId,
      },
      collectionPage: {
        ...state.collectionPage,
        phase: {
          ...state.collectionPage.phase,
          phases: updatedCollectionPagePhases,
        },
      },
      milestonePage: {
        ...state.milestonePage,
        phase: {
          ...state.milestonePage.phase,
          phases: updatedMilestonePagePhases,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesMilestonePagesSuccess, (state, props) => {
    const updatedPhases = props.data.phases.map((phase) =>
      phase.id === state.detailConfig.selectedPhaseId
        ? {...phase, selected: true}
        : {...phase, selected: false}
    );
    return {
      ...state,
      milestonePage: {
        ...state.milestonePage,
        phase: {
          ...state.milestonePage?.phase,
          phases: updatedPhases,
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchPhasesNextPageMilestonePageSuccess, (state, props) => {
    const newPhases = [
      ...state.dashboardPage.phase.phases,
      ...props.data.phases,
    ];
    const updatedPhases = newPhases.map((phase) =>
      phase.id === state.detailConfig.selectedPhaseId
        ? {...phase, selected: true}
        : {...phase, selected: false}
    );

    return {
      ...state,
      milestonePage: {
        ...state.milestonePage,
        phase: {
          ...state.dashboardPage.phase,
          phases: updatedPhases,
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.SetSelectedPhase, (state, props) => ({
    ...state,
    detailConfig: {
      ...state.detailConfig,
      selectedPhaseId: props.phaseId,
    },
  })),
  on(BaseActions.FetchAllTeamRolesSuccess, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        teamRoles: [...props.data.teamRoles],
      },
    };
  }),

  // SAS TOKEN
  on(BaseActions.FetchSasTokenRequest, (state) => state),
  on(BaseActions.FetchSasTokenSuccess, (state, props) => ({
    ...state,
    sasToken: props,
  })),
  on(BaseActions.FetchSasTokenFailure, (state) => state),

  // Submit Participant Deliverable
  on(
    BaseActions.SubmitParticipantMilestoneDeliverableRequest,
    (state) => state
  ),
  on(
    BaseActions.SubmitParticipantMilestoneDeliverableSuccess,
    (state, props) => ({
      ...state,
      milestonePage: {
        ...state.milestonePage,
        milestoneDetails: {
          ...state.milestonePage.milestoneDetails,
          deliverables: [...props.data],
        },
      },
    })
  ),
  on(
    BaseActions.SubmitParticipantMilestoneDeliverableFailure,
    (state) => state
  ),
  on(BaseActions.fetchDashboardWeekDataRequest, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        calender: {
          loading: true,
          calender: {...state.dashboardPage.calender.calender},
        },
      },
    };
  }),
  on(BaseActions.fetchDashboardWeekDataSuccess, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        calender: {loading: false, calender: props.data},
      },
    };
  }),
  on(BaseActions.fetchDashboardWeekDataFail, (state, props) => {
    return {
      ...state,
      dashboardPage: {
        ...state.dashboardPage,
        calender: {
          loading: false,
          calender: {...state.dashboardPage.calender.calender},
        },
      },
    };
  }),
  on(BaseActions.SetSelectedWorkSpaceId, (state, props) => ({
    ...state,
    detailConfig: {
      ...state.detailConfig,
      selectedWorkspaceId: props.SelectedWorkspaceId,
    },
  })),
  on(BaseActions.FetchDirectoryTeamsSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        team: {
          ...state.directoryPage.team,
          teams: props.data?.team,
          hasMore: props.data?.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchDirectoryTeamsNextPageSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        team: {
          ...state.directoryPage.team,
          teams: [...state.directoryPage.team.teams, ...props.data?.team],
          hasMore: props.data?.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchDirectoryCohortSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        cohort: {
          ...state.directoryPage.cohort,
          cohorts: props.data.cohort,
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchDirectoryCohortNextPageSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        cohort: {
          ...state.directoryPage.cohort,
          cohorts: [
            ...state.directoryPage.cohort.cohorts,
            ...props.data.cohort,
          ],
          hasMore: props.data.hasMore,
        },
      },
    };
  }),
  on(BaseActions.FetchDirectoryTeamRoleSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        teamRoles: props.data.teamRoles,
      },
    };
  }),
  on(BaseActions.FetchDirectoryTeamsOptionSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        teams: props.data.teams,
      },
    };
  }),
  on(BaseActions.fetchAllDirectConversationSuccess, (state, props) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        directMessage: [...props.conversations],
      },
    };
  }),

  on(BaseActions.fetchAllMessageForConversationIdSuccess, (state, props) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        message: {
          ...state.groupPage.message,
          messages: [...props.messages],
          hasMore: props.hasMore,
          phase: props.phase,
          milestone: props.milestone,
        },
      },
    };
  }),
  on(
    BaseActions.fetchAllMessageForConversationIdSuccessNextPage,
    (state, props) => {
      let messages = props.messages;
      messages = [...messages, ...state.groupPage.message.messages];

      return {
        ...state,
        groupPage: {
          ...state.groupPage,
          message: {
            ...state.groupPage.message,
            messages,
            hasMore: props.hasMore,
          },
        },
      };
    }
  ),
  on(BaseActions.AddNewMessage, (state, props) => {
    const messages: any[] = [...state.groupPage.message.messages];
    messages.push(props);
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        message: {...state.groupPage.message, messages},
      },
    };
  }),
  on(BaseActions.FetchAllGroupsForMyGroupsSuccess, (state, props) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        myCohort: props.myCohort,
        groupsMessage: [...props.groups],
        myTeam: props.myTeam,
        allTeams: props.allTeams ? props.allTeams : [],
      },
    };
  }),
  on(
    BaseActions.FetchAllThreadsForGroupsConversationSuccess,
    (state, props) => {
      return {
        ...state,
        groupPage: {
          ...state.groupPage,
          threads: {
            hasMore: props.hasMore,
            threads: [...props.conversations],
          },
        },
      };
    }
  ),
  on(
    BaseActions.FetchAllThreadsForGroupsConversationNextPageSuccess,
    (state, props) => {
      return {
        ...state,
        groupPage: {
          ...state.groupPage,
          threads: {
            hasMore: props.hasMore,
            threads: [
              ...state.groupPage.threads.threads,
              ...props.conversations,
            ],
          },
        },
      };
    }
  ),
  on(BaseActions.CreateNewChatThreadForGroupSuccess, (state, props) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        threads: {
          ...state.groupPage.threads,
          threads: [{...props}, ...state.groupPage.threads.threads],
        },
      },
    };
  }),
  on(BaseActions.SearchUserRequest, (state, props) => {
    return {
      ...state,
      groupPage: {...state.groupPage, searchUsers: []},
    };
  }),
  on(BaseActions.SearchUserSuccess, (state, props) => {
    return {
      ...state,
      groupPage: {...state.groupPage, searchUsers: props.members},
    };
  }),
  /* on(BaseActions.StartNewDirectMessageConversationSuccess, (state, props) => {

    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        directMessage: [
          ...state.groupPage.directMessage,
          { id: props.id, name: props.name },
        ],
      },
    };
  }), */
  on(
    BaseActions.AddNewConversationNewDirectChatFetchedFromSocket,
    (state, props) => {
      return {
        ...state,
        groupPage: {
          ...state.groupPage,
          directMessage: [...state.groupPage.directMessage, props.payload],
        },
      };
    }
  ),
  on(
    BaseActions.AddNewConversationNewGroupChatFetchedFromSocket,
    (state, props) => {
      return {
        ...state,
        groupPage: {
          ...state.groupPage,
          groupsMessage: [...state.groupPage.groupsMessage, props.payload],
        },
      };
    }
  ),
  on(BaseActions.MarkDirectConversationAsRead, (state, props) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        directMessage: [...props.updatedDirectChats],
      },
    };
  }),
  on(BaseActions.MarkGroupThreadConversationAsRead, (state, props) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        groupsMessage: [...props.groupData],
        myTeam: {...props.teams},
        myCohort: {...props.cohort},
        allTeams: [...props.allTeams],
      },
    };
  }),
  on(BaseActions.FetchUserProfileForDirectoryPageSuccess, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        userProfileData: props,
        userProfileLoadingState: false,
      },
    };
  }),
  on(BaseActions.FetchUserProfileForDirectoryPageRequest, (state, props) => {
    return {
      ...state,
      directoryPage: {...state.directoryPage, userProfileLoadingState: true},
    };
  }),
  on(BaseActions.ClearUserProfileForDirectoryPage, (state, props) => {
    return {
      ...state,
      directoryPage: {
        ...state.directoryPage,
        userProfileData: {},
        userProfileLoadingState: false,
      },
    };
  }),

  // CHAT SAS TOKEN
  on(BaseActions.FetchChatSasTokenRequest, (state) => state),
  on(BaseActions.FetchChatSasTokenSuccess, (state, props) => ({
    ...state,
    chatSasToken: props,
  })),
  on(BaseActions.FetchMilestoneRelatedConversationSuccess, (state, props) => ({
    ...state,
    milestoneConversation: {...state.milestoneConversation, groups: props},
  })),
  on(BaseActions.FetchMilestoneRelatedConversationRequest, (state, props) => ({
    ...state,
    milestoneConversation: {...state.milestoneConversation, groups: {}},
  })),
  on(
    BaseActions.FetchAllThreadsConversationForMilestoneSuccess,
    (state, props) => ({
      ...state,
      milestoneConversation: {...state.milestoneConversation, thread: props},
    })
  ),
  on(
    BaseActions.FetchAllThreadsConversationForMilestoneRequest,
    (state, props) => ({
      ...state,
      milestoneConversation: {...state.milestoneConversation, thread: {}},
    })
  ),
  on(
    BaseActions.CreateNewConversationChannelForMilestoneSuccess,
    (state, props) => {
      const data: any[] = [];
      data.push(props);
      return {
        ...state,
        milestoneConversation: {
          ...state.milestoneConversation,
          thread: {conversations: data},
        },
      };
    }
  ),
  on(
    BaseActions.FetchAllConversationRelatedToThreadsMilestoneSuccess,
    (state, props) => ({
      ...state,
      milestoneConversation: {
        ...state.milestoneConversation,
        conversation: props,
      },
    })
  ),
  on(
    BaseActions.FetchAllConversationRelatedToThreadsMilestoneRequest,
    (state, props) => ({
      ...state,
      milestoneConversation: {
        ...state.milestoneConversation,
        conversation: {},
      },
    })
  ),
  on(
    BaseActions.AddMessageToConversationToRelatedToMilestone,
    (state, props) => {
      return {
        ...state,
        milestoneConversation: {
          ...state.milestoneConversation,
          conversation: {
            ...state.milestoneConversation.conversation,
            messages: [
              ...state?.milestoneConversation?.conversation?.messages,
              props,
            ],
          },
        },
      };
    }
  ),
  on(
    BaseActions.FetchAllConversationRelatedToThreadsMilestoneNextPageSuccess,
    (state, props) => {
      return {
        ...state,
        milestoneConversation: {
          ...state.milestoneConversation,
          conversation: {
            ...state.milestoneConversation.conversation,
            messages: [
              ...props.messages,
              ...state?.milestoneConversation?.conversation?.messages,
            ],
            hasMore: props.hasMore,
          },
        },
      };
    }
  ),
  on(
    BaseActions.MarkReadToSideNavItemsInMilestoneConversation,
    (state, props) => {
      return {
        ...state,
        milestoneConversation: {
          ...state.milestoneConversation,
          groups: {
            ...state.milestoneConversation.groups,
            myCohort: props.cohort,
            myTeam: props.teams,
          },
        },
      };
    }
  ),

  on(BaseActions.fetchAllMessageForConversationIdRequest, (state) => {
    return {
      ...state,
      groupPage: {
        ...state.groupPage,
        message: {
          ...state.groupPage.message,
          messages: [],
          hasMore: false,
          phase: {},
          milestone: {},
        },
      },
    };
  }),
  on(BaseActions.FetchUserProfileSuccess, (state, props) => {
    return {
      ...state,
      userProfile: {...props},
    };
  }),
  on(AuthActions.UpdateProfileDataSuccess, (state, props) => {
    return {
      ...state,
      userProfile: {...props?.data},
    };
  }),
  on(BaseActions.SearchUserForMentionSuccess, (state, props) => {
    return {
      ...state,
      groupPage: {...state.groupPage, mentionsOptions: props.members},
    };
  }),
  on(BaseActions.SearchUserForMentionRequest, (state, props) => {
    return {
      ...state,
      groupPage: {...state.groupPage, mentionsOptions: []},
    };
  }),
  on(BaseActions.UpdateCollectionPageSelectedFilterByRole, (state, props) => {
    return {
      ...state,
      collectionPage: {
        ...state.collectionPage,
        selectedFilterRole: props.roles,
      },
    };
  }),
  on(BaseActions.FetchUnreadCountGroupsIconsSuccess, (state, props) => {
    return {
      ...state,
      workspaceUnreadMessages: props?.workspaceUnreadMessages,
    };
  }),
  on(BaseActions.ResetPhasesMilestoneToDefaultState, (state, props) => ({
    ...state,
    actionStepPage: {
      name: '',
      description: '',
      assignToRoles: '',
      assignToMembers: '',
      approximateTime: '',
      dueDate: '',
      materialsNeeded: '',
      content: '',
      nextActionStep: null,
      previousActionStep: null,
    },
    milestonePage: {
      actionSteps: [],
      hasMore: true,
      milestoneDetails: {},
      page: 1,
      phase: {
        hasMore: null,
        phases: [],
      },
      milestoneFiles: {
        documents: [],
        hasMore: false,
      },
    },
    collectionPage: {
      phase: {
        hasMore: true,
        phases: [],
        preSelected: '',
      },
      milestones: {
        hasMore: true,
        milestones: [],
      },
      teamRoles: [],
      milestoneLoaded: false,
      selectedFilterRole: null,
    },
    dashboardPage: {
      phase: {hasMore: true, phases: []},
      milestone: {hasMore: true, milestones: []},
      calender: {
        loading: false,
        calender: [],
      },
    },
    directoryPage: {
      cohort: {
        cohorts: [],
        hasMore: false,
      },
      team: {
        hasMore: false,
        teams: [],
      },
      teamRoles: [],
      teams: [],
      userProfileData: {},
      userProfileLoadingState: false,
    },
    groupPage: {
      directMessage: [],
      groupsMessage: [],
      allTeams: [],
      message: {
        hasMore: false,
        messages: [],
      },
      threads: {
        hasMore: false,
        threads: [],
      },
      myCohort: {},
      myTeam: {},
      searchUsers: [],
      mentionsOptions: [],
    },
    milestoneConversation: {conversation: {}, groups: {}, thread: {}},
  })),

  on(BaseActions.FetchFileAccordingToMilestoneSuccess, (state, props) => {
    return {
      ...state,
      milestonePage: {
        ...state.milestonePage,
        milestoneFiles: {
          documents: [...state.milestonePage.milestoneFiles.documents, ...props.data.documents],
          hasMore: props.data.hasMore,
        },
      },
    };
  }),

  on(BaseActions.ResetMilestoneFileList, (state) => {
    return {
      ...state, milestonePage: {
        ...state.milestonePage, milestoneFiles: {
          documents: [], hasMore: false
        }
      }
    }
  }),

  on(BaseActions.FetchChatSasTokenFailure, (state) => state)
);

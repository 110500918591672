import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-milestone-content-item',
  templateUrl: './milestone-content-item.component.html',
  styleUrls: ['./milestone-content-item.component.css'],
})
export class MilestoneContentItemComponent implements OnInit {
  @Input() itemType: string;
  @Input() isFirst: boolean;
  @Input() item: any;
  @Input() workspaceId: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  getProgressStyles() {
    const number: number =
      +this.item.completedActionStepsPercentage.split('%')[0];
    if (number >= 0 && number <= 25) {
      return 'danger-spinner';
    } else if (number >= 26 && number <= 75) {
      return 'warn-spinner';
    } else if (number >= 76 && number <= 100) {
      return 'on-track-spinner';
    }
  }

  getPhaseTitle() {
    if (this.itemType === 'actions') {
      return this.item?.parentMilestone?.phase?.shortTitle;
    } else if (this.itemType === 'milestone') {
      return this.item?.parentPhase?.shortTitle;
    }
  }

  getMilestoneTitle() {}

  getContentTitle() {}

  getProgressSpinnerValue() {
    return +this.item.completedActionStepsPercentage.split('%')[0];
  }

  navigateToMilestone() {
    this.router.navigate([
      `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.item?.parentMilestone?.phase?.id}/milestone/${this.item?.parentMilestone?.id}`,
    ]);
  }

  navigateToPhases() {
    if (this.itemType === 'milestone') {
      const params = {
        phase: this.item?.parentPhase?.id,
      };
      this.router.navigate(
        [`/changemakers/collection/workspace/${this.workspaceId}`],
        { queryParams: params }
      );
    } else if (this.itemType === 'actions') {
      const params = {
        phase: this.item?.parentMilestone?.phase?.id,
      };
      this.router.navigate(
        [`/changemakers/collection/workspace/${this.workspaceId}`],
        { queryParams: params }
      );
    }
  }
  onNavigateToActionSteps() {
    if (this.itemType !== 'milestone') {
      this.router.navigate([
        `/changemakers/action-details/workspace/${this.workspaceId}/phase/${this.item?.parentMilestone?.phase?.id}/milestone/${this.item?.parentMilestone?.id}/actionstep/${this.item?.id}`,
      ]);
    }
    if (this.itemType === 'milestone') {
      /*   `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.item?.parentMilestone?.phase?.id}/milestone/${this.item?.parentMilestone?.id}`, */
      this.router.navigate([
        `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.item?.parentPhase?.id}/milestone/${this.item?.id}`,
      ]);
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {CommonService} from '../../../../shared/services/common/common.service';

@Component({
  selector: 'app-loading-screen-panel',
  templateUrl: './loading-screen-panel.component.html',
  styleUrls: ['./loading-screen-panel.component.css'],
})
export class LoadingScreenPanelComponent implements OnInit {
  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  startLoadingScreen() {
    console.log('Loading Screen Started');
    this.commonService.startLoading();
    this.commonService.startLoading();
    this.commonService.startLoading();
    this.commonService.startLoading();
    setTimeout(() => {
      this.commonService.stopLoading();
      this.commonService.startLoading();
      this.commonService.stopLoading();
      this.commonService.stopLoading();
      this.commonService.startLoading();
      this.commonService.stopLoading();
      this.commonService.startLoading();
      this.commonService.startLoading();
      this.commonService.stopLoading();
      this.commonService.stopLoading();
      this.commonService.stopLoading();
      this.commonService.stopLoading();
      console.log('Loading Screen Stopped');
    }, 5000);
  }
}

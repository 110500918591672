import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from 'src/app/modules/shared/services/common/common.service';

@Component({
  selector: 'app-new-group-chat',
  templateUrl: './new-group-chat.component.html',
  styleUrls: ['./new-group-chat.component.css'],
})
export class NewGroupChatComponent implements OnInit {
  @Output() onCreateNewThreadHandler: EventEmitter<any> = new EventEmitter();
  @Input()groupId:any
  form: FormGroup = new FormGroup({
    threadName: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
    attachments: new FormControl([]),
  });
  errorMessages = {
    threadName: 'Required',
  };
  threadName: FormControl = new FormControl();
  errorMessageMap = {
    threadName: {
      required: 'This field is required.',
    },
  };

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  onClickedSend(event) {
    this.form.patchValue({
      message: event.text,
      attachments: event.attachments
    });
    if (this.form.valid) {
      this.onCreateNewThreadHandler.emit({
        threadType: 'group',
        threadName: this.form.value.threadName,
        messageBody: this.form.value.message,
        attachments: this.form.value.attachments
      });
      this.form.reset();
    }
  }
}

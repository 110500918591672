/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[groups] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** CLEAR THREADS LIST ***********/
const CLEAR_THREADS = '[groups] clear threads';
export const ClearThreads = createAction(CLEAR_THREADS);

/******** CLEAR THREADS LIST ***********/
const CLEAR_THREADS_LIST = '[groups] clear threads list';
export const ClearThreadsList = createAction(CLEAR_THREADS_LIST);

/******** CLEAR MESSAGES LIST ***********/
const CLEAR_MESSAGES_LIST = '[groups] clear messages list';
export const ClearMessagesList = createAction(CLEAR_MESSAGES_LIST);

/******** CLEAR MEMBERS LIST ***********/
const CLEAR_MEMBERS_LIST = '[groups] clear members list';
export const ClearMembersList = createAction(CLEAR_MEMBERS_LIST);

/******** GROUP CONVERSATIONS LIST ***********/
const GROUP_CONVERSATIONS_LIST_REQUEST = '[groups] Group Conversations List Request';
export const GroupConversationsListRequest = createAction(GROUP_CONVERSATIONS_LIST_REQUEST)
const GROUP_CONVERSATIONS_LIST_SUCCESS = '[groups] Group Conversations List Success';
export const GroupConversationsListSuccess = createAction(GROUP_CONVERSATIONS_LIST_SUCCESS, props<any>())
const GROUP_CONVERSATIONS_LIST_FAILURE = '[groups] Group Conversations List Failure';
export const GroupConversationsListFailure = createAction(GROUP_CONVERSATIONS_LIST_FAILURE, props<any>())

/******** DIRECT CONVERSATIONS LIST ***********/
const DIRECT_CONVERSATIONS_LIST_REQUEST = '[groups] Direct Conversations List Request';
export const DirectConversationsListRequest = createAction(DIRECT_CONVERSATIONS_LIST_REQUEST)
const DIRECT_CONVERSATIONS_LIST_SUCCESS = '[groups] Direct Conversations List Success';
export const DirectConversationsListSuccess = createAction(DIRECT_CONVERSATIONS_LIST_SUCCESS, props<any>())
const DIRECT_CONVERSATIONS_LIST_FAILURE = '[groups] Direct Conversations List Failure';
export const DirectConversationsListFailure = createAction(DIRECT_CONVERSATIONS_LIST_FAILURE, props<any>())

/******** GROUP THREADS LIST ***********/
const GROUP_THREADS_LIST_REQUEST = '[groups] Group Threads List Request';
export const GroupThreadsListRequest = createAction(GROUP_THREADS_LIST_REQUEST, props<any>())
const GROUP_THREADS_LIST_SUCCESS = '[groups] Group Threads List Success';
export const GroupThreadsListSuccess = createAction(GROUP_THREADS_LIST_SUCCESS, props<any>())
const GROUP_THREADS_LIST_FAILURE = '[groups] Group Threads List Failure';
export const GroupThreadsListFailure = createAction(GROUP_THREADS_LIST_FAILURE, props<any>())

/******** SET GROUP THREAD LIST PAGE NUMBERS ***********/
const SET_GROUP_THREAD_LIST_PAGE_NUMBER = '[groups] Set group thread list page number';
export const SetGroupThreadsListPageNumber = createAction(SET_GROUP_THREAD_LIST_PAGE_NUMBER, props<any>())

/******** MESSAGES LIST ***********/
const MESSAGES_LIST_REQUEST = '[groups] Messages List Request';
export const MessagesListRequest = createAction(MESSAGES_LIST_REQUEST, props<any>())
const MESSAGES_LIST_SUCCESS = '[groups] Messages List Success';
export const MessagesListSuccess = createAction(MESSAGES_LIST_SUCCESS, props<any>())
const MESSAGES_LIST_FAILURE = '[groups] Messages List Failure';
export const MessagesListFailure = createAction(MESSAGES_LIST_FAILURE, props<any>())

/******** MEMBERS LIST ***********/
const MEMBERS_LIST_REQUEST = '[groups] Members List Request';
export const MembersListRequest = createAction(MEMBERS_LIST_REQUEST, props<any>())
const MEMBERS_LIST_SUCCESS = '[groups] Members List Success';
export const MembersListSuccess = createAction(MEMBERS_LIST_SUCCESS, props<any>())
const MEMBERS_LIST_FAILURE = '[groups] Members List Failure';
export const MembersListFailure = createAction(MEMBERS_LIST_FAILURE, props<any>())

/******** ADD NEW MESSAGE ***********/
const ADD_NEW_MESSAGE = '[groups] Add new message';
export const AddNewMessage = createAction(ADD_NEW_MESSAGE, props<any>())

/******** UPDATE DIRECT MESSAGE COUNT ***********/
const UPDATE_DIRECT_MESSAGE_COUNT = '[groups] Update direct message count';
export const UpdateDirectMessageCount = createAction(UPDATE_DIRECT_MESSAGE_COUNT, props<any>())

/******** UPDATE GROUP MESSAGE COUNT ***********/
const UPDATE_GROUP_MESSAGE_COUNT = '[groups] Update group message count';
export const UpdateGroupMessageCount = createAction(UPDATE_GROUP_MESSAGE_COUNT, props<any>())

/******** UPDATE THREAD MESSAGE COUNT ***********/
const UPDATE_THREAD_MESSAGE_COUNT = '[groups] Update thread message count';
export const UpdateThreadMessageCount = createAction(UPDATE_THREAD_MESSAGE_COUNT, props<any>())

/******** CREATE CONVERSATION ***********/
const CREATE_CONVERSATION_REQUEST = '[groups] Create Conversation Request';
export const CreateConversationRequest = createAction(CREATE_CONVERSATION_REQUEST, props<any>())
const CREATE_CONVERSATION_SUCCESS = '[groups] Create Conversation Success';
export const CreateConversationSuccess = createAction(CREATE_CONVERSATION_SUCCESS, props<any>())
const CREATE_CONVERSATION_FAILURE = '[groups] Create Conversation Failure';
export const CreateConversationFailure = createAction(CREATE_CONVERSATION_FAILURE, props<any>())

/******** SAS TOKEN ***********/
const SAS_TOKEN_REQUEST = '[groups] Sas Token Request';
export const SasTokenRequest = createAction(SAS_TOKEN_REQUEST)
const SAS_TOKEN_SUCCESS = '[groups] Sas Token Success';
export const SasTokenSuccess = createAction(SAS_TOKEN_SUCCESS, props<any>())
const SAS_TOKEN_FAILURE = '[groups] Sas Token Failure';
export const SasTokenFailure = createAction(SAS_TOKEN_FAILURE, props<any>())

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as BaseAction from '../../../core/base.actions';
import * as BaseSelectors from '../../../core/base.selectors';
import { Store } from '@ngrx/store';
import { map, Observable, Subscription } from 'rxjs';
import { CommonService } from '../../../../shared/services/common/common.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import typeMap from '../../../../shared/constants/fileTypeToNameMap.constants';
import v4 from 'uuid';
import { MatMenuTrigger } from '@angular/material/menu';
import { CKEditor4, CKEditorComponent } from 'ckeditor4-angular';
import { ViewEncapsulation } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.css'],
})
export class ChatInputComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() showAttachIcon = true;
  @Input() showSendIcon = true;
  @Input() minHeight;
  @Input() hideDivider = false;
  @Input() conversationId: any = '';

  @Input() groupId;
  @Output() sendHandler: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('editor') editorComponent: CKEditorComponent;
  fileInputId = v4();

  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  textToBeSent: string = '';

  text;
  clipIcon = 'attach_file';
  sendIcon = 'send';
  x_timer: any;

  uploadSubscription: Subscription;
  chatSasTokenSubscription: Subscription;
  chatSasToken;

  maxAllowedUploads = 5;
  filesList = [];
  uploadProcessTracker = [];
  mentionOptions$: Observable<any> = new Observable();
  mentions: any[] = [];
  mentionsSubscription: Subscription = new Subscription();
  activatedParamsSubscription: Subscription = new Subscription();

  typeMap;
  config: any;
  workspaceId;

  acceptedFileTypes = [
    'application/pdf', // PDF
    'image/jpeg', // JPG / JPEG
    'image/png', // PNG
    'application/msword', // DOC
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
    'application/vnd.ms-excel', // XLS
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
    'application/vnd.ms-powerpoint', // PPT
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
    'application/zip', // ZIP
    'application/x-zip-compressed', // ZIP
    'multipart/x-zip', // ZIP
    'application/octet-stream', // ZIP / RAR
    'application/x-rar-compressed', // RAR
    'image/svg+xml', // SVG
    'text/csv', // CSV
  ];

  dataFeed = (opts, callback) => {
    const matchProperty = 'firstName';
    let data = this.mentions.filter((item) => {
      return (
        item[matchProperty].toLowerCase().indexOf(opts.query.toLowerCase()) >= 0
      );
    });

    data = data.sort((a, b) => {
      return a[matchProperty].localeCompare(b[matchProperty], undefined, {
        sensitivity: 'accent',
      });
    });

    callback(data);
  };

  constructor(
    private store: Store,
    private commonService: CommonService,
    private activatedRoutes: ActivatedRoute
  ) {
    this.store.dispatch(BaseAction.FetchChatSasTokenRequest());

    this.typeMap = typeMap;
  }
  ngOnChanges() {
    if (this.groupId === undefined) {
      this.searchUser('', this.workspaceId, this.conversationId);
    } else {
      this.searchUserAccordingToGroupId('', this.workspaceId, this.groupId);
    }
  }
  ngAfterViewInit(): void {
    this.editorComponent.onChange;
  }
  enterHandler(event) {
    if (event.includes('<p>&nbsp;</p>')) {
      this.onSendClicked();
    }
  }

  ngOnInit(): void {
    this.activatedParamsSubscription = this.activatedRoutes.paramMap.subscribe(
      (params: any) => {
        this.workspaceId = params.params.workspaceId;
        if (this.groupId === undefined) {
          this.searchUser('', params.params.workspaceId, this.conversationId);
        } else {
          this.searchUserAccordingToGroupId('', this.workspaceId, this.groupId);
        }
      }
    );
    this.chatSasTokenSubscription = this.store
      .select(BaseSelectors.getChatSasToken)
      .subscribe((data) => {
        this.chatSasToken = data;
      });
    this.mentionOptions$ = this.store.select(
      BaseSelectors.getAllMentionOptions
    );

    this.mentionsSubscription = this.store
      .select(BaseSelectors.getAllMentionOptions)
      .pipe(
        map((users: any) => {
          const updatedData: any[] = [];
          for (let user of users) {
            if (user.profilePicture) {
              updatedData.push({
                ...user,
                profileImage: `<img class="mention-photo" src=${user.profilePicture}/>`,
                label: user.firstName[0] + '' + user.lastName[0],
              });
            } else {
              updatedData.push({
                ...user,
                profilePicture: ``,
                label: user.firstName[0] + user.lastName[0],
              });
            }
          }
          return updatedData;
        })
      )
      .subscribe((mentions) => {
        this.mentions = mentions;

        this.config = {
          height: '150px',
          linkShowTargetTab: false,
          linkShowAdvancedTab: false,
          link: {
            // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
            addTargetToExternalLinks: true,

            // Let the users control the "download" attribute of each link.
            decorators: [
              {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                  download: 'download',
                },
              },
            ],
          },

          extraPlugins: ['editorplaceholder', 'autogrow', 'link', 'autolink'],
          mentions: [
            {
              marker: '@',
              feed: this.dataFeed,
              itemTemplate:
                '<li class="mention-option" data-id="{id}">' +
                `<div class="mention-image-div"><img class="mention-photo" onerror="this.onerror=null;this.className='hide-mention-img'" src="{profilePicture}" alt="{label}" />` +
                '<span class="mention-img-label">{label}</span></div>' +
                '<span class="firstName">{firstName} {lastName}</span>' +
                '</li>',
              outputTemplate:
                '<span data-value="{id}" data-id="{id}"><a href="mentions::{id}">@{firstName} {lastName}</a><span>&nbsp;</span></span>',
              minChars: 0,
            },
          ],
          editorplaceholder: 'Message here....',
          autoGrow_minHeight: 50,
          autoGrow_bottomSpace: 30,
        };
      });
  }
  onSendClicked() {
    // Eg:: <p>Hi <a href="mentions::241">@Ayush Mishra</a> <a href="mentions::208">@Harun s</a>&nbsp;</p>
    // Extract the mentions from text
    const mentions = (this.text.match(/href="mentions::[0-9]+"/g) || []);
    const mentionIds = mentions.map((mention) => {
      // Extracting mention ID from the mention
      const idsFound = (mention.match(/[0-9]+/g) || [])
      if (idsFound.length === 1) {
        return +idsFound[0]
      } else {
        return null
      }
    });
    // Extract unique mention IDs from the found list
    const uniqueMentionIds = [...new Set(mentionIds)]
    const message = {
      text: this.text,
      attachments: this.filesList.map((file) => ({
        blob_name: file.name,
        file_name: file.fileName,
      })),
      mentioned_ids: uniqueMentionIds
    };
    this.sendHandler.emit(message);
    this.text = '';
    this.filesList = [];
  }

  fileBrowseHandler() {
    if (this.filesList.length >= this.maxAllowedUploads) {
      this.commonService.notification(
        'You Can Upload maximum ' + this.maxAllowedUploads + ' file(s)',
        'warning'
      );
      return;
    }

    const files = this.fileDropRef.nativeElement.files;
    let invalidTypeCount = 0;
    // tslint:disable-next-line:forin
    for (const key in files) {
      const file = files[key];
      if (file instanceof File) {
        if (!this.acceptedFileTypes.includes(file.type)) {
          invalidTypeCount++;
          continue;
        }
        if (file.size > 10000000) {
          this.commonService.notification(
            'File size can not exceed 10Mb',
            'warning'
          );
          return;
        }
        if (this.filesList.length >= this.maxAllowedUploads) {
          this.commonService.notification(
            'You Can Upload maximum ' + this.maxAllowedUploads + ' file(s)',
            'warning'
          );
          return;
        } else {
          const suffix = new Date().getTime();
          const fileObject = {
            uploadStatus: 1,
            name:
              file.name.substring(0, file.name.lastIndexOf('.')) +
              '-' +
              suffix +
              file.name.substring(file.name.lastIndexOf('.')),
            size: file.size,
            fileName: file.name,
            data: file,
            progress: 0,
            hasError: false,
            uploadUrl: null,
            loading: false,
            type: file.type,
            isImage: file.type.split('/')[0] === 'image',
          };

          fileObject.uploadUrl = `${this.chatSasToken.host}/${this.chatSasToken.container}/${fileObject.name}?${this.chatSasToken.token}`;

          this.filesList.push(fileObject);

          this.uploadFiles(fileObject);
        }
      }
    }

    if (invalidTypeCount) {
      this.commonService.notification(
        invalidTypeCount + ' file(s) have invalid file type',
        'danger'
      );
    }
    this.fileDropRef.nativeElement.value = '';
  }

  uploadFiles(fileObject) {
    this.uploadProcessTracker = [...this.uploadProcessTracker, 1];
    const payload = {
      url: fileObject.uploadUrl,
      data: fileObject.data,
      fileType: fileObject.type,
    };
    fileObject.loading = true;
    this.uploadSubscription = this.commonService
      .uploadToBlobStorage(payload)
      .subscribe(
        (uploadResponse) => {
          if (uploadResponse.type === HttpEventType.UploadProgress) {
            fileObject.uploadStatus = 2;
            fileObject.progress = Math.round(
              (100 * uploadResponse.loaded) / uploadResponse.total
            );
          } else if (uploadResponse instanceof HttpResponse) {
            this.uploadProcessTracker = [...this.uploadProcessTracker].slice(1);
            fileObject.uploadStatus = 3;
            fileObject.url = uploadResponse.url;
          }
        },
        (err) => {
          this.commonService.notification(
            `File (${fileObject.name}) could not be uploaded.`,
            'danger'
          );
          this.uploadProcessTracker = [...this.uploadProcessTracker].slice(1);
          fileObject.uploadStatus = 4;
        },
        () => {
          fileObject.loading = false;
        }
      );
  }

  deleteFile(index: number) {
    const fileName = this.filesList[index]?.name;
    this.commonService.startLoading();
    this.commonService
      .deleteFromBlobStorage(this.filesList[index]?.uploadUrl)
      .subscribe((deleteResponse) => {
        if (deleteResponse instanceof HttpResponse) {
          this.filesList.splice(index, 1);
          this.commonService.stopLoading();
          this.commonService.notification(
            `File (${fileName}) was deleted.`,
            'success'
          );
        }
      });
  }

  ngOnDestroy() {
    this.chatSasTokenSubscription?.unsubscribe();
    this.uploadSubscription?.unsubscribe();
    this.mentionsSubscription?.unsubscribe();
    this.activatedParamsSubscription?.unsubscribe();
  }

  showMentions(event) {
    if (event.target.value.includes('@')) {
      setTimeout(() => {
        this.trigger.openMenu();
      }, 2000);
      clearTimeout(this.x_timer);
      this.x_timer = setTimeout(() => {
        /*  this.searchUser(event.target.value.split('@')[1]); */
      }, 1000);
    }
  }

  searchUser(nameEntered, workspaceId, conversationId) {
    this.store.dispatch(
      BaseAction.SearchUserForMentionRequest({
        username: nameEntered,
        workspaceId: workspaceId,
        conversationId: conversationId,
        searchAccordingToGroup: false,
      })
    );
  }

  searchUserAccordingToGroupId(nameEntered, workspaceId, groupId) {
    this.store.dispatch(
      BaseAction.SearchUserForMentionRequest({
        username: nameEntered,
        workspaceId: workspaceId,
        groupId: groupId,
        searchAccordingToGroup: true,
      })
    );
  }
  mentionUserSelected(userMembers) {
    const mentionUser = JSON.stringify(userMembers);
    this.text =
      this.text.split('@')[0] + '$$mention' + mentionUser + '$$mention';
    this.textToBeSent = this.text;
    const brokenValues = this.text.split('$$mention');
    let finalValue = '';
    for (const text of brokenValues) {
      if (text[0] !== '{') {
        finalValue = finalValue + text;
      } else {
        const userData = JSON.parse(text);
        finalValue =
          finalValue +
          `<span class="mention-user" >` +
          '@' +
          userData.firstName +
          userData.lastName +
          '</span>';
      }
    }
    this.text = finalValue;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as baseActions from '../../../base/core/base.actions';

@Component({
  selector: 'app-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.css'],
})
export class PhasesComponent implements OnInit {
  icon;
  visiblePhaseStyle = {stroke: 'white'};
  throttle = 50;
  scrollDistance = 2;
  @Input() phases: any;
  @Input() hasMore: any;
  @Output() loadMoreHandler: EventEmitter<any> = new EventEmitter();
  @Output() onClickPhasesHandler: EventEmitter<any> = new EventEmitter();
  @Input() showActive: boolean = true;
  @Output() milestoneClickHandler: EventEmitter<any> = new EventEmitter();
  @Input() selectedPhaseId:string=''

  lastRightScroll = 0;
  isRightLast = false;

  constructor(private router: Router, private store: Store) {
  }

  ngOnInit(): void {
    this.icon = this.phases.lockStatus === 0 ? 'arrow-right-s-line' : 'lock';
  }

  loadMorePhases() {
    if (this.hasMore === true) {
      this.loadMoreHandler.emit();
    }
  }

  getMilestoneStatusStyles(status) {
    if (status === 0) {
      return 'mile-stone-span-not-started';
    } else if (status === 1) {
      return 'mile-stone-span-ongoing';
    } else if (status === 2) {
      return 'mile-stone-span-completed';
    }
  }

  getIconAccordingToLockStatus(lockStatus) {
    return lockStatus === 0 ? 'arrow-right-s-line' : 'lock';
  }

  scrollMileSToneLeft() {
    document.getElementById('phases_list').scrollLeft -= 150;
    this.isRightLast = false;
  }

  scrollMileSTone() {
    if (
      this.lastRightScroll !==
      (document.getElementById('phases_list').scrollLeft += 150)
    ) {
      document.getElementById('phases_list').scrollLeft += 150;
      this.lastRightScroll = document.getElementById(
        'phases_list'
      ).scrollLeft += 150;
      this.isRightLast = false;
    } else {
      this.isRightLast = true;
    }
  }

  changeActivePhase(phase) {
    if (phase.lockStatus == 0) {
      this.onClickPhasesHandler.emit(phase);
    }
  }

  getStylesAccordingToStatus(phase) {
    if (this.showActive == true) {
      if (phase.id == this.selectedPhaseId) {
        return 'active';
      } else if (phase.lockStatus == 1) {
        return 'locked';
      } else if (phase.lockStatus == 0) {
        return 'normal';
      }
    } else {
      if (phase.lockStatus == 1) {
        return 'locked';
      } else if (phase.lockStatus == 0) {
        return 'normal';
      }
    }
  }

  milestoneClick(milestoneId, phaseId) {
    this.store.dispatch(baseActions.SetSelectedPhase({phaseId: phaseId}));
    this.milestoneClickHandler.emit({
      milestoneId: milestoneId,
      phaseId: phaseId,
    });
  }

  iconStyles(phase) {
    if (phase?.selected == true) {
      return this.visiblePhaseStyle;
    }
  }

  showScrollRightIcons() {
    return (
      document?.getElementById('phases_list')?.scrollWidth >
      document?.getElementById('phases_list')?.clientWidth
    )
  }

  showScrollLeftIcons() {
    if (
      document?.getElementById('phases_list')?.scrollLeft >
      document?.getElementById('phases_list')?.clientLeft
    ) {
      return true;
    } else return false;
  }
}

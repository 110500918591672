import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import * as BaseSelector from '../../core/base.selectors';
import * as BaseAction from '../../core/base.actions';
import {tap} from 'rxjs/operators';
import * as AuthSelector from '../../../authentication/core/authentication.selectors';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-collection-screen',
  templateUrl: './collection-screen.component.html',
  styleUrls: ['./collection-screen.component.css'],
})
export class CollectionScreenComponent implements OnInit, OnDestroy {
  phases$: Observable<any> = new Observable();
  milestone$: Observable<any> = new Observable();
  throttle = 300;
  scrollDistance = 1;
  @Input() phase: any;
  @Input() hasMore: boolean = true;
  @Input() currentTeamRoles$: Observable<any> = new Observable();
  @Output() changeSelectedPhaseNameHandler: EventEmitter<any> =
    new EventEmitter();
  @Output() preSelectedPhaseHandler: EventEmitter<any> = new EventEmitter();
  selectedPhaseName: string;
  teamRoles: string = '';
  @Input() workspaceId: string;
  getIsCollectionPageMilestoneLoadedSubscription: Subscription =
    new Subscription();
  showError: boolean = false;

  phasesList: any[] = [];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      const phase = Number(queryParams?.phase);
      if (phase) {
        this.selectedPhaseName = this.phasesList
          .filter(eachPhase => {
            return eachPhase.id === phase
          })[0]?.name;
      }
    });

    this.store
      .select(BaseSelector.getAllPhases)
      .subscribe(data => {
        this.phasesList = data.phases;
        const queryParams: any = this.route.snapshot.queryParams;
        const phase = Number(queryParams.phase);
        if (phase) {
          this.selectedPhaseName = this.phasesList
            .filter(eachPhase => {
              return eachPhase.id === phase
            })[0]?.name;
        } else {
          this.selectedPhaseName = this.phasesList.filter(eachPhase => eachPhase.id === data.preSelected)[0]?.name;
        }
      }).unsubscribe();

    this.phases$ = this.store.select(BaseSelector.getAllPhases);

    this.milestone$ = this.store
      .select(BaseSelector.getAllPhasesMilestone)
      .pipe(tap((res) => {
      }));

    this.currentTeamRoles$ = this.store.select(AuthSelector.getCurrentRole);

    this.getIsCollectionPageMilestoneLoadedSubscription = this.store
      .select(BaseSelector.getIsCollectionPageMilestoneLoaded)
      .subscribe((isLoaded) => {
        if (isLoaded === true) {
          this.showError = true;
        } else {
          this.showError = false;
        }
      });
  }

  loadMoreMilestones() {
    if (this.hasMore) {
      this.store.dispatch(
        BaseAction.FetchPhasesMilestoneNextPageRequest({
          phaseId: this.phase,
          teamRoles: this.teamRoles,
          organizationId: this.workspaceId,
        })
      );
    }
  }

  changeSelectedPhaseName(event) {
    this.selectedPhaseName = event;
  }

  ngOnDestroy(): void {
    this.getIsCollectionPageMilestoneLoadedSubscription?.unsubscribe();
  }
}

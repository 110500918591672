import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-password-screen',
  templateUrl: './reset-password-screen.component.html',
  styleUrls: ['./reset-password-screen.component.css']
})
export class ResetPasswordScreenComponent implements OnInit {
  @Input() icon;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
}

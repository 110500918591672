import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dates-table',
  templateUrl: './dates-table.component.html',
  styleUrls: ['./dates-table.component.css']
})
export class DatesTableComponent implements OnInit {

  dateHeader = ['s', 'm', 't', 'w', 'th', 'f', 'sa']

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import * as BaseSelector from '../../core/base.selectors';
import * as BaseAction from '../../core/base.actions';
import {Router} from '@angular/router';
import * as AuthSelector from '../../../authentication/core/authentication.selectors';

@Component({
  selector: 'app-milestone-page-screen',
  templateUrl: './milestone-page-screen.component.html',
  styleUrls: ['./milestone-page-screen.component.css'],
})
export class MilestonePageScreenComponent implements OnInit, OnDestroy {
  @Output() loadMoreActionSteps: EventEmitter<any> = new EventEmitter();
  @Input() workspace;
  @Input() phaseId;
  @Input() milestoneId;
  unreadMessageCount;
  actionStep$: Observable<any> = new Observable();
  milestoneDetails$: Observable<any> = new Observable();
  currentTeamRoles$: Observable<any> = new Observable();
  activeTab = 'actionsteps';
  phases$: Observable<any> = new Observable();
  milestoneConversationGroupSubscription: Subscription = new Subscription();

  constructor(private store: Store, private router: Router) {
    this.actionStep$ = this.store.select(BaseSelector.getActionsSteps);
    this.phases$ = this.store.select(BaseSelector.getPhasesForMilestonePage);
    this.milestoneDetails$ = this.store
      .select(BaseSelector.getMilestoneDetails)
      .pipe();
  }

  ngOnInit(): void {
    this.milestoneConversationGroupSubscription = this.store
      .select(BaseSelector.fetchAllMilestoneRelatedConversationGroups)
      .subscribe((conversationData) => {
        this.unreadMessageCount =
          (conversationData?.groups?.myCohort?.unreadMessages > 0
            ? conversationData?.groups?.myCohort?.unreadMessages
            : 0) +
          (conversationData?.groups?.myTeam?.unreadMessages > 0
            ? conversationData?.groups?.myTeam?.unreadMessages
            : 0);
      });
      this.currentTeamRoles$ = this.store.select(AuthSelector.getCurrentRole);
  }

  onToggleTab(tabName: any) {
    if(tabName.index === 0) {
      this.activeTab = 'actionsteps';
    }
   else if(tabName.index === 1) {
      this.activeTab = 'conversation';
    }
    else if(tabName.index === 2) {
      this.activeTab = 'files';
    }
  }

  loadMoreHandler() {
    this.store.dispatch(
      BaseAction.FetchPhasesNextPageMilestonePageRequest({
        organizationId: this.workspace,
      })
    );
  }

  onClickPhasesHandler(event) {
    const params = {
      phase: event.id,
    };
    this.router.navigate(
      ['/changemakers/collection/workspace/' + this.workspace],
      { queryParams: params }
    );
  }

  milestoneClickedHandler(event) {
    this.store.dispatch(
      BaseAction.SetSelectedPhase({ phaseId: event.phaseId })
    );
    this.router.navigate([
      `/changemakers/milestone-details/workspace/${this.workspace}/phase/${event.phaseId}/milestone/${event.milestoneId}`,
    ]);
  }

  ngOnDestroy(): void {
    this.milestoneConversationGroupSubscription?.unsubscribe();
  }
}

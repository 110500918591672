import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-open-chat',
  templateUrl: './open-chat.component.html',
  styleUrls: ['./open-chat.component.css'],
})
export class OpenChatComponent implements OnInit {
  @Output() closeChatHandler: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeChat() {
    this.closeChatHandler.emit();
  }
}

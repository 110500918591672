import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as BaseActions from '../../../core/base.actions';
import * as BaseSelector from '../../../core/base.selectors';
import { tap } from 'rxjs/operators';
import * as ActionCable from 'actioncable';
import { v1URL } from 'src/app/modules/shared/constants/urls.constants';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';
import { MilestoneConversationService } from '../../service/milestone-conversation.service';
import { MilestoneConversationThreadService } from '../../service/milestone-conversation-thread.service';

const groupChat = new MilestoneConversationService();
let conversationIdGlobal = '';
@Component({
  selector: 'app-milestone-conversation',
  templateUrl: './milestone-conversation.component.html',
  styleUrls: ['./milestone-conversation.component.css'],
})
export class MilestoneConversationComponent implements OnInit, OnDestroy {
  @Input() milestoneId: string = '';
  @Input() phaseId: any = '';
  @Input() workspaceId: any = '';

  milestoneConversationGroup$: Observable<any> = new Observable();
  myCohort: any;
  myTeams: any;
  currentGroupId: any = '';
  ConsumerSubscription;
  Consumer;
  conversationId = '';
  getMessageSubscription: Subscription = new Subscription();
  milestoneThreadCurrentThreadIdSubscription: Subscription = new Subscription();
  newConversationCreatedSubscription: Subscription = new Subscription();
  readGroupConversationSubscription: Subscription = new Subscription();
  newMessageAddedFromTheOtherUserSubscription: Subscription =
    new Subscription();

  constructor(
    private store: Store,
    private commonService: CommonService,
    private milestoneThreadService: MilestoneConversationThreadService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.milestoneConversationGroup$ = this.store
      .select(BaseSelector.fetchAllMilestoneRelatedConversationGroups)
      .pipe(
        tap((res) => {
          this.myCohort = res?.groups?.myCohort;
          this.myTeams = res?.groups?.myTeam;
          this.cdRef.detectChanges();
        })
      );
    this.addSubscriptionToWebSocket();
    this.getMessageSubscription = groupChat.chatSubject.subscribe((message) => {
      if (message !== null) {
        this.store.dispatch(
          BaseActions.AddMessageToConversationToRelatedToMilestone(message)
        );
      }
    });
    this.milestoneThreadCurrentThreadIdSubscription =
      this.milestoneThreadService.currentConversationId.subscribe(
        (currentThreadId) => {
          if (currentThreadId) {
            this.conversationId = currentThreadId;
            conversationIdGlobal = currentThreadId;
          }
        }
      );
    this.newConversationCreatedSubscription =
      this.milestoneThreadService.newConversationCreatedSubject.subscribe(
        (data) => {
          if (data) {
            this.unSubscribeSocket();
          }
        }
      );
    this.markGroupConversationAsRead();
    this.newMessageAddedByOtherUser();
  }
  changedSelectedTabId(event) {
    this.currentGroupId = event;
    this.store.dispatch(
      BaseActions.FetchAllThreadsConversationForMilestoneRequest({
        groupId: event,
        milestoneId: this.milestoneId,
      })
    );
  }
  /* add Subscription to the web socket */
  addSubscriptionToWebSocket() {
    const { webSocket } = v1URL;
    this.Consumer = ActionCable.createConsumer(
      `${webSocket.url(this.commonService.getAuthenticationToken())}`
    );
    this.ConsumerSubscription = this.Consumer.subscriptions.create(
      'ConversationChannel',
      {
        connected() {},
        disconnected() {},
        received(data) {
          if (data) {
            if (+conversationIdGlobal === data.conversationId) {
              groupChat.addMessage(data);
            } else if (data.kind === 'chat_event') {
              /*  groupChat.addNewConversation(data); */
            } else if (data.kind === 'read_direct_message') {
              /*    groupChat.markConversationAsRead(data); */
            } else if (data.kind === 'unread_group_message') {
              /* mark thread as read */
              /*
              TODO check should be added for the milestoneId
              */
              groupChat.markGroupThreadConversationAsRead(data);
            } else if (
              data.kind === 'chat' &&
              +conversationIdGlobal !== data.conversationId
            ) {
              groupChat.newMessageAddedFromTheOtherUser(data);
            }
          }
        },
      }
    );
  }
  sendMessage(event) {
    this.ConsumerSubscription.send(
      {
        body: event.text,
        attachments: event.attachments,
        mentioned_ids: event.mentioned_ids,
        conversation_id: this.conversationId,
        action: 'create_chat_message',
      },
      "identifier: { channel: 'ConversationChannel' },"
    );
  }
  unSubscribeSocket() {
    this.ConsumerSubscription?.unsubscribe();

    this.addSubscriptionToWebSocket();
  }
  readMessage(event) {
    this.ConsumerSubscription.send(
      {
        body: event,
        conversation_id: this.conversationId,
        action: 'update_last_read_message',
        message_id: event,
      },
      "identifier: { channel: 'ConversationChannel' },"
    );
  }
  markGroupConversationAsRead() {
    this.readGroupConversationSubscription =
      groupChat.markGroupThreadConversationAsReadSubject.subscribe(
        (conversationReadData) => {
          if (conversationReadData) {
            if (conversationReadData.milestone_id == +this.milestoneId) {
              if (conversationReadData.group_id == this.myCohort.id) {
                this.myCohort = {
                  ...this.myCohort,
                  unreadMessages: conversationReadData?.unread_messages,
                };
              } else if (conversationReadData.group_id == this.myTeams.id) {
                this.myTeams = {
                  ...this.myTeams,
                  unreadMessages: conversationReadData?.unread_messages,
                };
              }
              this.store.dispatch(
                BaseActions.MarkReadToSideNavItemsInMilestoneConversation({
                  teams: this.myTeams,
                  cohort: this.myCohort,
                })
              );
            }
          }
        }
      );
  }
  newMessageAddedByOtherUser() {
    this.newMessageAddedFromTheOtherUserSubscription =
      groupChat.newMessageAddedFromOtherUser.subscribe((data) => {
        if (data) {
          this.store.dispatch(
            BaseActions.MarkSideItemAsUnreadForNewMessageMilestoneConversation(
              data
            )
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.ConsumerSubscription?.unsubscribe();
    this.milestoneThreadCurrentThreadIdSubscription?.unsubscribe();
    this.getMessageSubscription?.unsubscribe();
    this.readGroupConversationSubscription?.unsubscribe();
    this.newMessageAddedFromTheOtherUserSubscription?.unsubscribe();

    this.cdRef?.detach();
  }
}

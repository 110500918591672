import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginFormComponent} from './unauthorized-pages/login-page/components/login-form/login-form.component';
import {LoginPageContainerComponent} from './unauthorized-pages/login-page/login-page-container/login-page-container.component';
import {LoginPageScreenComponent} from './unauthorized-pages/login-page/login-page-screen/login-page-screen.component';
import {SharedModule} from '../shared/shared.module';
import {FlexModule} from '@angular/flex-layout';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects} from './core/authentication.effects';
import {reducer} from './core/authentication.reducer';

import {RouterModule} from '@angular/router';
import {AuthenticationRoutes} from './authentication.routing';

import {
  ResetPasswordContainerComponent
} from './unauthorized-pages/resetPasswordPage/reset-password-container/reset-password-container.component';
import {ResetPasswordScreenComponent} from './unauthorized-pages/resetPasswordPage/reset-password-screen/reset-password-screen.component';
import {
  ResetPasswordRequestComponent
} from './unauthorized-pages/resetPasswordPage/components/reset-password-request/reset-password-request.component';
import {
  ResetPasswordEmailSentComponent
} from './unauthorized-pages/resetPasswordPage/components/reset-password-email-sent/reset-password-email-sent.component';
import {ResetPasswordSetComponent} from './unauthorized-pages/resetPasswordPage/components/reset-password-set/reset-password-set.component';
import {
  ResetPasswordSuccessComponent
} from './unauthorized-pages/resetPasswordPage/components/reset-password-success/reset-password-success.component';
import {
  CreateAccountContainerComponent
} from './unauthorized-pages/create-account-page/create-account-container/create-account-container.component';
import {CreateAccountScreenComponent} from './unauthorized-pages/create-account-page/create-account-screen/create-account-screen.component';
import {
  CreateAccountFormComponent
} from './unauthorized-pages/create-account-page/components/create-account-form/create-account-form.component';
import { WorkspaceSelectorScreenComponent } from './unauthorized-pages/workspace-selector/workspace-selector-screen/workspace-selector-screen.component';
import { WorkspaceSelectorContainerComponent } from './unauthorized-pages/workspace-selector/workspace-selector-container/workspace-selector-container.component';
import { WorkspaceComponent } from './unauthorized-pages/workspace-selector/components/workspace/workspace.component';


@NgModule({
  declarations: [
    LoginFormComponent,
    LoginPageContainerComponent,
    LoginPageScreenComponent,
    ResetPasswordContainerComponent,
    ResetPasswordScreenComponent,
    ResetPasswordRequestComponent,
    ResetPasswordEmailSentComponent,
    ResetPasswordSetComponent,
    ResetPasswordSuccessComponent,
    CreateAccountContainerComponent,
    CreateAccountScreenComponent,
    CreateAccountFormComponent,
    WorkspaceSelectorScreenComponent,
    WorkspaceSelectorContainerComponent,
    WorkspaceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexModule,
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature(
      [AuthenticationEffects]
    ),
    RouterModule.forChild(AuthenticationRoutes),
    RouterModule,
  ]
})
export class AuthenticationModule {}

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {CommonService} from 'src/app/modules/shared/services/common/common.service';
import * as fromRoot from '../../../../state/app.state';

@Injectable({
  providedIn: 'root',
})
export class ActionDetailsService {
  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
  ) {
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentions',
})
export class MentionsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    /* /mentions */ /* \mentions */
    let changeValue: string = value;
    if (value) {
      changeValue = changeValue.replace(
        `<a href="mentions::`,
        `<a class="mention-user" href="mentions::`
      );
    }
    return value;
    /*  const brokenValues = value.split('$$mention');
    let finalValue = '';
    for (let text of brokenValues) {
      if (text[0] != '{') {
        finalValue = finalValue + text;
      } else {
        const userData = JSON.parse(text);
        finalValue =
          finalValue +
          `<span class="mention-user" >` +
          '@' +
          userData.firstName +
          userData.lastName +
          '</span>';
      }
    }
    return finalValue; */
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from '../../../../shared/services/common/common.service';
import {UploadFileComponent} from '../../../../shared/components/upload-file/upload-file.component';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';

@Component({
  selector: 'app-milestone-resource',
  templateUrl: './milestone-resource.component.html',
  styleUrls: ['./milestone-resource.component.css'],
})
export class MilestoneResourceComponent implements OnInit {
  @Input() deliverable: any;

  constructor(
    public commonService: CommonService,
    private store: Store<fromRoot.State>
  ) {
  }

  ngOnInit(): void {
  }

  openUploadDialog() {
    if (this.deliverable?.id) {
      this.commonService.openDialog({
        data: {
          component: UploadFileComponent,
          maxAllowedUploads: 5,
          deliverableId: this.deliverable?.id,
          dialogClassNames:['upload-pop-up']
        },
      
      });
    } else {
      this.commonService.notification(
        'Uploading participant deliverables is disabled'
      );
    }
  }
}

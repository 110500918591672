import {Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import typeMap from '../../../../shared/constants/fileTypeToNameMap.constants';
import {CommonService} from '../../../../shared/services/common/common.service';
import {UserProfileComponent} from '../../../directory-page/components/user-profile/user-profile.component';
import * as BaseActions from '../../../core/base.actions';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatComponent implements OnInit {
  @Input() message: any;
  @Input() workspaceId: any = '';

  typeMap;
  attachments = [];

  constructor(
    private commonService: CommonService,
    private store: Store,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    Window['ChatComponent'] = this;
    this.typeMap = typeMap;
  }

  public removeEventListener: () => void;

  ngOnInit(): void {
    this.attachments = [...this.message.attachments];

    /* need to be removed not proper  */
    const divs = document.querySelectorAll('.mention-user');

    divs.forEach((el) =>
      el.addEventListener('click', (event: any) => {

      })
    );
  }

  getName(name: string) {
    if (name) {
      const splitName = name.split(' ');
      return splitName[0][0] + splitName[1][0];
    } else {
      return '';
    }
  }

  downloadFile(url) {
    this.commonService.openInNewTab(url);
  }

  getUserProfile(userData) {
    this.store.dispatch(
      BaseActions.FetchUserProfileForDirectoryPageRequest({
        userId: userData.id,
        workspace: this.workspaceId,
      })
    );
    const dialogBox = this.matDialog.open(UserProfileComponent, {
      width: '70%',
      height: '60%',
      panelClass: 'user-profile-pop-up',
      data: {
        workspaceId: this.workspaceId,
      },
    });
  }

  showMentions(value) {
    value = value.replaceAll(' target=\"_blank\"', '');
    value = value.replaceAll('<a href="mentions::', '<a id="');
    const brokenValues = value.split('@');
    let finalValue: any = '';
    for (let text of brokenValues) {
      if (text.includes('</a>&nbsp;')) {
        text = '@' + text;
      }
      if (text.includes('<a id="')) {
        let part = '';
        part = text.substring(text.lastIndexOf('=') + 2, text.lastIndexOf(`"`));

        text = text.replace(
          `<a id="`,
          `<a class="mention-user" onClick="Window.ChatComponent.showModel(` +
          part +
          `)" id="`
        );
        finalValue = finalValue + text;
      } else {
        finalValue = finalValue + text;
      }
    }
    return this.sanitizer.bypassSecurityTrustHtml(finalValue);
  }

  showModel(event: any) {
    this.store.dispatch(
      BaseActions.FetchUserProfileForDirectoryPageRequest({
        userId: event,
        workspace: this.workspaceId,
      })
    );
    const dialogBox = this.matDialog.open(UserProfileComponent, {
      width: '70%',
      height: '60%',
      panelClass: 'user-profile-pop-up',
      data: {
        workspaceId: this.workspaceId,
      },
    });
  }
}

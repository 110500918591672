import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {CommonService} from '../../../../../shared/services/common/common.service';
import {ActivatedRoute} from '@angular/router';
import {ResetPasswordService} from '../../services/reset-password.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-reset-password-set',
  templateUrl: './reset-password-set.component.html',
  styleUrls: ['./reset-password-set.component.css']
})
export class ResetPasswordSetComponent implements OnInit, OnDestroy {
  form: FormGroup;

  errorMessageMap = {
    password: {
      required: 'This field is required.',
      pattern: 'Invalid Password',
      minlength: 'Must be at least 8 characters'
    },
    confirmPassword: {
      required: 'This field is required.',
      pattern: 'Invalid Password',
      minlength: 'Must be at least 8 characters',
      passwordMatchFailed: 'Password and confirm password do not match'
    }
  };

  errorMessages: any = {};
  token: string;
  organizationId: string;

  queryParamsSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private resetPasswordService: ResetPasswordService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((values) => {
      const {token, organizationId} = values;
      this.token = token;
      this.organizationId = organizationId;
    })

    this.form = this.fb.group({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.commonService.passwordRegex)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.commonService.passwordRegex)
      ]),
    }, {validators: [this.validatePassword]});
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('confirmPassword')) {
      const password = control.get('password').value;
      const confirmPassword = control.get('confirmPassword').value;

      if (password && confirmPassword && password !== confirmPassword) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors ? control.get('confirmPassword').errors : {}),
          passwordMatchFailed: true
        })
      }
    }
    return null;
  }

  resetPassword() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      password,
      confirmPassword
    } = this.form.getRawValue();

    const payload = {
      token: this.token || 'tokenMissing',
      organizationId: this.organizationId,
      data: {
        password,
        password_confirmation: confirmPassword
      }
    };

    this.resetPasswordService.set.emit(payload);
  }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
  }
}

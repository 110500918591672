import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';
import * as AuthenticationSelectors from '../../../../../../authentication/core/authentication.selectors';
import { AuthenticatedWrapperService } from '../../service/authenticated-wrapper.service';
import * as AuthenticationActions from '../../../../../../authentication/core/authentication.actions';
import { MatDialog } from '@angular/material/dialog';
import { EditProfileComponent } from '../../../../edit-profile/edit-profile.component';
import * as BaseActions from '../../../../../../base/core/base.actions';
import * as BaseSelector from '../../../../../../base/core/base.selectors';
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  profileSubscription: Subscription;
  breadCrumb$: Observable<any> = new Observable();
  avatarText: string;
  userName: string;
  userProfileData: any;
  editProfileSubscription: Subscription;
  multiBreadCrumbs$:Observable<any>;

  constructor(
    private store: Store,
    private AuthWrapperService: AuthenticatedWrapperService,
    private commonService: CommonService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store.dispatch(BaseActions.FetchUserProfileRequest());
    this.profileSubscription = this.store
      .select(BaseSelector.getCurrentUserProfile)
      .subscribe((data) => {
        if (data) {
          this.avatarText = `${data.firstName?.charAt(0) || ''}${
            data.lastName?.charAt(0) || ''
          }`;
          this.userName = data.firstName + ' ' + data.lastName;

          this.userProfileData = data;
        }
      });
    this.breadCrumb$ = this.AuthWrapperService.getBreadCrumb();
    this.multiBreadCrumbs$=this.AuthWrapperService.getMultipleBreadCrumbs();
  }

  logout() {
    this.store.dispatch(AuthenticationActions.LogoutRequest(''));
  }

  ngOnDestroy() {
    this.profileSubscription?.unsubscribe();
    this.editProfileSubscription?.unsubscribe();
  }

  onEditProfileHandler() {
    let EditBox = this.dialog.open(EditProfileComponent, {
      data: this.userProfileData,
      hasBackdrop: true,
      disableClose: true,
      panelClass: ['edit-box'],
     /*  maxHeight: '95%',  */
       width: '55%',
     height: '86%',
    
    });
    this.editProfileSubscription = EditBox.afterClosed().subscribe(
      (updatedData) => {
        if (updatedData?.profileData) {
          if (updatedData?.profileData.phoneNumber !== '') {
            let updatedPhoneNumber =
              updatedData?.profileData.phoneNumber.replace('-', '');
            updatedPhoneNumber = updatedPhoneNumber.replace('(', '');
            updatedPhoneNumber = updatedPhoneNumber.replace(')', '');
            const modifiedData = {
              ...updatedData,
              profileData: {
                ...updatedData?.profileData,
                phoneNumber: updatedPhoneNumber,
              },
            };
            this.store.dispatch(
              AuthenticationActions.UpdateProfileDataRequest({
                payload: modifiedData.profileData,
              })
            );
          } else {
            this.store.dispatch(
              AuthenticationActions.UpdateProfileDataRequest({
                payload: updatedData.profileData,
              })
            );
          }
        }
      }
    );
  }
  onHelpIconClicked(){
    window.open("https://scribehow.com/page/Changemakers_Platform__Participant_How_To_Guide__Fs-4KzGsQUOX53quqdX4zg", '_blank');
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DirectoryService } from '../../../directory-page/service/directory.service';
import { GroupsNavigationService } from '../../service/groups-navigation.service';
import { MessageService } from '../../service/message.service';

@Component({
  selector: 'app-my-group-side-nav',
  templateUrl: './my-group-side-nav.component.html',
  styleUrls: ['./my-group-side-nav.component.css'],
})
export class MyGroupSideNavComponent implements OnInit, OnDestroy {
  @Input() directMessage: any;
  @Input() workspaceId: string = '';
  @Input() groups: any;
  @Input() myTeams: any;
  @Input() myCohort: any;
  @Input() allTeams: any;
  currentTabActive: any = '';
  directMessageSubscription: Subscription = new Subscription();
  currentTabSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private directoryService: DirectoryService,
    private activatedRoute: ActivatedRoute,
    private groupsNavigationService: GroupsNavigationService
  ) {}

  ngOnDestroy(): void {
    this.directMessageSubscription?.unsubscribe();
    this.currentTabSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.currentTabActive = this.myTeams.id;

    this.currentTabSubscription = this.activatedRoute.queryParams.subscribe(
      (params: any) => {
        if (
          params.groupId &&
          params.conversationType === 'groups'
        ) {
          this.currentTabActive = +params.groupId;
        }
        if (
          params.conversationId &&
          params.conversationType === 'direct'
        ) {
          this.currentTabActive = +params.conversationId;
        }
      }
    );

    this.messageService.threadCurrentPage.next(0);
    this.directMessageSubscription =
      this.directoryService.directMessageToUserSubject.subscribe((userData) => {
        if (userData === null) {
          /*     this.showMyCohort(); */
        } else {
          if (userData?.type === 'message-send') {
            /* this.directoryService.setEmptyToDirectMessage(); */
          } else {
            this.currentTabActive = 'new-direct-message';
          }
        }
      });
  }

  showDirectMessages(groupName) {
    this.currentTabActive = groupName.id;
    this.messageService.threadCurrentPage.next(0);
    const params: any = {
      conversationId: groupName.id,
      conversationType: 'direct',
      conversationName: groupName.name,
    };
    this.groupsNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: params,
      }
    );
  }

  showGroupMessages(id) {
    this.currentTabActive = id;
    this.messageService.threadCurrentPage.next(0);
    const params: any = {
      groupId: id,
      conversationType: 'groups',
    };
    this.groupsNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: params,
      }
    );
  }

  showMyTeams() {
    this.currentTabActive = this.myTeams.id;
    this.messageService.threadCurrentPage.next(0);
    const params: any = {
      groupId: this.myTeams.id,
      conversationType: 'groups',
    };
    this.groupsNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: params,
      }
    );
  }

  showMyCohort() {
    this.currentTabActive = this.myCohort.id;
    this.messageService.threadCurrentPage.next(0);
    const params: any = {
      groupId: this.myCohort.id,
      conversationType: 'groups',
    };
    this.groupsNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: params,
      }
    );
  }

  directNewMessage() {
    this.currentTabActive = 'new-direct-message';
    const params: any = {
      conversationType: 'new-direct-message',
    };
    this.groupsNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/changemakers/groups/workspace/${this.workspaceId}`],
      {
        queryParams: params,
      }
    );
  }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ResetPasswordService} from '../../services/reset-password.service';
import {CommonService} from '../../../../../shared/services/common/common.service';

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.css']
})
export class ResetPasswordRequestComponent implements OnInit {
  form: FormGroup;

  errorMessageMap = {
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email.'
    }
  };

  errorMessages: any = {};

  constructor(
    private fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.emailRegex)
      ])
    });
  }

  sendRequestPasswordEmail(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }
    const {email} = this.form.getRawValue();
    const payload = {
      email
    };

    this.resetPasswordService.request.emit(payload);
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField))
    };
  }
}

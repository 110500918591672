import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as BaseAction from '../../core/base.actions';
import { DirectoryService } from '../service/directory.service';
import { CommonService } from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-directory-container',
  templateUrl: './directory-container.component.html',
  styleUrls: ['./directory-container.component.css'],
})
export class DirectoryContainerComponent implements OnInit, OnDestroy {
  paramsSubscription: Subscription = new Subscription();
  workspaceId: string;
  filterParamsSubscription: Subscription = new Subscription();
  updateDirectoryAsParProfileChangesSubscription: Subscription =
    new Subscription();
  filters;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private directoryService: DirectoryService,
    private commonService:CommonService,
  ) {}

  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
    this.directoryService.setTeamsCurrentPageNumber(1);
    this.directoryService.setCohortCurrentPageNumber(1);
    this.updateDirectoryAsParProfileChangesSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.updateDirectoryAsParProfileChangesSubscription =
      this.directoryService.updateEventEmitter.subscribe((res) => {
        this.getQueryParams();
        this.store.dispatch(
          BaseAction.FetchDirectoryTeamsRequest({
            workspaceId: this.workspaceId,
          })
        );
      });
    this.paramsSubscription = this.activatedRoute.params.subscribe((params) => {
      this.workspaceId = params.workspaceId;
      this.getQueryParams();
      this.store.dispatch(
        BaseAction.FetchDirectoryTeamsRequest({
          workspaceId: this.workspaceId,
        })
      );
      this.store.dispatch(
        BaseAction.FetchDirectoryTeamRoleRequest({
          workspaceId: this.workspaceId,
        })
      );
      this.store.dispatch(
        BaseAction.FetchDirectoryTeamsOptionRequest({
          workspaceId: this.workspaceId,
        })
      );
    });
  }

  getQueryParams() {
    this.filterParamsSubscription = this.activatedRoute.queryParamMap.subscribe(
      (params: any) => {
        this.filters = params?.params;
        if (Object.keys(this.filters).length > 0) {
          this.store.dispatch(
            BaseAction.FetchDirectoryCohortRequest({
              workspaceId: this.workspaceId,
              filter: { ...this.filters },
            })
          );
        } else {
          this.store.dispatch(
            BaseAction.FetchDirectoryCohortRequest({
              workspaceId: this.workspaceId,
            })
          );
        }
      }
    );
  }
}

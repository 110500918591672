import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as baseActions from '../../../core/base.actions';

@Component({
  selector: 'app-collection-phases',
  templateUrl: './collection-phases.component.html',
  styleUrls: ['./collection-phases.component.css'],
})
export class CollectionPhasesComponent implements OnInit, OnChanges, OnDestroy {
  icon;
  visiblePhaseStyle = {stroke: 'white'};
  throttle = 50;
  scrollDistance = 2;
  @Input() phases: any;
  @Input() hasMore: any;
  @Output() changeSelectedPhaseName: EventEmitter<any> = new EventEmitter();
  @Input() workspaceId: any;
  @Input() selectedPhases: any;
  lastRightScroll = 0;
  isRightLast = false;

  showRightScrollIcon = false;
  showLeftScrollIcon = false;

  constructor(
    private router: Router,
    private store: Store,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.icon = this.phases.lockStatus === 0 ? 'arrow-right-s-line' : 'lock';
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showScrollRightIcons();
    this.showScrollLeftIcons();
  }

  loadMorePhases() {
    if (this.hasMore) {
      this.store.dispatch(
        baseActions.FetchPhasesNextPageRequest({
          organizationId: this.workspaceId,
        })
      );
    }
  }

  getMilestoneStatusStyles(status) {
    if (status === 0) {
      return 'mile-stone-span-not-started';
    } else if (status === 1) {
      return 'mile-stone-span-ongoing';
    } else if (status === 2) {
      return 'mile-stone-span-completed';
    }
  }

  getIconAccordingToLockStatus(lockStatus) {
    return lockStatus === 0 ? 'arrow-right-s-line' : 'lock';
  }

  getCardStyleAccordingToPhaseActiveStatus() {
  }

  scrollMileSToneLeft() {
    document.getElementById('phases_list').scrollLeft -= 150;
    this.isRightLast = false;
  }

  scrollMileSTone() {
    if (
      this.lastRightScroll !== (document.getElementById('phases_list').scrollLeft += 150)
    ) {
      document.getElementById('phases_list').scrollLeft += 150;
      this.lastRightScroll = document.getElementById(
        'phases_list'
      ).scrollLeft += 150;
      this.isRightLast = false;
    } else {
      this.isRightLast = true;
    }
    this.showScrollRightIcons();
    this.showScrollLeftIcons();
  }

  changeActivePhase(phase) {
    if (phase.lockStatus === 0) {
      this.changeSelectedPhaseName.emit(phase.name);
      this.router.navigate(
        [],
        {
          queryParams: {
            phase: phase.id,
          },
        }
      );
    }
  }

  iconStyles(phase) {
    if (phase?.id === this.selectedPhases) {
      return this.visiblePhaseStyle;
    }
  }

  milestoneClickedHandler(event, phaseId) {
    this.store.dispatch(baseActions.SetSelectedPhase({phaseId}));
    this.router.navigate([
      `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${phaseId}/milestone/${event}`,
    ]);
  }

  showScrollRightIcons() {
    this.showRightScrollIcon = document?.getElementById('phases_list')?.scrollWidth > document?.getElementById('phases_list')?.clientWidth;
    this.cdRef.detectChanges();
  }

  showScrollLeftIcons() {
    this.showLeftScrollIcon = document?.getElementById('phases_list')?.scrollLeft >
      document?.getElementById('phases_list')?.clientLeft;
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }
}

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import * as BaseActions from '../../../core/base.actions';
import * as BaseSelector from '../../../core/base.selectors';
import {DirectoryService} from '../../../directory-page/service/directory.service';

@Component({
  selector: 'app-new-direct-chat',
  templateUrl: './new-direct-chat.component.html',
  styleUrls: ['./new-direct-chat.component.css'],
})
export class NewDirectChatComponent implements OnInit, OnDestroy {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  nameCtrl = new FormControl('');
  nameList: Observable<string[]>;
  names: any[] = [];
  @Output() searchedUserHandler: EventEmitter<any> = new EventEmitter();
  @Input() workspaceId: string = '';
  newDirectMessageHandlerSubscription: Subscription = new Subscription();
  allNames: string[] = [];
  isMessageSend: boolean = false;

  @ViewChild('nameInput') nameInput: ElementRef<HTMLInputElement>;
  searchedUser$: Observable<any> = new Observable();
  x_timer: any;

  constructor(
    private store: Store,
    private directoryService: DirectoryService
  ) {
    this.nameList = this.nameCtrl.valueChanges.pipe(
      startWith(null),
      map((name: string | null) =>
        name ? this._filter(name) : this.allNames.slice()
      )
    );

    this.newDirectMessageHandlerSubscription =
      this.directoryService.directMessageToUserSubject.subscribe((userData) => {
        if (userData !== null) {
          if (userData?.type === 'message-send') {
            /* this.directoryService.setEmptyToDirectMessage(); */
          } else {
            this.names.push(userData);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.newDirectMessageHandlerSubscription?.unsubscribe();
    if (this.isMessageSend) {
      const data = {
        type: 'message-send',
      };
      this.directoryService.directMessageToUserSubject.next(data);
    } else {
     /*  this.directoryService.setEmptyToDirectMessage(); */
    }
  }

  ngOnInit() {
    this.searchedUser$ = this.store
      .select(BaseSelector.getAllSearchedUsersResult)
      .pipe(tap((res) => {
      }));
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.names.push(value);
    }
    event.chipInput!.clear();
    this.nameCtrl.setValue(null);
  }

  remove(name: string): void {
    const index = this.names.indexOf(name);
    if (index >= 0) {
      this.names.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.names.push(event.option.value);
    this.nameCtrl.setValue('');
    this.nameInput.nativeElement.value = '';
  }

  keyUpHandler(event) {
    clearTimeout(this.x_timer);
    this.x_timer = setTimeout(() => {
      this.searchUser();
    }, 1000);
  }

  searchUser() {
    this.searchedUserHandler.emit({searchedTerm: this.nameCtrl.value});
  }

  onMessageSendHandler(event) {
    this.isMessageSend = true;
    const userList = this.names.map((name) => name.id);

    const requestPayload = {
      thread_type: 'direct',
      user_ids: userList,
      messageBody: event.text,
      attachments: event.attachments
    };
    this.store.dispatch(
      BaseActions.StartNewDirectMessageConversationRequest({
        payload: requestPayload,
        workspaceId: this.workspaceId,
      })
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allNames.filter((name) =>
      name.toLowerCase().includes(filterValue)
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as BaseSelector from '../../../core/base.selectors';
import * as BaseActions from '../../../core/base.actions';
import { map, Observable, tap } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calander-card',
  templateUrl: './calander-card.component.html',
  styleUrls: ['./calander-card.component.css'],
})
export class CalanderCardComponent implements OnInit {
  selectedDate: any;
  loadingStatus$: Observable<boolean>;
  calenderDetails$: Observable<any>;
  changeSelectedDateCheck: boolean = false;
  weekStartingDay;
  @Input() workspace: string;

  constructor(private store: Store, private date: DatePipe) {}

  ngOnInit(): void {
    this.changeSelectedDateCheck = false;

    this.selectedDate = new Date();
    this.selectedDate = this.date.transform(this.selectedDate, 'yyyy/MM/dd');
    this.calenderDetails$ = this.store
      .select(BaseSelector.getCalenderDetails)
      .pipe(
        map((calender: any) => {
          return this.modifyData(calender);
        })
      );
  }

  modifyData(calender) {
    const weekdata = [];
    for (let keys in calender.calender) {
      if (keys === 'sun') {
        if (this.changeSelectedDateCheck === true) {
          this.selectedDate = this.date.transform(
            calender.calender[keys].date,
            'yyyy/MM/dd'
          );
        }
        this.weekStartingDay = this.selectedDate = this.date.transform(
          calender.calender[keys].date,
          'yyyy/MM/dd'
        );
        weekdata.push({
          ...calender.calender[keys],
          count: 1,
          label: 's',
        });
      } else if (keys === 'mon') {
        weekdata.push({
          ...calender.calender[keys],
          count: 2,
          label: 'm',
        });
      } else if (keys === 'tue') {
        weekdata.push({
          ...calender.calender[keys],
          count: 3,
          label: 't',
        });
      } else if (keys === 'wed') {
        weekdata.push({
          ...calender.calender[keys],
          count: 4,
          label: 'w',
        });
      } else if (keys === 'thu') {
        weekdata.push({
          ...calender.calender[keys],
          count: 5,
          label: 'th',
        });
      } else if (keys === 'fri') {
        weekdata.push({
          ...calender.calender[keys],
          count: 6,
          label: 'f',
        });
      } else if (keys === 'sat') {
        weekdata.push({
          ...calender.calender[keys],
          count: 7,
          label: 'sa',
        });
      }
    }
    const sorted = weekdata.sort((a, b) => a.count - b.count);
    if (this.changeSelectedDateCheck === false) {
      this.selectedDate = new Date();
      this.selectedDate = this.date.transform(this.selectedDate, 'yyyy/MM/dd');
    }

    return { ...calender, formateData: sorted };
  }

  changeSelectedDate(data: any) {
    this.selectedDate = this.date.transform(data.date, 'yyyy/MM/dd');

    document.getElementById(`${data.count}`)?.scrollIntoView({ behavior: 'smooth' });
  }

  loadNextWeekData() {
    const nextWeekDate = new Date(this.weekStartingDay);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);

    const payload = {
      date: this.date.transform(nextWeekDate, 'yyyyMMdd'),
      workspace: this.workspace,
    };
    this.changeSelectedDateCheck = true;
    this.store.dispatch(BaseActions.fetchDashboardWeekDataRequest(payload));
  }

  loadPreviousWeekData() {
    const previousWeekDate = new Date(this.weekStartingDay);
    previousWeekDate.setDate(previousWeekDate.getDate() - 7);
    const payload = {
      date: this.date.transform(previousWeekDate, 'yyyyMMdd'),
      workspace: this.workspace,
    };
    this.changeSelectedDateCheck = true;
    this.store.dispatch(BaseActions.fetchDashboardWeekDataRequest(payload));
  }

  checkForDateMatch(date) {
    if (this.selectedDate === date) {
      return 'date-title-active';
    } else {
      return 'date-title';
    }
  }
}

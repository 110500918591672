import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../../../../shared/services/common/common.service';

@Component({
  selector: 'app-dropdown-panel',
  templateUrl: './dropdown-panel.component.html',
  styleUrls: ['./dropdown-panel.component.css']
})
export class DropdownPanelComponent implements OnInit {
  singleSelectOptions = [
    {
      key: 1,
      value: 'Admin'
    },
    {
      key: 2,
      value: 'User'
    },
    {
      key: 3,
      value: 'Other'
    }
  ]

  dropdownsForm: FormGroup;

  errorMessageMap = {
    singleSelectValue: {
      required: 'This field is required.',
    }
  };

  errorMessages: any = {};

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.dropdownsForm = this.fb.group({
      singleSelectValue: new FormControl({value: '', disabled: false}, [Validators.required])
    });
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.dropdownsForm, this.errorMessageMap, currentField))
    };
  }

  validateForm() {
    if (this.dropdownsForm.invalid) {
      this.checkForErrors();
      return;
    }
  }
}

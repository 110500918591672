import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SocketService {
  constructor() {
  }

  createSocketConnection() {

  }

  sendMessageToSocket() {
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as BaseActions from '../../../core/base.actions';
import * as BaseSelector from '../../../core/base.selectors';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-milestone-conversation-channel',
  templateUrl: './milestone-conversation-channel.component.html',
  styleUrls: ['./milestone-conversation-channel.component.css'],
})
export class MilestoneConversationChannelComponent implements OnInit {
  constructor(private store: Store) {}
  conversation$: Observable<any> = new Observable();
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  hasMoreMessages: boolean = false;
  conversationType = 'groups';
  @Input() conversationId: string = '';
  @Output() sendMessageHandler: EventEmitter<any> = new EventEmitter();
  @Output() lastMessageReadHandler: EventEmitter<any> = new EventEmitter();
  @ViewChild('chatDiv') private chatDiv: ElementRef;
  @Input()workspaceId:any
  allMessages: any[] = [];
  lastMessageId;
  lastMessageReadId;
  oldLastMessageReadId;
  ngOnInit(): void {
    this.conversation$ = this.store
      .select(BaseSelector.getAllConversationMessagesRelatedToMilestone)
      .pipe(
        tap((res) => {
          this.scrollToBottom();
          this.allMessages = res?.messages;
          this.readLastMessage(this.allMessages);

          this.hasMoreMessages = res?.hasMore;
          if (this.allMessages?.length > 0) {
            this.lastMessageId = this.allMessages[0]?.id;
          }
        })
      );
  }
  dateCheck(firstMessageDate, previousMessageDate) {
    return firstMessageDate !== previousMessageDate;
  }
  sendMessage(event) {
    this.sendMessageHandler.emit(event);
  }
  showScrollLeftIcons() {
    if (
      document?.getElementById('chatDiv')?.scrollLeft >
      document?.getElementById('chatDiv')?.clientLeft
    ) {
      return true;
    } else return true;
  }
  onUp() {
    /* need to write the logic for the pagination */
    if (this.hasMoreMessages === true) {
      this.store.dispatch(
        BaseActions.FetchAllConversationRelatedToThreadsMilestoneNextPageRequest(
          {
            conversationId: this.conversationId,
            lastMessageId: this.lastMessageId,
          }
        )
      );
    }
  }
  onScrollDown() {}
  scrollToBottom(): void {
    try {
      this.chatDiv.nativeElement.scrollTop =
        this.chatDiv.nativeElement.scrollHeight;
    } catch (err) {}
  }
  readLastMessage(messages: any) {
    if (messages?.length > 0) {
      this.lastMessageReadId = messages[messages.length - 1]?.id;
      if (this.lastMessageReadId !== this.oldLastMessageReadId) {
        const lastReadMessage = messages[messages.length - 1]?.id;
        this.lastMessageReadHandler.emit(lastReadMessage);
        this.oldLastMessageReadId = this.lastMessageReadId;
      }
    }
  }
}

import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['../../styles.css', './password-input.component.css']
})
export class PasswordInputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() secondaryLabel: string;
  @Input() isRequired: boolean;
  @Input() placeholder: string;
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() error: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() maxLength = '100';
  @Input() minLength = '0';
  @Input() max: number;
  @Input() min: number;
  @Input() autoComplete:string
  @Output() checkForErrors = new EventEmitter();

  fieldSubscription: Subscription;

  constructor() {
  }

  // Do not show field as touched and having error when clicked outside the field
  @HostListener('focusout', ['$event'])
  onBlur(event) {
    this.parentFormGroup.controls[this.controlName].markAsUntouched();
  }

  ngOnInit(): void {
    this.fieldSubscription = this.parentFormGroup.controls[this.controlName].valueChanges.subscribe((value) => {
      // If field is marked as touched, mark it as untouched
      if (this.parentFormGroup.controls[this.controlName].touched) {
        this.parentFormGroup.controls[this.controlName].markAsUntouched();
      }

      // Get errors on the form field
      const errors = this.parentFormGroup.controls[this.controlName].errors;

      // If errors exist, handle it
      if (errors) {
        // Clear errors on the form field
        this.parentFormGroup.controls[this.controlName].setErrors(null);

        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);

        // Add errors again to the form field for showing messages in the future
        this.parentFormGroup.controls[this.controlName].setErrors(errors);
      } else {
        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);
      }
    });
  }

  ngOnDestroy() {
    this.fieldSubscription?.unsubscribe();
  }

}

import {environment} from '../../../../environments/environment';

const baseURL = environment.apiURL;

const v1Base = `${baseURL}/v1`;
const socketUrl = `${environment.socketUrl}`;
export const v1URL = {
  login: {
    method: `post`,
    url: `${v1Base}/sessions`,
  },
  logout: {
    method: `delete`,
    url: `${v1Base}/sessions`,
  },
  account: {
    create: {
      method: `put`,
      url: `${v1Base}/invitations`,
    },
    update: {
      method: `post`,
      url: `${v1Base}/account/update`,
    },
  },
  password: {
    forgot: {
      method: `post`,
      url: `${v1Base}/password_resets`,
    },
    reset: {
      method: `put`,
      url: `${v1Base}/password_resets`,
    },
    update: {
      method: `post`,
      url: `${v1Base}/password/update`,
    },
  },
  actionDetailsPage: {
    method: 'get',
    url: (
      workspaceId: string,
      phaseId: string,
      milestoneId: string,
      actionId: string
    ) => {
      return `${v1Base}/workspaces/${workspaceId}/phases/${phaseId}/milestones/${milestoneId}/action_steps/${actionId}`;
    },
  },
  milestoneDetailsActionSteps: {
    method: 'get',
    url: (
      workspaceId: string,
      phaseId: string,
      milestoneId: string,
      page: number,
      teamRoles?:string
    ) => {
      return  teamRoles && teamRoles.length > 0?  `${v1Base}/workspaces/${workspaceId}/phases/${phaseId}/milestones/${milestoneId}/action_steps?teamRoles=${teamRoles}&page=${page}`:
       `${v1Base}/workspaces/${workspaceId}/phases/${phaseId}/milestones/${milestoneId}/action_steps?page=${page}`;
    },
  },
  milestoneDetails: {
    method: 'get',
    url: (workspaceId: string, phaseId: string, milestoneId: string) => {
      return `${v1Base}/workspaces/${workspaceId}/phases/${phaseId}/milestones/${milestoneId}`;
    },
  },
  allPhases: {
    method: 'get',
    url: (workspaceId: string) => {
      return `${v1Base}/workspaces/${workspaceId}/phases`;
    },
  },
  allNextPhases: {
    method: 'get',
    url: (workspaceId: string, page: string) => {
      return `${v1Base}/workspaces/${workspaceId}/phases?page=${page}`;
    },
  },
  phasesMileStone: {
    method: 'get',
    url: (workspaceId, phaseId, page, teamRoles) => {
      return teamRoles !== ''
        ? `${v1Base}/workspaces/${workspaceId}/phases/${phaseId}/milestones?page=${page}&teamRoles=${teamRoles}`
        : `${v1Base}/workspaces/${workspaceId}/phases/${phaseId}/milestones?page=${page}`;
    },
  },
  fetchDashboardMilestone: {
    method: 'get',
    url: (workspaceId, page) => {
      return `${v1Base}/workspaces/${workspaceId}/dashboard?page=${page}`;
    },
  },
  fetchAllTeamRoles: {
    method: 'get',
    url: (workspaceId) => {
      return `${v1Base}/workspaces/${workspaceId}/team_roles`;
    },
  },
  fetchDashBoardWeekData: {
    method: 'get',
    url: (payload) => {
      const {workspace, date} = payload;
      return `${v1Base}/workspaces/${workspace}/week.json?startDate=${date}`;
    },
  },
  fetchSasToken: {
    method: 'get',
    url: (payload) =>
      `${v1Base}/workspaces/${payload.workspaceId}/phases/${payload.phaseId}/milestones/${payload.milestoneId}/get_sas_token`,
  },
  submitParticipantMilestoneDeliverableSuccess: {
    method: 'put',
    url: (payload) =>
      `${v1Base}/workspaces/${payload.workspaceId}/phases/${payload.phaseId}/milestones/${payload.milestoneId}/deliverables/${payload.deliverableId}`,
  },
  getOrganizationDetails: {
    method: 'get',
    url: (orgId) => `${v1Base}/organizations/${orgId}`,
  },
  updateProfile: {
    method: 'put',
    url: `${v1Base}/profile`,
  },
  getSasTokenForProfileImageUpload: {
    method: 'get',
    url: `${v1Base}/profile/get_sas_token`,
  },
  getDirectoryTeams: {
    method: 'get',
    url: (orgId, page) => `${v1Base}/users/team?page=${page}`,
  },
  getDirectoryCohort: {
    method: 'get',
    url: (orgId, page, filter?) => {
      if (filter) {
        if (filter.team_id && filter.team_roles) {
          return `${v1Base}/users/cohort?page=${page}&team_ids=${filter.team_id}&team_roles=${filter.team_roles}`;
        } else if (!filter.team_id) {
          return `${v1Base}/users/cohort?page=${page}&team_roles=${filter.team_roles}`;
        } else if (!filter.team_roles) {
          return `${v1Base}/users/cohort?page=${page}&team_ids=${filter.team_id}`;
        }
      } else {
        return `${v1Base}/users/cohort?page=${page}`;
      }
    },
  },
  getAllTeams: {
    method: 'get',
    url: (workspaceId) => `${v1Base}/workspaces/${workspaceId}/teams`,
  },
  getAllDirectMessages: {
    method: 'get',
    url: `${v1Base}/chat/direct_conversations`,
  },
  getAllMessages: {
    method: 'get',
    url: (conversationId, lastMessageId?) => {
      let url = `${v1Base}/chat/conversations/${conversationId}/messages`;
      if (lastMessageId) {
        url += `?last_message_id=${lastMessageId}`
      }
      return url;
    }
  },
  getAllMessagesNextPage: {
    method: 'get',
    url: (conversationId, page) =>
      `${v1Base}/chat/conversations/${conversationId}/messages?last_message_id=${page}`,
  },
  getAllGroupsForConversation: {
    method: 'get',
    url: `${v1Base}/chat/groups`,
  },
  getAllThreadsForGroups: {
    method: 'get',
    url: (groupId, page) =>
      `${v1Base}/chat/conversations?group_id=${groupId}&page=${page}`,
  },
  createConversation: {
    method: 'post',
    url: `${v1Base}/chat/conversations`,
  },
  searchUser: {
    method: 'get',
    url: (workspaceId, searchedTerm) =>
      `${v1Base}/users?search=${searchedTerm}`,
  },
  getUsers: {
    method: 'get',
    url: (payload) => {
      const {search, groupId, conversationId, userId} = payload;

      let url = `${v1Base}/users`;

      if (userId) {
        url += `/${userId}`;
        return url;
      }

      let hasSearch = false;
      if (search || (groupId || conversationId)) {
        url += `?${search ? 'search=' + search : ''}`;
        hasSearch = !!search;
      }

      if (groupId) {
        url += `${hasSearch ? '&' : ''}group_id=${groupId}`
      } else if (conversationId) {
        url += `${hasSearch ? '&' : ''}conversation_id=${conversationId}`
      }

      return url;
    }
  },
  searchUserForMentions: {
    method: 'get',
    url: (workspaceId, searchedTerm, conversationId) =>
      `${v1Base}/users?search=${searchedTerm}&conversation_id=${conversationId}`,
  },
  searchUserForMentionsForNewGroups: {
    method: 'get',
    url: (workspaceId, searchedTerm, groupId) =>
      `${v1Base}/users?search=${searchedTerm}&group_id=${groupId}`,
  },
  webSocket: {
    url: (token) => `${socketUrl}${token}`,
  },
  userProfileDirectory: {
    method: 'get',
    url: (workspace, userId) => `${v1Base}/users/${userId}`,
  },
  fetchChatSasToken: {
    method: 'get',
    url: (payload) => `${v1Base}/chat/conversations/get_sas_token`,
  },
  fetchMilestoneRelatedConversation: {
    method: 'get',
    url: (milestoneId) => `${v1Base}/chat/groups?milestone=${milestoneId}`,
  },
  fetchAllMilestoneRelatedThreads: {
    method: 'get',
    url: (groupId, milestone) =>
      `${v1Base}/chat/conversations?group_id=${groupId}&milestone_id=${milestone}`,
  },
  fetchUsersProfile: {
    method: 'get',
    url: `${v1Base}/profile`,
  },
  fetchUnreadMessageCountForTheGroupIcon: {
    method: 'get',
    url: `${v1Base}/workspaces/side_nav_count`,
  },
  fetchFilesAccordingToMilestone: {
    method: 'get',
    url: (milestoneId: number, pageNumber: number) =>
      `${v1Base}/milestones/${milestoneId}/documents?page=${pageNumber}`,
  },
};

import {EventEmitter, Injectable} from '@angular/core';
import {AuthenticationService} from '../../../core/authentication.service'

@Injectable({
  providedIn: 'root'
})
export class CreateAccountService {

  createAccount = new EventEmitter();
  getOrganizationId = new EventEmitter();

  constructor(private authService: AuthenticationService) {
  }

  getOrgDetails(orgId) {
    return this.authService.getOrganizationDetails(orgId)
  }
}

import { Component, OnInit ,Input} from '@angular/core';


@Component({
  selector: 'app-milestone-status',
  templateUrl: './milestone-status.component.html',
  styleUrls: ['./milestone-status.component.css']
})
export class MilestoneStatusComponent implements OnInit {

  constructor() { }
  @Input() completionStatus:any

  ngOnInit(): void {
  }

}

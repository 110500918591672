import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-milestone-conversation-side-nav',
  templateUrl: './milestone-conversation-side-nav.component.html',
  styleUrls: ['./milestone-conversation-side-nav.component.css'],
})
export class MilestoneConversationSideNavComponent
  implements OnInit, OnChanges, OnDestroy
{
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
  }
  FirstTimeNavigate: boolean = false;
  ngOnChanges(): void {
    if (this.myCohort && this.FirstTimeNavigate == true) {
      this.showMyCohort();
      this.FirstTimeNavigate = false;
    }
  }
  currentTabActive: string = '';
  @Input() myCohort: any;
  @Input() myTeams: any;
  @Input() groups: any;
  @Input() milestoneId: any;
  @Input() phaseId: any;
  @Input() workspaceId: any;
  @Output() currentSelectedGroupId: EventEmitter<any> = new EventEmitter();
  paramsSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    /*  this.showMyCohort(); */
    this.FirstTimeNavigate = true;
    this.paramsSubscription = this.activatedRoute.params.subscribe((params) => {
      if (params) {
        this.showMyCohort();
      }
    });
  }
  showMyTeams() {
    this.currentTabActive = this.myTeams?.id;
    const params = {
      chat: 'groups',
      groupId: this.currentTabActive,
    };
    this.currentSelectedGroupId.emit(this.currentTabActive);
  }
  showMyCohort() {
    this.currentTabActive = this.myCohort?.id;
    const params = {
      chat: 'groups',
      groupId: this.currentTabActive,
    };
    this.currentSelectedGroupId.emit(this.currentTabActive);
  }
}

import {AfterViewInit, Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.css']
})
export class BasicDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('anchor', {static: true, read: ViewContainerRef}) anchor;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BasicDialogComponent>,
    private resolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.anchor.clear();
    const {component} = this.data;
    delete this.data.component;
    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef = this.anchor.createComponent(factory);
    componentRef.instance.data = this.data;
  }

  ngAfterViewInit() {
  }

  close() {
    this.dialogRef.close();
  }
}

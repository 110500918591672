import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-date',
  templateUrl: './chat-date.component.html',
  styleUrls: ['./chat-date.component.css']
})
export class ChatDateComponent implements OnInit {

  @Input() date: any

  constructor() {
  }

  ngOnInit(): void {
  }

}

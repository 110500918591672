import {Injectable} from '@angular/core';
import {CommonService} from '../services/common/common.service';


import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private commonServices: CommonService,
              private http: HttpClient) {
  }

}



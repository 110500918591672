import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-workspace-selector-container',
  templateUrl: './workspace-selector-container.component.html',
  styleUrls: ['./workspace-selector-container.component.css'],
})
export class WorkspaceSelectorContainerComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }

}

import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AuthenticationService} from 'src/app/modules/authentication/core/authentication.service';
import {CommonService} from '../../services/common/common.service';
import * as AuthAction from '../../../authentication/core/authentication.actions';
import {Observable, Subscription} from 'rxjs';
import * as AuthSelector from '../../../authentication/core/authentication.selectors';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {AuthenticatedWrapperService} from '../application-wrappers/authenticated-wrapper/service/authenticated-wrapper.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  acceptedFileTypes = ['image/jpeg', 'image/png'];
  profileUrl = '';
  email: string;
  form: FormGroup;
  disableSaveButton = true;
  changePasswordClicked$: Observable<boolean> = new Observable();
  errorMessageMap = {
    firstName: {
      required: 'This field is required.',
    },
    lastName: {
      required: 'This field is required.',
    },
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email',
    },
    phoneNumber: {
      required: 'This field is required',
    },
    organization: {
      required: 'This field is required.',
    },
    bio: {
      required: 'This field is required.',
    },
    title: {
      required: 'This field is required.',
    },
  };
  formValues: any = {};
  errorMessages: any = {};
  uploadProcessTracker = [];
  sasTokenSubscription: Subscription;
  uploadSubscription: Subscription;
  sssToken;
  uploadFile: any;

  constructor(
    private dialogRef: MatDialogRef<EditProfileComponent>,
    private fb: FormBuilder,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public userData,
    private authService: AuthenticationService,
    private store: Store,
    private authenticatedWrapperService: AuthenticatedWrapperService
  ) {
    this.store.dispatch(AuthAction.FetchSasTokenForProfileImageRequest());
    this.email = this.formValues.email;
  }

  ngOnDestroy(): void {
    this.sasTokenSubscription?.unsubscribe();
    this.authenticatedWrapperService.changePasswordClickedHandler(false);
  }

  ngOnInit() {
    this.authenticatedWrapperService.changePasswordClickedHandler(false);
    this.changePasswordClicked$ =
      this.authenticatedWrapperService.changePasswordClicked;
    this.sasTokenSubscription = this.store
      .select(AuthSelector.GetProfileSasToken)
      .subscribe((token) => {
        this.sssToken = token;
      });
    this.formValues = this.userData;

    this.form = new FormGroup({
      firstName: new FormControl(
        {
          value: this.formValues?.firstName || '',
          disabled: false,
        },
        [Validators.required]
      ),
      lastName: new FormControl(
        {
          value: this.formValues?.lastName || '',
          disabled: false,
        },
        [Validators.required]
      ),
      email: new FormControl(
        {
          value: this.formValues?.email || '',
          disabled: true,
        },
        [Validators.required, Validators.pattern(this.commonService.emailRegex)]
      ),
      phoneNumber: new FormControl(
        {
          value: this.formValues?.phoneNumber || '',
          disabled: false,
        },
        [Validators.required]
      ),
      title: new FormControl(
        {
          value: this.formValues?.title || '',
          disabled: false,
        },
        [Validators.required]
      ),

      bio: new FormControl(
        {
          value: this.formValues?.bio || '',
          disabled: false,
        },
        [Validators.required]
      ),
      hiddenEmail: new FormControl(this.formValues?.hiddenEmail),
      hiddenPhoneNumber: new FormControl(this.formValues?.hiddenPhoneNumber),
      displayImage: new FormControl(''),
    });
    this.profileUrl = this.formValues.displayImage;
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  editProfile() {
    this.dialogRef.close({profileData: this.form.value});
  }

  cancelEdit() {
    this.dialogRef.close(null);
  }

  changePasswordRequest() {
    this.store.dispatch(
      AuthAction.ChangePasswordForEditProfileRequest({
        email: this.userData.email,
      })
    );
  }

  fileUpload(event) {
    this.disableSaveButton = false;
    const files = this.fileInput.nativeElement.files;
    let invalidTypeCount = 0;
    // tslint:disable-next-line:forin
    for (const key in files) {
      const file = files[key];
      if (file instanceof File) {
        if (!this.acceptedFileTypes.includes(file.type)) {
          invalidTypeCount++;
          continue;
        }
        if (file.size > 10000000) {
          this.commonService.notification(
            'File size can not exceed 10Mb',
            'warning'
          );
          return;
        }

        const suffix = new Date().getTime();
        const fileObject = {
          type: 'image',
          uploadStatus: 1,
          name:
            file.name.substring(0, file.name.lastIndexOf('.')) +
            '-' +
            suffix +
            file.name.substring(file.name.lastIndexOf('.')),
          size: file.size,
          fileName: file.name,
          data: file,
          progress: 0,
          hasError: false,
          uploadUrl: null,
          loading: false,
          fileType: file.type,
        };

        fileObject.uploadUrl = `${this.sssToken.host}/${this.sssToken.container}/${fileObject.name}?${this.sssToken.sasToken}`;

        this.uploadFile = fileObject;

        this.uploadFiles(fileObject);
      }
    }

    if (invalidTypeCount) {
      this.commonService.notification(
        'This file type is not supported. Please upload the image with file type as .PNG or .JPEG',
        'danger'
      );
    }
    this.fileInput.nativeElement.value = '';
  }

  async uploadFiles(fileObject) {
    this.uploadProcessTracker = [...this.uploadProcessTracker, 1];
    const payload = {
      url: fileObject.uploadUrl,
      data: fileObject.data,
      fileType: fileObject.fileType,
    };

    fileObject.loading = true;
    this.uploadSubscription = this.commonService
      .uploadImageToCloud(payload)
      .subscribe(
        (uploadResponse) => {
          if (uploadResponse.type === HttpEventType.UploadProgress) {
            fileObject.uploadStatus = 2;
            fileObject.progress = Math.round(
              (100 * uploadResponse.loaded) / uploadResponse.total
            );
          } else if (uploadResponse instanceof HttpResponse) {
            this.uploadProcessTracker = [...this.uploadProcessTracker].slice(1);
            fileObject.uploadStatus = 3;
          }
        },
        (err) => {
          this.commonService.notification(
            `This file type is not supported. Please upload a JPG or PNG file.`,
            'danger'
          );
          this.uploadProcessTracker = [...this.uploadProcessTracker].slice(1);
          fileObject.uploadStatus = 4;
        },
        () => {
          fileObject.loading = false;
          this.profileUrl = payload.url;
          this.form.controls['displayImage'].setValue(fileObject.name);
          this.disableSaveButton = false;
        }
      );
  }

  logout() {
    this.dialogRef.close(null);
    this.store.dispatch(AuthAction.LogoutRequest(''));
  }

  checkToSave() {
    if (this.form.dirty == true || this.disableSaveButton == false) {
      return false;
    } else {
      return true;
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {Router} from '@angular/router';
import {LoginPageService} from '../../services/login-page.service';
import {CommonService} from '../../../../../shared/services/common/common.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  form: FormGroup;

  errorMessageMap = {
    email: {
      required: 'This field is required.',
      pattern: 'Invalid Email.',
    },
    password: {
      required: 'This field is required.',
      minlength: 'Password should be at least 8 characters long',
    },
  };

  errorMessages: any = {};

  constructor(
    private loginServices: LoginPageService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.emailRegex),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  login(): void {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }
    const {email, password} = this.form.getRawValue();
    const payload = {
      email,
      password,
    };

    this.loginServices.login.emit(payload);
  }

  checkForErrors(currentField?) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  ngOnDestroy() {
  }
}

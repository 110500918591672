import {Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CreateAccountService} from 'src/app/modules/authentication/unauthorized-pages/create-account-page/services/create-account.service';
import {AuthenticationService} from '../../../../../authentication/services/authentication.service';
import {pluck, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-unauthorized-wrapper-type-two',
  templateUrl: './unauthorized-wrapper-type-two.component.html',
  styleUrls: ['./unauthorized-wrapper-type-two.component.css'],
})
export class UnauthorizedWrapperTypeTwoComponent implements OnInit {
  organizationId;
  organizationLogo$: Observable<any>;
  @ViewChild('org', {static: false}) org: ElementRef;
  orgLogo: string;

  constructor(
    private authenticationService: AuthenticationService,
    private createAccountService: CreateAccountService
  ) {
  }

  ngOnInit(): void {
    this.organizationLogo$ = this.authenticationService.organizationId$.pipe(
      switchMap((org) => {
        if (org) {
          return this.createAccountService.getOrgDetails(org);
        } else {
          return of([]);
        }
      }),
      pluck('data'),
      pluck('logo')
    );
  }

  onError() {
    this.org.nativeElement.className = 'hidden';
  }
}

/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthenticationState} from './authentication.reducer';

// Selector functions
const AuthenticationState =
  createFeatureSelector<AuthenticationState>('authentication');

export const getResetPasswordEmail = createSelector(
  AuthenticationState,
  (state) => state.resetPasswordEmail
);

export const getProfile = createSelector(
  AuthenticationState,
  (state) => state.profile
);

export const getCurrentRole = createSelector(
  AuthenticationState,
  (state) => state?.profile?.selectedWorkspace?.teamRole?.id
);

export const GetProfileSasToken = createSelector(
  AuthenticationState,
  (state) => state.profileSasToken
);
export const getSelectedWorkspace = createSelector(
  AuthenticationState,
  (state) => state.profile.selectedWorkspace
);
export const getAllWorkspaces = createSelector(
  AuthenticationState,
  (state) => state.profile.availableWorkspaces
)

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SocketService} from 'src/app/modules/shared/services/socket/socket.service';

@Component({
  selector: 'app-discussions',
  templateUrl: './discussions.component.html',
  styleUrls: ['./discussions.component.css'],
})
export class DiscussionsComponent implements OnInit {
  @Input() message$;
  @Output() messageSendHandler: EventEmitter<any> = new EventEmitter();
  @Output() readLastMessageHandler: EventEmitter<any> = new EventEmitter();
  @Input() workspaceId: any;
  @Input() groupId: any;

  constructor(private groupChatService: SocketService) {
  }

  ngOnInit(): void {
  }

  sendMessage(event) {
    this.messageSendHandler.emit(event);
  }

  readMessage(event) {
    this.readLastMessageHandler.emit(event)
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import * as BaseSelector from '../../core/base.selectors';
import * as BaseAction from '../../core/base.actions';
import { DirectoryService } from '../service/directory.service';
import { GroupsNavigationService } from '../../groups-page/service/groups-navigation.service';

@Component({
  selector: 'app-directory-screen',
  templateUrl: './directory-screen.component.html',
  styleUrls: ['./directory-screen.component.css'],
})
export class DirectoryScreenComponent implements OnInit {
  teams$: Observable<any>;
  cohort$: Observable<any>;
  teamsRole$: Observable<any>;
  teamsOption$: Observable<any>;
  activeTab: string = 'myteams';
  teamsHasMore: boolean;
  cohortHasMore: boolean;
  selectedTeamRoles: FormControl = new FormControl();
  selectedTeams: FormControl = new FormControl();
  @Input() filterParams: any = {};
  @Input() workspaceId: string;
  selectedTabIndex = 0;
  constructor(
    private store: Store,
    private router: Router,
    private directoryService: DirectoryService,
    private groupNavigationService: GroupsNavigationService
  ) {}

  ngOnInit(): void {
    this.teams$ = this.store.select(BaseSelector.getDirectoryTeams).pipe(
      tap((teams) => {
        this.teamsHasMore = teams.hasMore;
      })
    );
    this.cohort$ = this.store.select(BaseSelector.getDirectoryCohort).pipe(
      tap((cohort) => {
        this.cohortHasMore = cohort.hasMore;
      })
    );
    this.teamsRole$ = this.store.select(BaseSelector.getDirectoryTeamRoles);
    this.teamsOption$ = this.store.select(BaseSelector.getDirectoryTeamsOption);
    if (this.filterParams) {
      if (this.filterParams.team_roles) {
        const teamsRoles = this.filterParams?.team_roles.split(',');
        const updatedValueOfTeamRoles = teamsRoles.map((teamRolesId) =>
          parseInt(teamRolesId)
        );

        this.selectedTeamRoles.patchValue(updatedValueOfTeamRoles);
      }
      if (this.filterParams.team_id) {
        if (this.filterParams.team_id === 'clear') {
          this.selectedTeams.setValue(this.filterParams.team_id);
        } else {
          this.selectedTeams.setValue(parseInt(this.filterParams.team_id));
        }
      }
    }
  }

  onTabChange(event) {
    if (event.index === 0) {
      this.activeTab = 'myteams';
    } else if (event.index === 1) {
      this.activeTab = 'cohort';
    }
  }

  teamRolesSelectedValues() {
    if (this.selectedTeamRoles.value?.length) {
      const formValues = this.selectedTeamRoles.value;

      const selectedTeamRoles = formValues.join(',');

      this.filterParams = {
        ...this.filterParams,
        team_roles: selectedTeamRoles,
      };
    } else {
      this.filterParams = {
        ...this.filterParams,
        team_roles: null,
      };
    }
    this.router.navigate(
      [`/changemakers/directory/workspace/${this.workspaceId}`],
      { queryParams: this.filterParams }
    );
  }

  teamSelectedValues() {
    if (this.selectedTeams.value !== 'clear') {
      this.filterParams = {
        ...this.filterParams,
        team_id: this.selectedTeams.value,
      };
      this.router.navigate(
        [`/changemakers/directory/workspace/${this.workspaceId}`],
        { queryParams: this.filterParams }
      );
    } else {
      this.filterParams = { ...this.filterParams, team_id: null };
      this.router.navigate(
        [`/changemakers/directory/workspace/${this.workspaceId}`],
        { queryParams: this.filterParams }
      );
    }
  }

  sendMessageHandler(data) {
    this.directoryService.setDirectMessageToUser(data);
    const params = {
      newConversation: 'true',
      userId: data.id
    };
    this.groupNavigationService.setGroupPreviousSelected(params);
    this.router.navigate(
      [`/workspace/${this.workspaceId}/groups`],
      {
        queryParams: params,
      }
    );
  }

  loadMoreDirectoryTeams() {
    if (this.teamsHasMore === true) {
      this.store.dispatch(
        BaseAction.FetchDirectoryTeamsNextPageRequest({
          workspaceId: this.workspaceId,
        })
      );
    }
  }

  LoadMoreDirectoryCohort() {
    if (this.cohortHasMore === true) {
      if (Object.keys(this.filterParams).length > 0) {
        this.store.dispatch(
          BaseAction.FetchDirectoryCohortNextPageRequest({
            workspaceId: this.workspaceId,
            filter: { ...this.filterParams },
          })
        );
      } else {
        this.store.dispatch(
          BaseAction.FetchDirectoryCohortNextPageRequest({
            workspaceId: this.workspaceId,
          })
        );
      }
    }
  }
  teamRoleHandler(teamRole) {
    const teamRoles: any[] = [];
    teamRoles.push(teamRole);
    this.selectedTeamRoles.setValue(teamRoles);
    this.teamRolesSelectedValues();
    this.selectedTabIndex = 1;
  }
}

import {Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import * as baseSelector from '../../../core/base.selectors';
import * as BaseAction from '../../../core/base.actions';

@Component({
  selector: 'app-team-roles',
  templateUrl: './team-roles.component.html',
  styleUrls: ['./team-roles.component.css'],
})
export class TeamRolesComponent implements OnInit, OnDestroy {
  teamRole$: Observable<any> = new Observable();
  panelOpenState = false;
  teamRole: FormControl = new FormControl([]);
  @Input() currentTeamRole;
  selectedFiltersSubscription: Subscription = new Subscription();

  roleChangeSubscription: Subscription = this.teamRole.valueChanges.subscribe(data => {
    this.store.dispatch(
      BaseAction.UpdateCollectionPageSelectedFilterByRole({
        roles: this.teamRole.value,
      })
    );
  });

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.teamRole$ = this.store.select(baseSelector.getAllTeamRoles);
    this.store.select(baseSelector.getCurrentSelectedFilterByRoleForCollectionPage)
      .subscribe((selectedFilters) => {
        if (selectedFilters) {
          this.teamRole.setValue(selectedFilters);
        } else {
          const selectedRoles: any[] = JSON.parse(JSON.stringify(this.teamRole.value));
          selectedRoles.push(this.currentTeamRole);
          this.teamRole.setValue(selectedRoles);
        }
      }).unsubscribe();
  }

  ngOnDestroy(): void {
    this.selectedFiltersSubscription?.unsubscribe();
    this.roleChangeSubscription?.unsubscribe();
  }
}

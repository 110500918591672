import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-create-account-screen',
  templateUrl: './create-account-screen.component.html',
  styleUrls: ['./create-account-screen.component.css']
})
export class CreateAccountScreenComponent implements OnInit {
  @Input() formValues: any;
  @Input() token: any;
  @Input() organizationId: any

  constructor() {
  }

  ngOnInit(): void {
  }
}

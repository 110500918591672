import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private organizationId = new BehaviorSubject('');
  organizationId$ = this.organizationId.asObservable();
  private originationLogo = new BehaviorSubject('');
  organizationLogo$ = this.originationLogo.asObservable();

  constructor() {
  }

  setOrganizationId(data: string) {
    this.organizationId.next(data);
  }

  setOrganizationLogo(logo: string) {
    this.originationLogo.next(logo);
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as ActionCable from 'actioncable';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';
import { GroupChatService } from '../service/group-chat.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import * as BaseSelector from '../../core/base.selectors';
import * as BaseActions from '../../core/base.actions';
import { ActivatedRoute } from '@angular/router';
import { v1URL } from '../../../shared/constants/urls.constants';
import { DirectoryService } from '../../directory-page/service/directory.service';
import { group } from '@angular/animations';
import { ThreadCountService } from '../service/threads-count.service';

const groupChat = new GroupChatService();
let conversationIdGlobal = '';

@Component({
  selector: 'app-groups-screen',
  templateUrl: './groups-screen.component.html',
  styleUrls: ['./groups-screen.component.css'],
})
export class GroupsScreenComponent implements OnInit, OnDestroy {
  activeTab;
  Consumer;
  messages: Observable<any> = groupChat.chatSubject;
  ConsumerSubscription;
  directMessage$: Observable<any> = new Observable();
  Groups$: Observable<any> = new Observable();
  Teams$: Observable<any> = new Observable();
  Cohort$: Observable<any> = new Observable();
  allTeams$: Observable<any> = new Observable();
  conversationId: any = '';
  activatedParamsSubscription: Subscription = new Subscription();
  messagesSubscription: Subscription = new Subscription();
  newConversationSubscription: Subscription = new Subscription();
  markDirectConversationAsReadSubscription: Subscription = new Subscription();
  markGroupThreadsAsReadSubscription: Subscription = new Subscription();
  @Input() workspaceId: string;
  directChats: any = [];
  groupChats: any[];
  myTeams: any;
  myCohort: any;
  allTeams: any;
  queryParamsSubscription: Subscription = new Subscription();
  threadsSubscription: Subscription = new Subscription();
  currentGroupId: any;
  currentDisplayedThreads: any;
  constructor(
    private commonService: CommonService,
    public groupService: GroupChatService,
    private store: Store,
    private activatedRoutes: ActivatedRoute,
    private directoryService: DirectoryService,
    private threadsCountService: ThreadCountService
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription = this.activatedRoutes.queryParamMap.subscribe(
      (params: any) => {
        if (params.params.groupId) {
          this.currentGroupId = +params.params.groupId;
        }
      }
    );

    this.getAllMessaged();
    this.readDirectMessageAsRead();
    this.markGroupThreadConversationAsRead();
    /* chat event subscription from socket */
    this.newConversationSubscription = groupChat.newConversationChat
      .pipe(
        tap((newConversation: any) => {
          if (newConversation) {
            if (newConversation.eventType === 'new_conversation') {
              this.unSubscribeSocket();
            }
            if (newConversation.group?.id == null) {
              /* new direct message */
              this.store.dispatch(
                BaseActions.AddNewConversationNewDirectChatFetchedFromSocket({
                  payload: {
                    ...newConversation?.conversation,
                    unreadMessages: 0,
                  },
                })
              );
            } else if (newConversation.group?.id) {
              if (newConversation.eventType === 'added_to_group') {
                this.store.dispatch(
                  BaseActions.AddNewConversationNewGroupChatFetchedFromSocket({
                    payload: {
                      ...newConversation?.group,
                      unreadMessages: 0,
                    },
                  })
                );
              }
            }
          }
        })
      )
      .subscribe();
    this.directMessage$ = this.store
      .select(BaseSelector.getAllDirectMessages)
      .pipe(
        tap((directChat) => {
          this.directChats = directChat;
        })
      );
    this.Groups$ = this.store
      .select(BaseSelector.getAllGroupsConversation)
      .pipe(
        tap((groupChat) => {
          this.groupChats = groupChat;
        })
      );
    this.allTeams$ = this.store
      .select(BaseSelector.getAllTeamsConversationForWorkspaceManager)
      .pipe(
        tap((allTeams) => {
          this.allTeams = allTeams;
        })
      );
    this.Teams$ = this.store.select(BaseSelector.getMyTeamsConversation).pipe(
      tap((teams) => {
        this.myTeams = teams;
      })
    );
    this.Cohort$ = this.store.select(BaseSelector.getMyCohortConversation).pipe(
      tap((myCohort) => {
        this.myCohort = myCohort;
      })
    );
    this.addSubscriptionToWebSocket();
    this.activatedParamsSubscription =
      this.activatedRoutes.queryParamMap.subscribe((params: any) => {
        if (params.params.conversationId) {
          this.conversationId = +params.params.conversationId;
          conversationIdGlobal = this.conversationId;
        }
      });
  }

  getAllMessaged() {
    /* message subscription from sockets */
    this.messagesSubscription = groupChat.chatSubject
      .pipe(
        tap((message) => {
          if (message !== null) {
            this.store.dispatch(BaseActions.AddNewMessage(message));
          }
        })
      )
      .subscribe();
  }

  readDirectMessageAsRead() {
    this.markDirectConversationAsReadSubscription =
      groupChat.markDirectConversationAsReadSubject
        .pipe(
          tap((data) => {
            if (data) {
              let updatedDirectChat: any[] = [];
              updatedDirectChat = this.directChats.map((chats) =>
                chats.id === data.conversation_id
                  ? { ...chats, unreadMessages: data.unread_messages }
                  : { ...chats }
              );

              this.store.dispatch(
                BaseActions.MarkDirectConversationAsRead({
                  updatedDirectChats: updatedDirectChat,
                })
              );
            }
          })
        )
        .subscribe();
  }

  onToggleTab(event) {}

  addSubscriptionToWebSocket() {
    const { webSocket } = v1URL;
    this.Consumer = ActionCable.createConsumer(
      `${webSocket.url(this.commonService.getAuthenticationToken())}`
    );
    this.ConsumerSubscription = this.Consumer.subscriptions.create(
      'ConversationChannel',
      {
        connected() {
        },
        disconnected() {},
        received(data) {
          if (data) {
            if (+conversationIdGlobal === data.conversationId) {
              groupChat.addMessage(data);
            } else if (data?.kind === 'chat_event') {
              groupChat.addNewConversation(data);
            } else if (data.kind === 'unread_direct_message') {
              groupChat.markConversationAsRead(data);
            } else if (data.kind === 'unread_group_message') {
              /* mark thread as read */
              groupChat.markGroupThreadConversationAsRead(data);
            }
          }
        },
      }
    );
  }

  sendMessageThroughSocket(event) {
    this.ConsumerSubscription.send(
      {
        body: event.text,
        attachments: event.attachments,
        mentioned_ids: event.mentioned_ids,
        conversation_id: this.conversationId,
        action: 'create_chat_message',
      },
      "identifier: { channel: 'ConversationChannel' },"
    );
  }

  readMessage(event) {
    this.ConsumerSubscription.send(
      {
        body: event,
        conversation_id: this.conversationId,
        action: 'update_last_read_message',
        message_id: event,
      },
      "identifier: { channel: 'ConversationChannel' },"
    );
  }

  /* can we removed */
  unSubscribeSocket() {
    this.ConsumerSubscription?.unsubscribe();
    this.messagesSubscription?.unsubscribe();
    this.addSubscriptionToWebSocket();
    this.getAllMessaged();
  }

  markGroupThreadConversationAsRead() {
    this.markGroupThreadsAsReadSubscription =
      groupChat.markGroupThreadConversationAsReadSubject
        .pipe(
          tap((data) => {
            if (this.groupChats) {
              let updatedMyCohort: any = { ...this.myCohort };
              let updatedMyTeams: any = { ...this.myTeams };

              let updatedThreads: any = { ...this.currentDisplayedThreads };
              const updatedGroupData = this.groupChats.map((groupChat) =>
                groupChat.id == data.group_id
                  ? { ...groupChat, unreadMessages: data.group_unread_messages }
                  : { ...groupChat }
              );
              if (this.myCohort.id == data.group_id) {
                updatedMyCohort = {
                  ...this.myCohort,
                  unreadMessages: data.group_unread_messages,
                };
              }
              if (this.myTeams.id == data.group_id) {
                updatedMyTeams = {
                  ...this.myTeams,
                  unreadMessages: data.group_unread_messages,
                };
              }
              /* working on my teams  */

              const updateAllTeams = this.allTeams.map((groupChat) =>
                groupChat.id == data.group_id
                  ? { ...groupChat, unreadMessages: data.group_unread_messages }
                  : { ...groupChat }
              );

              if (this.currentGroupId == data.group_id) {
                /* const updatedAllThreads =
                  this.currentDisplayedThreads.threads.map((thread) =>
                    thread.id == data.conversation_id
                      ? { ...thread, unreadMessages: data.unread_messages }
                      : { ...thread }
                  );
                updatedThreads = {
                  ...updatedThreads,
                  threads: updatedAllThreads,
                }; */
                this.threadsCountService.setReadOrUnreadCount(data);
              }
              if (data) {
                this.store.dispatch(
                  BaseActions.MarkGroupThreadConversationAsRead({
                    groupData: updatedGroupData,
                    cohort: updatedMyCohort,
                    teams: updatedMyTeams,
                    threads: updatedThreads,
                    allTeams: updateAllTeams,
                  })
                );
              }
            }
          })
        )
        .subscribe();
  }

  ngOnDestroy(): void {
    this.ConsumerSubscription?.unsubscribe();
    this.messagesSubscription?.unsubscribe();
    this.activatedParamsSubscription?.unsubscribe();
    this.newConversationSubscription?.unsubscribe();
    this.markDirectConversationAsReadSubscription?.unsubscribe();
    this.markGroupThreadsAsReadSubscription?.unsubscribe();
   /*  this.directoryService?.setEmptyToDirectMessage(); */
  }
}

import {Routes} from '@angular/router';
import {LoginPageContainerComponent} from './unauthorized-pages/login-page/login-page-container/login-page-container.component';
import {
  UnauthorizedWrapperTypeOneComponent
} from '../shared/components/application-wrappers/unauthorized-wrapper/unauthorized-wrapper-type-one/unauthorized-wrapper-type-one.component';
import {
  UnauthorizedWrapperTypeTwoComponent
} from '../shared/components/application-wrappers/unauthorized-wrapper/unauthorized-wrapper-type-two/unauthorized-wrapper-type-two.component';
import {
  ResetPasswordContainerComponent
} from './unauthorized-pages/resetPasswordPage/reset-password-container/reset-password-container.component';
import {
  ResetPasswordRequestComponent
} from './unauthorized-pages/resetPasswordPage/components/reset-password-request/reset-password-request.component';
import {
  ResetPasswordEmailSentComponent
} from './unauthorized-pages/resetPasswordPage/components/reset-password-email-sent/reset-password-email-sent.component';
import {ResetPasswordSetComponent} from './unauthorized-pages/resetPasswordPage/components/reset-password-set/reset-password-set.component';
import {
  ResetPasswordSuccessComponent
} from './unauthorized-pages/resetPasswordPage/components/reset-password-success/reset-password-success.component';
import {
  CreateAccountContainerComponent
} from './unauthorized-pages/create-account-page/create-account-container/create-account-container.component';
import {
  WorkspaceSelectorContainerComponent
} from './unauthorized-pages/workspace-selector/workspace-selector-container/workspace-selector-container.component';
import {AuthGuardService} from '../shared/services/auth-guard/auth-guard.service';

export const AuthenticationRoutes: Routes = [
  {
    path: '',
    component: UnauthorizedWrapperTypeOneComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginPageContainerComponent,
      },
      {
        path: 'workspace',
        component: WorkspaceSelectorContainerComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'create-account',
        component: CreateAccountContainerComponent,
      },
    ],
  },
  {
    path: '',
    component: UnauthorizedWrapperTypeTwoComponent,
    children: [
      {
        path: '',
        redirectTo: 'forgot-password',
        pathMatch: 'full',
      },
      {
        path: 'forgot-password',
        component: ResetPasswordContainerComponent,
        children: [
          {
            path: '',
            component: ResetPasswordRequestComponent,
          },
          {
            path: 'email-sent',
            component: ResetPasswordEmailSentComponent,
          },
        ],
      },
      {
        path: 'reset-password',
        component: ResetPasswordContainerComponent,
        children: [
          {
            path: '',
            component: ResetPasswordSetComponent,
          },
          {
            path: 'success',
            component: ResetPasswordSuccessComponent,
          },
        ],
      },
    ],
  },
];

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-steps',
  templateUrl: './action-steps.component.html',
  styleUrls: ['./action-steps.component.css'],
})
export class ActionStepsComponent implements OnInit {
  @Input() notStartedActionStepsCount: number = 0;
  @Input() inProgressActionStepsCount: number = 0;
  @Input() inProgressActionSteps: any = [];
  @Input() notStartedActionSteps: any = [];
  @Input() milestoneName: string = '';
  @Input() phaseName: string;
  @Input() phaseId: string;
  @Input() workspaceId: string;
  @Input() milestoneId: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}
  navigateToDetails(actionSteps) {
    this.router.navigate([
      `/changemakers/action-details/workspace/${this.workspaceId}/phase/${actionSteps?.phaseId}/milestone/${actionSteps?.milestoneId}/actionstep/${actionSteps?.id}`,
    ]);
  }
}

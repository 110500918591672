import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-label-type-two',
  templateUrl: './label-type-two.component.html',
  styleUrls: ['./label-type-two.component.css']
})
export class LabelTypeTwoComponent implements OnInit {
  @Input() label: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {CommonService} from '../../../../shared/services/common/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as AuthenticationActions from '../../../core/authentication.actions';
import {CreateAccountService} from '../services/create-account.service';
import {AuthenticationService} from '../../../services/authentication.service';


@Component({
  selector: 'app-create-account-container',
  templateUrl: './create-account-container.component.html',
  styleUrls: ['./create-account-container.component.css']
})
export class CreateAccountContainerComponent implements OnInit, OnDestroy {
  createAccountSubscription: Subscription;
  formValues: any;
  token: string;

  queryParamsSubscription: Subscription
  orgDetails$: Observable<any> = new Observable()
  organizationId: string

  constructor(
    private store: Store<fromRoot.State>,
    private createAccountService: CreateAccountService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((values) => {

      const {token, formValues, organizationId} = values;
      try {
        this.formValues = JSON.parse(atob(formValues))

      } catch (e) {
        this.formValues = {}
      }
      this.token = token;
      this.organizationId = organizationId
      this.authenticationService.setOrganizationId(organizationId);

    });

    this.createAccountSubscription = this.createAccountService.createAccount.subscribe((payload) => {
      this.store.dispatch(AuthenticationActions.CreateAccountRequest(payload));
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.createAccountSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
  }
}

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as BaseSelector from '../../../../../base/core/base.selectors';

@Component({
  selector: 'app-authenticated-wrapper-container',
  templateUrl: './authenticated-wrapper-container.component.html',
  styleUrls: ['./authenticated-wrapper-container.component.css'],
})
export class AuthenticatedWrapperContainerComponent implements OnInit, OnDestroy {
  detailsConfigSubscription: Subscription;
  workSpaceId;

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.detailsConfigSubscription = this.store
      .select(BaseSelector.getConfigDetails)
      .subscribe((config) => {
        this.workSpaceId = config.selectedWorkspaceId;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.detailsConfigSubscription?.unsubscribe();
    this.cdRef.detach();
  }
}

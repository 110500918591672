import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-multi-breadcrumbs',
  templateUrl: './multi-breadcrumbs.component.html',
  styleUrls: ['./multi-breadcrumbs.component.css'],
})
export class MultiBreadcrumbsComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() color: string = '';
  @Input() data: any[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigate(path, queryParams?) {
    if (queryParams) {
      this.router.navigate([`/${path}`], { queryParams: queryParams });
    } else {
      this.router.navigate([`/${path}`]);
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';

@Injectable({ providedIn: 'root' })
export class AuthenticatedWrapperService {
  breadcrumb: BehaviorSubject<any> = new BehaviorSubject(null);
  changePasswordClicked: BehaviorSubject<boolean> = new BehaviorSubject(false);
  multiBreadCrumb: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {}

  setBreadCrumb(title: string, path: string, color?: string) {
    this.breadcrumb.next({
      title: title,
      icon: 'arrow_back_ios',
      path: path,
      color: color ? color : '',
    });
  }

  cleanBreadCrumb() {
    this.breadcrumb.next(null);
  }

  getBreadCrumb() {
    return this.breadcrumb;
  }

  changePasswordClickedHandler(value) {
    this.changePasswordClicked.next(value);
  }

  setMultipleBreadCrumbs(data: any) {
    this.multiBreadCrumb.next(data);
  }

  clearMultipleBreakCrumb() {
    this.multiBreadCrumb.next(null);
  }

  getMultipleBreadCrumbs() {
    const multiBreadCrumb$: Observable<any> =
      this.multiBreadCrumb.asObservable();
    return multiBreadCrumb$;
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-information-screen',
  templateUrl: './information-screen.component.html',
  styleUrls: ['./information-screen.component.css']
})
export class InformationScreenComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActionDetailsService} from '../../service/action-details.service';
import * as BaseSelectors from '../../../core/base.selectors';
import {Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-action-details-content',
  templateUrl: './action-details-content.component.html',
  styleUrls: ['./action-details-content.component.css'],
})
export class ActionDetailsContentComponent implements OnInit, OnDestroy {
  @Input() workspaceId: any;
  @Input() phaseId: any;
  @Input() milestoneId: any;
  actionDetails$: Observable<any> = new Observable();
  previousPage: any;
  nextPage: any;
  pageSubscription: Subscription = new Subscription();
  actionDetails;

  constructor(
    private actionDetailsService: ActionDetailsService,
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.actionDetails$ = this.store
      .select(BaseSelectors.getActionsStepContent)
      .pipe(
        tap((data) => {
          this.actionDetails = data;
          this.nextPage =
            data.nextActionStepId !== null ? data.nextActionStepId : false;
          this.previousPage =
            data.prevActionStepId !== null ? data.prevActionStepId : false;

          this.cdRef.detectChanges();
        })
      );
  }

  navigateToPreviousPage() {
    if (this.actionDetails?.prevItem?.actionStepId) {
      this.router.navigate([
        `/changemakers/action-details/workspace/${this.workspaceId}/phase/${this.actionDetails?.prevItem?.phaseId}/milestone/${this.actionDetails?.prevItem?.milestoneId}/actionstep/${this.actionDetails?.prevItem?.actionStepId}`,
      ]);
    } else if (
      !this.actionDetails?.prevItem?.actionStepId &&
      this.actionDetails?.prevItem?.milestoneId
    ) {
      this.router.navigate([
        `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.actionDetails?.prevItem?.phaseId}/milestone/${this.actionDetails?.prevItem?.milestoneId}`,
      ]);
    } else if (
      !this.actionDetails?.prevItem?.actionStepId &&
      !this.actionDetails?.prevItem?.milestoneId &&
      this.actionDetails?.prevItem?.phaseId
    ) {
      this.router.navigate(
        [`/changemakers/collection/workspace/${this.workspaceId}`],
        {
          queryParams: {
            phase: this.actionDetails?.prevItem?.phaseId,
          },
        }
      );
    }
    window.scrollTo(0, 0);
  }

  navigateToNextPage() {
    if (this.actionDetails?.nextItem?.actionStepId) {
      this.router.navigate([
        `/changemakers/action-details/workspace/${this.workspaceId}/phase/${this.actionDetails?.nextItem?.phaseId}/milestone/${this.actionDetails?.nextItem?.milestoneId}/actionstep/${this.actionDetails?.nextItem?.actionStepId}`,
      ]);
    } else if (
      !this.actionDetails?.nextItem?.actionStepId &&
      this.actionDetails?.nextItem?.milestoneId
    ) {
      this.router.navigate([
        `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.actionDetails?.nextItem?.phaseId}/milestone/${this.actionDetails?.nextItem?.milestoneId}`,
      ]);
    } else if (
      !this.actionDetails?.nextItem?.actionStepId &&
      !this.actionDetails?.nextItem?.milestoneId &&
      this.actionDetails?.nextItem?.phaseId
    ) {
      this.router.navigate(
        [`/changemakers/collection/workspace/${this.workspaceId}`],
        {
          queryParams: {
            phase: this.actionDetails?.nextItem?.phaseId,
          },
        }
      );
    }

    window.scrollTo(0, 0);
  }

  getNextButtonLabel() {
    if (
      !this.actionDetails?.nextItem?.actionStepId &&
      this.actionDetails?.nextItem?.milestoneId
    ) {
      return 'Next Milestone';
    } else if (
      !this.actionDetails?.nextItem?.actionStepId &&
      !this.actionDetails?.nextItem?.milestoneId &&
      this.actionDetails?.nextItem?.phaseId
    ) {
      return 'Next Phase';
    } else if (
      this.actionDetails?.nextItem?.actionStepId &&
      this.actionDetails?.nextItem?.milestoneId &&
      this.actionDetails?.nextItem?.phaseId
    ) {
      return 'Next Step';
    } else {
      return '';
    }
  }

  getPreviousButtonLabel() {
    if (
      !this.actionDetails?.prevItem?.actionStepId &&
      this.actionDetails?.prevItem?.milestoneId
    ) {
      return 'Previous Milestone';
    } else if (
      !this.actionDetails?.prevItem?.actionStepId &&
      !this.actionDetails?.prevItem?.milestoneId &&
      this.actionDetails?.prevItem?.phaseId
    ) {
      return 'Previous Phase';
    } else if (
      this.actionDetails?.prevItem?.actionStepId &&
      this.actionDetails?.prevItem?.milestoneId &&
      this.actionDetails?.prevItem?.phaseId
    ) {
      return 'Previous Step';
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.pageSubscription?.unsubscribe();
    this.cdRef?.detach();
  }
}

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import * as CollectionAction from '../../core/base.actions';
import * as CollectionSelector from '../../core/base.selectors';
import * as BaseSelector from '../../core/base.selectors';
import {CommonService} from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-collection-container',
  templateUrl: './collection-container.component.html',
  styleUrls: ['./collection-container.component.css'],
})
export class CollectionContainerComponent implements OnInit, OnDestroy {
  activatedRouteSubscription: Subscription = new Subscription();
  phasesMilestoneHasMore$: Observable<any> = new Observable();
  phaseInfo: any;
  teamRoles: number[] = [];
  activatedParamsSubscription: Subscription;
  workspaceId: any;
  phasesSubscription: Subscription;
  currentTeamRolesSubscription: Subscription;
  preSelectedPhase: string = '';

  phaseId: string;

  isInitialLoad = true;
  initialLoadChecker: number[] = [];

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.activatedParamsSubscription = this.activatedRoute.paramMap.subscribe(
      (params: any) => {
        if (params.params.workspaceId) {
          this.workspaceId = params.params.workspaceId;

          this.store.dispatch(
            CollectionAction.FetchPhasesRequest({
              organizationId: this.workspaceId,
            })
          );

          this.fetchAllTeamRoles(this.workspaceId);
        }
      }
    );

    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.phase) {
          this.phaseId = params.phase;
          this.fetchMilestonesData();
        } else {
          this.fetchMilestoneDataWithDefaultPageSelected();
        }
      }
    );

    this.phasesMilestoneHasMore$ = this.store.select(
      CollectionSelector.PhasesMilestoneHasMore
    );

    this.currentTeamRolesSubscription = this.store.select(BaseSelector.getCurrentSelectedFilterByRoleForCollectionPage).subscribe(data => {
      if (data) {
        this.teamRoles = data;
        this.fetchMilestonesData();
      }
    })
  }

  fetchMilestonesData() {
    if (this.isInitialLoad) {
      this.initialLoadChecker = [...this.initialLoadChecker, 1];
      if (this.initialLoadChecker.length >= 2) {
        if (this.workspaceId && this.phaseId) {

          this.store.dispatch(
            CollectionAction.FetchPhasesMilestonesRequest({
              phase: this.phaseId,
              teamRoles: this.teamRoles,
              organizationId: this.workspaceId,
            })
          );
          this.isInitialLoad = false;
        }
      }
    } else {
      this.store.dispatch(
        CollectionAction.FetchPhasesMilestonesRequest({
          phase: this.phaseId,
          teamRoles: this.teamRoles,
          organizationId: this.workspaceId,
        })
      );
    }


  }

  fetchAllTeamRoles(workspaceId) {
    const props = {organizationId: workspaceId};
    this.store.dispatch(CollectionAction.FetchAllTeamRolesRequest(props));
  }

  fetchMilestoneDataWithDefaultPageSelected() {
    this.phasesSubscription = this.store
      .select(CollectionSelector.getPreSelectedPhase)
      .pipe()
      .subscribe((phases) => {
        if (phases) {
          const params = {
            phase: phases,
          };
          this.router.navigate(
            [`/changemakers/collection/workspace/${this.workspaceId}`],
            {queryParams: params}
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription?.unsubscribe();
    this.phasesSubscription?.unsubscribe();
    this.currentTeamRolesSubscription?.unsubscribe();
    this.activatedParamsSubscription?.unsubscribe();
  }
}

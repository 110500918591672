import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import * as BaseActions from '../../../core/base.actions';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  @Input() userData: any;
  @Input() workspaceId: string = '';
  @Input() tabType:string='';
  @Output() sendDirectMessageToUserHandler: EventEmitter<any> =
    new EventEmitter();
  @Output() paginationHandler: EventEmitter<any> = new EventEmitter();
  @Output() teamRoleSelectHandler: EventEmitter<any> = new EventEmitter();
  throttle = 300;
  scrollDistance = 1;

  constructor(private matDialog: MatDialog, private store: Store) {}

  ngOnInit(): void {}

  getUserProfile(userData) {
    this.store.dispatch(
      BaseActions.FetchUserProfileForDirectoryPageRequest({
        userId: userData.id,
        workspace: this.workspaceId,
      })
    );
    const dialogBox = this.matDialog.open(UserProfileComponent, {
      width: '70%',
      height: '60%',
      panelClass: 'user-profile-pop-up',
      data: {
        workspaceId: this.workspaceId,
      },
    });
  }

  sendMessageHandler(data) {
    this.sendDirectMessageToUserHandler.emit(data);
  }

  onScrollDown(event) {
    this.paginationHandler.emit();
  }
  onSelectTeamRole(teamRole) {
    this.teamRoleSelectHandler.emit(teamRole?.id);
  }
}

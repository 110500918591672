import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MilestonePageService {
  pageSubject: BehaviorSubject<number> = new BehaviorSubject(1);
  fetchActionStepsNextPage: Subject<any> = new Subject();
  phasesCurrentPage: BehaviorSubject<number> = new BehaviorSubject(1);

  constructor() {
  }

  setCurrentPageNumber(number) {
    this.pageSubject.next(number);
  }

  fetchNextActionSteps() {
    this.fetchActionStepsNextPage.next(null);
  }

  setCurrentPage(pageNumber) {
    this.phasesCurrentPage.next(pageNumber);
  }
}

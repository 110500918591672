import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() path: string = '';
  @Input() color: string = '';

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigate() {
    this.router.navigate([`/${this.path}`]);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-milestone-name-chip',
  templateUrl: './milestone-name-chip.component.html',
  styleUrls: ['./milestone-name-chip.component.css'],
})
export class MilestoneNameChipComponent implements OnInit {
  @Input() milestoneName: string;
  @Input() workspaceId: string;
  @Input() phaseId: string;
  @Input() milestoneId: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToMilestone() {
    this.router.navigate([
      `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.phaseId}/milestone/${this.milestoneId}`,
    ]);
  }
}

/* NgRx */
import { createAction, props } from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[base] clear state';
export const ClearState = createAction(CLEAR_STATE);

const FETCH_ACTION_DETAILS_REQUEST = '[base] fetch action details request';
export const FetchActionDetailsRequest = createAction(
  FETCH_ACTION_DETAILS_REQUEST,
  props<any>()
);

const FETCH_ACTION_DETAILS_SUCCESS = '[base] fetch action details successful';
export const FetchActionDetailsSuccess = createAction(
  FETCH_ACTION_DETAILS_SUCCESS,
  props<any>()
);
const FETCH_ACTION_DETAILS_FAILURE = '[base] fetch action details failure';
export const FetchActionDetailsFailure = createAction(
  FETCH_ACTION_DETAILS_FAILURE,
  props<any>()
);

const FETCH_MILESTONE_REQUEST = '[base] fetch milestone details request';
export const FetchMilestonesRequest = createAction(
  FETCH_MILESTONE_REQUEST,
  props<{ milestoneId: string; organizationId: string; phaseId: string;teamRoles:number[] }>()
);
const FETCH_MILESTONE_SUCCESS = '[base] fetch milestone details successful';
export const FetchMilestonesSuccess = createAction(
  FETCH_MILESTONE_SUCCESS,
  props<any>()
);
const FETCH_MILESTONE_FAILURE = '[base] fetch milestone details failure';
export const FetchMilestoneFailure = createAction(
  FETCH_MILESTONE_FAILURE,
  props<any>()
);
const FETCH_MILESTONE_ACTION_STEPS_NEXT_PAGE_REQUEST =
  '[base] fetch milestone action steps next page request';
export const FetchMilestoneActionStepsNextPageRequest = createAction(
  FETCH_MILESTONE_ACTION_STEPS_NEXT_PAGE_REQUEST,
  props<{ milestoneId: string; organizationId: string; phaseId: string,teamRoles:number[] }>()
);
const FETCH_MILESTONE_ACTION_STEPS_NEXT_PAGE_SUCCESS =
  '[base] fetch milestone action steps next page success';
export const FetchMilestoneActionStepsNextPageSuccess = createAction(
  FETCH_MILESTONE_ACTION_STEPS_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_MILESTONE_ACTION_STEPS_NEXT_PAGE_FAILURE =
  '[base] fetch milestone action steps next page failure';
export const FetchMilestoneActionStepsNextPageFailure = createAction(
  FETCH_MILESTONE_ACTION_STEPS_NEXT_PAGE_FAILURE,
  props<any>()
);

const FETCH_PHASES_REQUEST = `[base] fetch phases request`;
export const FetchPhasesRequest = createAction(
  FETCH_PHASES_REQUEST,
  props<any>()
);
const FETCH_PHASES_SUCCESS = `[base] fetch phases success`;
export const FetchPhasesSuccess = createAction(
  FETCH_PHASES_SUCCESS,
  props<any>()
);
const FETCH_PHASES_FAILURE = `[base] fetch phases failure`;
export const FetchPhasesFailure = createAction(
  FETCH_PHASES_FAILURE,
  props<any>()
);
const ADD_SELECTED_PHASE = '[base] Add Selected Phase';
export const AddSelectedPhase = createAction(ADD_SELECTED_PHASE, props<any>());

const FETCH_PHASES_MILESTONES_REQUEST = '[base] Fetch Phases Milestone Request';
export const FetchPhasesMilestonesRequest = createAction(
  FETCH_PHASES_MILESTONES_REQUEST,
  props<any>()
);
const FETCH_PHASES_MILESTONES_SUCCESS = '[base] Fetch Phases Milestone Success';
export const FetchPhasesMilestonesSuccess = createAction(
  FETCH_PHASES_MILESTONES_SUCCESS,
  props<any>()
);
const FETCH_PHASES_MILESTONES_FAIL = '[base] Fetch Phases Milestone Fail';
export const FetchPhasesMilestonesFail = createAction(
  FETCH_PHASES_MILESTONES_FAIL,
  props<any>()
);

const FETCH_PHASES_ACTION_STEPS_REQUEST =
  '[base] Fetch Phases Action Steps Request';
export const FetchPhasesActionStepsRequest = createAction(
  FETCH_PHASES_ACTION_STEPS_REQUEST,
  props<any>()
);
const FETCH_PHASES_ACTION_STEPS_SUCCESS =
  '[base] Fetch Phases Action Steps Success';
export const FetchPhasesActionStepsSuccess = createAction(
  FETCH_PHASES_ACTION_STEPS_SUCCESS,
  props<any>()
);
const FETCH_PHASES_ACTION_STEPS_FAIL = '[base] Fetch Phases Action Steps Fail';
export const FetchPhasesActionStepsFail = createAction(
  FETCH_PHASES_ACTION_STEPS_FAIL,
  props<any>()
);

const FETCHED_PHASES_NEXT_PAGE_REQUEST =
  '[base] Fetch Phases next page request';
export const FetchPhasesNextPageRequest = createAction(
  FETCHED_PHASES_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCHED_PHASES_NEXT_PAGE_SUCCESS =
  '[base] Fetch Phases next page success';
export const FetchPhasesNextPageSuccess = createAction(
  FETCHED_PHASES_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCHED_PHASES_NEXT_PAGE_FAIL = '[base] Fetch Phases next page fail';
export const FetchPhasesNextPageFail = createAction(
  FETCHED_PHASES_NEXT_PAGE_FAIL,
  props<any>()
);

const FETCH_PHASES_MILESTONE_NEXT_PAGE_REQUEST =
  '[base] Fetch Phases Milestone next page request ';
export const FetchPhasesMilestoneNextPageRequest = createAction(
  FETCH_PHASES_MILESTONE_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCH_PHASES_MILESTONE_NEXT_PAGE_SUCCESS =
  '[base] Fetch Phases Milestone next page Success ';
export const FetchPhasesMilestoneNextPageSuccess = createAction(
  FETCH_PHASES_MILESTONE_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_PHASES_MILESTONE_NEXT_PAGE_FAIL =
  '[base] Fetch Phases Milestone next page Fail ';
export const FetchPhasesMilestoneNextPageFail = createAction(
  FETCH_PHASES_MILESTONE_NEXT_PAGE_FAIL,
  props<any>()
);

const FETCH_DASHBOARD_PHASES_REQUEST = '[base] fetch dashboard phases request';
export const FetchDashboardPhasesRequest = createAction(
  FETCH_DASHBOARD_PHASES_REQUEST,
  props<{ organizationId: any }>()
);
const FETCH_DASHBOARD_PHASES_SUCCESS = '[base] fetch dashboard phases success';
export const FetchDashboardPhasesSuccess = createAction(
  FETCH_DASHBOARD_PHASES_SUCCESS,
  props<any>()
);
const FETCH_DASHBOARD_PHASES_FAIL = '[base] fetch dashboard phases fail';
export const FetchDashboardPhasesFail = createAction(
  FETCH_DASHBOARD_PHASES_FAIL,
  props<any>()
);
const FETCH_DASHBOARD_PHASES_NEXT_PAGE_REQUEST =
  '[base] fetch dashboard phases next page request';
export const FetchDashboardPhasesNextPageRequest = createAction(
  FETCH_DASHBOARD_PHASES_NEXT_PAGE_REQUEST,
  props<{ organizationId: any }>()
);
const FETCH_DASHBOARD_PHASES_NEXT_PAGE_SUCCESS =
  '[base] fetch dashboard phases next page Success';
export const FetchDashboardPhasesNextPageSuccess = createAction(
  FETCH_DASHBOARD_PHASES_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_DASHBOARD_PHASES_NEXT_PAGE_FAIL =
  '[base] fetch dashboard phases next page fail';
export const FetchDashboardPhasesNextPageFail = createAction(
  FETCH_DASHBOARD_PHASES_NEXT_PAGE_FAIL,
  props<any>()
);
const FETCH_MILESTONE_ACTION_STEPS_COLLECTION_PAGE_NEXT_PAGE_REQUEST =
  '[base] Action Steps Milestone Action collection next page request';
export const FetchActionStepCollectionPageRequest = createAction(
  FETCH_MILESTONE_ACTION_STEPS_COLLECTION_PAGE_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCH_MILESTONE_ACTION_STEPS_COLLECTION_PAGE_NEXT_PAGE_SUCCESS =
  '[base] Action Steps Milestone Action collection next page Success';
export const FetchActionStepCollectionPageSuccess = createAction(
  FETCH_MILESTONE_ACTION_STEPS_COLLECTION_PAGE_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_MILESTONE_ACTION_STEPS_COLLECTION_PAGE_NEXT_PAGE_FAIL =
  '[base] Action Steps Milestone Action collection next page Fail';
export const FetchActionStepCollectionPageFail = createAction(
  FETCH_MILESTONE_ACTION_STEPS_COLLECTION_PAGE_NEXT_PAGE_FAIL,
  props<any>()
);
const FETCH_MILESTONE_DASHBOARD_PAGE_REQUEST =
  '[base] fetch Milestone dashboard page request';
export const FetchMilestoneDashboardPageRequest = createAction(
  FETCH_MILESTONE_DASHBOARD_PAGE_REQUEST,
  props<any>()
);
const FETCH_MILESTONE_DASHBOARD_PAGE_SUCCESS =
  '[base] fetch Milestone dashboard page success';
export const FetchMilestoneDashboardPageSuccess = createAction(
  FETCH_MILESTONE_DASHBOARD_PAGE_SUCCESS,
  props<any>()
);
const FETCH_MILESTONE_DASHBOARD_PAGE_FAIL =
  '[base] fetch Milestone dashboard page fail';
export const FetchMilestoneDashboardPageFail = createAction(
  FETCH_MILESTONE_DASHBOARD_PAGE_FAIL,
  props<any>()
);
const FETCH_MILESTONE_DASHBOARD_NEXT_PAGE_REQUEST =
  '[base] fetch milestone dashboard next page request';
export const FetchMilestoneDashboardNextPageRequest = createAction(
  FETCH_MILESTONE_DASHBOARD_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCH_MILESTONE_DASHBOARD_NEXT_PAGE_SUCCESS =
  '[base] fetch milestone dashboard next page success';
export const FetchMilestoneDashboardNextPageSuccess = createAction(
  FETCH_MILESTONE_DASHBOARD_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_MILESTONE_DASHBOARD_NEXT_PAGE_FAIL =
  '[base] fetch milestone dashboard next page fail';
export const FetchMilestoneDashboardNextPageFail = createAction(
  FETCH_MILESTONE_DASHBOARD_NEXT_PAGE_FAIL,
  props<any>()
);

const ADD_SELECTED_PHASE_COLLECTION_PAGE =
  '[base] set selected phase collection page';
export const SetSelectedPhaseForCollectionPage = createAction(
  ADD_SELECTED_PHASE_COLLECTION_PAGE,
  props<any>()
);
const SET_SELECTED_PHASE = `[base] set selected phase`;
export const SetSelectedPhase = createAction(SET_SELECTED_PHASE, props<any>());
const FETCH_PHASES_MILESTONE_PAGE_REQUEST =
  '[base] fetch Phases milestone page request';
export const FetchPhasesMilestonePagesRequest = createAction(
  FETCH_PHASES_MILESTONE_PAGE_REQUEST
);
const FETCH_PHASES_MILESTONE_PAGE_SUCCESS =
  '[base] fetch Phases milestone page success';
export const FetchPhasesMilestonePagesSuccess = createAction(
  FETCH_PHASES_MILESTONE_PAGE_SUCCESS,
  props<any>()
);
const FETCH_PHASES_MILESTONE_PAGE_FAIL =
  '[base] fetch Phases milestone page Fail';
export const FetchPhasesMilestonePagesFail = createAction(
  FETCH_PHASES_MILESTONE_PAGE_FAIL,
  props<any>()
);
const FETCH_PHASES_NEXT_PAGE_MILESTONE_PAGE_REQUEST =
  '[base] fetch phases next page milestone page request';
export const FetchPhasesNextPageMilestonePageRequest = createAction(
  FETCH_PHASES_NEXT_PAGE_MILESTONE_PAGE_REQUEST,
  props<any>()
);
const FETCH_PHASES_NEXT_PAGE_MILESTONE_PAGE_SUCCESS =
  '[base] fetch phases next page milestone page success';
export const FetchPhasesNextPageMilestonePageSuccess = createAction(
  FETCH_PHASES_NEXT_PAGE_MILESTONE_PAGE_SUCCESS,
  props<any>()
);
const FETCH_PHASES_NEXT_PAGE_MILESTONE_PAGE_FAIL =
  '[base] fetch phases next page milestone page fail';
export const FetchPhasesNextPageMilestonePageFail = createAction(
  FETCH_PHASES_NEXT_PAGE_MILESTONE_PAGE_FAIL,
  props<any>()
);
const FETCH_ALL_TEAM_ROLES_REQUEST =
  '[base] fetch all possible team roles request';
export const FetchAllTeamRolesRequest = createAction(
  FETCH_ALL_TEAM_ROLES_REQUEST,
  props<any>()
);
const FETCH_ALL_TEAM_ROLES_SUCCESS =
  '[base] fetch all possible team roles success';
export const FetchAllTeamRolesSuccess = createAction(
  FETCH_ALL_TEAM_ROLES_SUCCESS,
  props<any>()
);
const FETCH_ALL_TEAM_ROLES_FAIL = '[base] fetch all possible team roles fail';
export const FetchAllTeamRolesFail = createAction(
  FETCH_ALL_TEAM_ROLES_FAIL,
  props<any>()
);
const FETCH_DASHBOARD_WEEK_DATA_REQUEST =
  '[base] fetch dashboard week data request';
export const fetchDashboardWeekDataRequest = createAction(
  FETCH_DASHBOARD_WEEK_DATA_REQUEST,
  props<any>()
);
const FETCH_DASHBOARD_WEEK_DATA_SUCCESS =
  '[base] fetch dashboard week data success';
export const fetchDashboardWeekDataSuccess = createAction(
  FETCH_DASHBOARD_WEEK_DATA_SUCCESS,
  props<any>()
);
const FETCH_DASHBOARD_WEEK_DATA_FAIL = '[base] fetch dashboard week data fail';
export const fetchDashboardWeekDataFail = createAction(
  FETCH_DASHBOARD_WEEK_DATA_FAIL,
  props<any>()
);

/****** FETCH SAS TOKEN ******/
const FETCH_SAS_TOKEN_REQUEST = '[base] fetch SAS token request';
export const FetchSasTokenRequest = createAction(
  FETCH_SAS_TOKEN_REQUEST,
  props<any>()
);

const FETCH_SAS_TOKEN_SUCCESS = '[base] fetch SAS token success';
export const FetchSasTokenSuccess = createAction(
  FETCH_SAS_TOKEN_SUCCESS,
  props<any>()
);

const FETCH_SAS_TOKEN_FAILURE = '[base] fetch SAS token failure';
export const FetchSasTokenFailure = createAction(
  FETCH_SAS_TOKEN_FAILURE,
  props<any>()
);

/****** SUBMIT PARTICIPANT MILESTONE DELIVERABLES ******/
const SUBMIT_PARTICIPANT_MILESTONE_DELIVERABLES_REQUEST =
  '[base] submit participant milestone deliverable request';
export const SubmitParticipantMilestoneDeliverableRequest = createAction(
  SUBMIT_PARTICIPANT_MILESTONE_DELIVERABLES_REQUEST,
  props<any>()
);

const SUBMIT_PARTICIPANT_MILESTONE_DELIVERABLES_SUCCESS =
  '[base] submit participant milestone deliverable success';
export const SubmitParticipantMilestoneDeliverableSuccess = createAction(
  SUBMIT_PARTICIPANT_MILESTONE_DELIVERABLES_SUCCESS,
  props<any>()
);

const SUBMIT_PARTICIPANT_MILESTONE_DELIVERABLES_FAILURE =
  '[base] submit participant milestone deliverable failure';
export const SubmitParticipantMilestoneDeliverableFailure = createAction(
  SUBMIT_PARTICIPANT_MILESTONE_DELIVERABLES_FAILURE,
  props<any>()
);
const SET_SELECTED_WORK_SPACE_ID = '[base] set selected workspaceID';
export const SetSelectedWorkSpaceId = createAction(
  SET_SELECTED_WORK_SPACE_ID,
  props<{ SelectedWorkspaceId: any }>()
);

const FETCH_DIRECTORY_TEAMS_REQUEST = '[base] fetch directory teams request';
export const FetchDirectoryTeamsRequest = createAction(
  FETCH_DIRECTORY_TEAMS_REQUEST,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_SUCCESS = '[base] fetch directory teams success';
export const FetchDirectoryTeamsSuccess = createAction(
  FETCH_DIRECTORY_TEAMS_SUCCESS,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_FAIL = '[base] fetch directory teams fail';
export const FetchDirectoryTeamsFail = createAction(
  FETCH_DIRECTORY_TEAMS_FAIL,
  props<any>()
);

const FETCH_DIRECTORY_COHORT_REQUEST = '[base] fetch directory cohort request';
export const FetchDirectoryCohortRequest = createAction(
  FETCH_DIRECTORY_COHORT_REQUEST,
  props<any>()
);
const FETCH_DIRECTORY_COHORT_SUCCESS = '[base] fetch directory cohort success';
export const FetchDirectoryCohortSuccess = createAction(
  FETCH_DIRECTORY_COHORT_SUCCESS,
  props<any>()
);
const FETCH_DIRECTORY_COHORT_FAIL = '[base] fetch directory cohort fail';
export const FetchDirectoryCohortFail = createAction(
  FETCH_DIRECTORY_COHORT_FAIL,
  props<any>()
);

const FETCH_DIRECTORY_TEAMS_NEXT_PAGE_REQUEST =
  '[base] fetch directory teams next page request';

export const FetchDirectoryTeamsNextPageRequest = createAction(
  FETCH_DIRECTORY_TEAMS_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_NEXT_PAGE_SUCCESS =
  '[base] fetch directory teams next page success';

export const FetchDirectoryTeamsNextPageSuccess = createAction(
  FETCH_DIRECTORY_TEAMS_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_NEXT_PAGE_FAIL =
  '[base] fetch directory teams next page fail';

export const FetchDirectoryTeamsNextPageFail = createAction(
  FETCH_DIRECTORY_TEAMS_NEXT_PAGE_FAIL,
  props<any>()
);

const FETCH_DIRECTORY_COHORT_NEXT_PAGE_REQUEST =
  '[base] fetch directory cohort next page request';
export const FetchDirectoryCohortNextPageRequest = createAction(
  FETCH_DIRECTORY_COHORT_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCH_DIRECTORY_COHORT_NEXT_PAGE_SUCCESS =
  '[base] fetch directory cohort next page success';
export const FetchDirectoryCohortNextPageSuccess = createAction(
  FETCH_DIRECTORY_COHORT_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_DIRECTORY_COHORT_NEXT_PAGE_FAIL =
  '[base] fetch directory cohort next page fail';
export const FetchDirectoryCohortNextPageFail = createAction(
  FETCH_DIRECTORY_COHORT_NEXT_PAGE_FAIL,
  props<any>()
);

const FETCH_DIRECTORY_TEAMS_ROLE_REQUEST =
  '[base] fetch directory teams role request';
export const FetchDirectoryTeamRoleRequest = createAction(
  FETCH_DIRECTORY_TEAMS_ROLE_REQUEST,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_ROLE_SUCCESS =
  '[base] fetch directory teams role success';
export const FetchDirectoryTeamRoleSuccess = createAction(
  FETCH_DIRECTORY_TEAMS_ROLE_SUCCESS,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_ROLE_FAIL =
  '[base] fetch directory teams role fail';
export const FetchDirectoryTeamRoleFail = createAction(
  FETCH_DIRECTORY_TEAMS_ROLE_FAIL,
  props<any>()
);

const FETCH_DIRECTORY_TEAMS_OPTION_REQUEST =
  '[base] fetch directory teams option request';
export const FetchDirectoryTeamsOptionRequest = createAction(
  FETCH_DIRECTORY_TEAMS_OPTION_REQUEST,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_OPTION_SUCCESS =
  '[base] fetch directory teams option success';
export const FetchDirectoryTeamsOptionSuccess = createAction(
  FETCH_DIRECTORY_TEAMS_OPTION_SUCCESS,
  props<any>()
);
const FETCH_DIRECTORY_TEAMS_OPTION_FAIL =
  '[base] fetch directory teams option fail';
export const FetchDirectoryTeamsOptionFail = createAction(
  FETCH_DIRECTORY_TEAMS_OPTION_FAIL,
  props<any>()
);

/* groups page actions */
const FETCH_ALL_DIRECT_CONVERSATION_REQUEST =
  '[base] fetch all direct conversation request';
export const fetchAllDirectConversationRequest = createAction(
  FETCH_ALL_DIRECT_CONVERSATION_REQUEST
);
const FETCH_ALL_DIRECT_CONVERSATION_SUCCESS =
  '[base] fetch all direct conversation success';
export const fetchAllDirectConversationSuccess = createAction(
  FETCH_ALL_DIRECT_CONVERSATION_SUCCESS,
  props<any>()
);
const FETCH_ALL_DIRECT_CONVERSATION_FAIL =
  '[base] fetch all direct conversation fail';
export const fetchAllDirectConversationFail = createAction(
  FETCH_ALL_DIRECT_CONVERSATION_FAIL,
  props<any>()
);
const FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_REQUEST =
  '[base] fetch all message for conversationId request';
export const fetchAllMessageForConversationIdRequest = createAction(
  FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_REQUEST,
  props<any>()
);
const FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_SUCCESS =
  '[base] fetch all message for conversationId Success';
export const fetchAllMessageForConversationIdSuccess = createAction(
  FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_SUCCESS,
  props<any>()
);
const FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_FAIL =
  '[base] fetch all message for conversationId fail';
export const fetchAllMessageForConversationIdFail = createAction(
  FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_FAIL,
  props<any>()
);

/* needs to check and changed */
const ADD_NEW_MESSAGE = '[base] add new message';
export const AddNewMessage = createAction(ADD_NEW_MESSAGE, props<any>());

const FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_REQUEST_NEXT_PAGE =
  '[base] fetch all message for conversationId request next page';
export const fetchAllMessageForConversationIdRequestNextPage = createAction(
  FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_REQUEST_NEXT_PAGE,
  props<any>()
);
const FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_SUCCESS_NEXT_PAGE =
  '[base] fetch all message for conversationId Success next page';
export const fetchAllMessageForConversationIdSuccessNextPage = createAction(
  FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_SUCCESS_NEXT_PAGE,
  props<any>()
);
const FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_FAIL_NEXT_PAGE =
  '[base] fetch all message for conversationId fail next page';
export const fetchAllMessageForConversationIdFailNextPage = createAction(
  FETCH_ALL_MESSAGE_FOR_CONVERSATION_ID_FAIL_NEXT_PAGE,
  props<any>()
);

const FETCH_ALL_GROUPS_FOR_MY_GROUP_PAGE_REQUEST =
  '[base] fetch all groups for my group page request';
export const FetchAllGroupsForMyGroupsRequest = createAction(
  FETCH_ALL_GROUPS_FOR_MY_GROUP_PAGE_REQUEST,props<any>()
);
const FETCH_ALL_GROUPS_FOR_MY_GROUP_PAGE_SUCCESS =
  '[base] fetch all groups for my group page success';
export const FetchAllGroupsForMyGroupsSuccess = createAction(
  FETCH_ALL_GROUPS_FOR_MY_GROUP_PAGE_SUCCESS,
  props<any>()
);
const FETCH_ALL_GROUPS_FOR_MY_GROUP_PAGE_FAIL =
  '[base] fetch all groups for my group page fail';
export const FetchAllGroupsForMyGroupsFail = createAction(
  FETCH_ALL_GROUPS_FOR_MY_GROUP_PAGE_FAIL,
  props<any>()
);

const FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_REQUEST =
  '[base] fetch all threads for a group conversation request';
export const FetchAllThreadsForGroupsConversationRequest = createAction(
  FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_REQUEST,
  props<any>()
);
const FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_SUCCESS =
  '[base] fetch all threads for a group conversation success';
export const FetchAllThreadsForGroupsConversationSuccess = createAction(
  FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_SUCCESS,
  props<any>()
);
const FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_NEXT_PAGE_SUCCESS =
  '[base] fetch all threads for a group conversation next page success';
export const FetchAllThreadsForGroupsConversationNextPageSuccess = createAction(
  FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_NEXT_PAGE_SUCCESS,
  props<any>()
);
const FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_NEXT_PAGE_REQUEST =
  '[base] fetch all threads for a group conversation next page request';
export const FetchAllThreadsForGroupsConversationNextPageRequest = createAction(
  FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_NEXT_PAGE_REQUEST,
  props<any>()
);
const FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_NEXT_PAGE_FAIL =
  '[base] fetch all threads for a group conversation next page fail';
export const FetchAllThreadsForGroupsConversationNextPageFAIL = createAction(
  FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_NEXT_PAGE_FAIL,
  props<any>()
);
const FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_FAIL =
  '[base] fetch all threads for a group conversation fail';
export const FetchAllThreadsForGroupsConversationFail = createAction(
  FETCH_ALL_THREADS_FOR_GROUP_CONVERSATION_FAIL,
  props<any>()
);

const CREATE_NEW_CHAT_THREAD_FOR_GROUP_REQUEST =
  '[base] create new chat thread for group request';
export const CreateNewChatThreadForGroupRequest = createAction(
  CREATE_NEW_CHAT_THREAD_FOR_GROUP_REQUEST,
  props<any>()
);
const CREATE_NEW_CHAT_THREAD_FOR_GROUP_SUCCESS =
  '[base] create new chat thread for group success';
export const CreateNewChatThreadForGroupSuccess = createAction(
  CREATE_NEW_CHAT_THREAD_FOR_GROUP_SUCCESS,
  props<any>()
);
const CREATE_NEW_CHAT_THREAD_FOR_GROUP_FAIL =
  '[base] create new chat thread for group fail';
export const CreateNewChatThreadForGroupFail = createAction(
  CREATE_NEW_CHAT_THREAD_FOR_GROUP_FAIL,
  props<any>()
);

const SEARCH_USER_REQUEST = '[base] search user request';
export const SearchUserRequest = createAction(
  SEARCH_USER_REQUEST,
  props<any>()
);
const SEARCH_USER_SUCCESS = '[base] search user success';
export const SearchUserSuccess = createAction(
  SEARCH_USER_SUCCESS,
  props<any>()
);
const SEARCH_USER_FAIL = '[base] search user fail';
export const SearchUserFail = createAction(SEARCH_USER_FAIL, props<any>());

const START_NEW_DIRECT_MESSAGE_CONVERSATION_REQUEST =
  '[base] start new direct message conversation request';
export const StartNewDirectMessageConversationRequest = createAction(
  START_NEW_DIRECT_MESSAGE_CONVERSATION_REQUEST,
  props<any>()
);
const START_NEW_DIRECT_MESSAGE_CONVERSATION_SUCCESS =
  '[base] start new direct message conversation success';
export const StartNewDirectMessageConversationSuccess = createAction(
  START_NEW_DIRECT_MESSAGE_CONVERSATION_SUCCESS,
  props<any>()
);
const START_NEW_DIRECT_MESSAGE_CONVERSATION_FAIL =
  '[base] start new direct message conversation fail';
export const StartNewDirectMessageConversationFail = createAction(
  START_NEW_DIRECT_MESSAGE_CONVERSATION_FAIL,
  props<any>()
);

const ADD_NEW_CONVERSATION_NEW_DIRECT_CHAT_FETCHED_FROM_SOCKET =
  '[base] add new conversation new direct chat fetched from socket';
export const AddNewConversationNewDirectChatFetchedFromSocket = createAction(
  ADD_NEW_CONVERSATION_NEW_DIRECT_CHAT_FETCHED_FROM_SOCKET,
  props<any>()
);
const ADD_NEW_CONVERSATION_NEW_GROUP_CHAT_FETCHED_FROM_SOCKET =
  '[base] add new conversation new group chat fetched from socket';
export const AddNewConversationNewGroupChatFetchedFromSocket = createAction(
  ADD_NEW_CONVERSATION_NEW_GROUP_CHAT_FETCHED_FROM_SOCKET,
  props<any>()
);

const MARK_DIRECT_CONVERSATION_AS_READ =
  '[base] mark direct conversation as read';
export const MarkDirectConversationAsRead = createAction(
  MARK_DIRECT_CONVERSATION_AS_READ,
  props<any>()
);
const MARK_GROUP_THREAD_CONVERSATION_AS_READ =
  '[base] mark group thread conversation as read';
export const MarkGroupThreadConversationAsRead = createAction(
  MARK_GROUP_THREAD_CONVERSATION_AS_READ,
  props<any>()
);
const MARK_DIRECT_CONVERSATION_AS_UNREAD =
  '[base] mark direct conversation as unread';
export const MarkDirectConversationAsUnread = createAction(
  MARK_DIRECT_CONVERSATION_AS_UNREAD,
  props<any>()
);

const FETCH_USER_PROFILE_FOR_DIRECTORY_PAGE_REQUEST =
  '[base] fetch user profile for directory page request';
export const FetchUserProfileForDirectoryPageRequest = createAction(
  FETCH_USER_PROFILE_FOR_DIRECTORY_PAGE_REQUEST,
  props<any>()
);
const FETCH_USER_PROFILE_FOR_DIRECTORY_PAGE_SUCCESS =
  '[base] fetch user profile for directory page success';
export const FetchUserProfileForDirectoryPageSuccess = createAction(
  FETCH_USER_PROFILE_FOR_DIRECTORY_PAGE_SUCCESS,
  props<any>()
);
const FETCH_USER_PROFILE_FOR_DIRECTORY_PAGE_FAIL =
  '[base] fetch user profile for directory page fail';
export const FetchUserProfileForDirectoryPageFail = createAction(
  FETCH_USER_PROFILE_FOR_DIRECTORY_PAGE_FAIL,
  props<any>()
);

const CLEAR_USER_PROFILE_FOR_DIRECTORY_PAGE =
  '[base] clear user profile for directory page';
export const ClearUserProfileForDirectoryPage = createAction(
  CLEAR_USER_PROFILE_FOR_DIRECTORY_PAGE
);

/****** FETCH CHAT SAS TOKEN ******/
const FETCH_CHAT_SAS_TOKEN_REQUEST = '[base] fetch chat SAS token request';
export const FetchChatSasTokenRequest = createAction(
  FETCH_CHAT_SAS_TOKEN_REQUEST
);

const FETCH_CHAT_SAS_TOKEN_SUCCESS = '[base] fetch chat SAS token success';
export const FetchChatSasTokenSuccess = createAction(
  FETCH_CHAT_SAS_TOKEN_SUCCESS,
  props<any>()
);

const FETCH_CHAT_SAS_TOKEN_FAILURE = '[base] fetch chat SAS token failure';
export const FetchChatSasTokenFailure = createAction(
  FETCH_CHAT_SAS_TOKEN_FAILURE,
  props<any>()
);

const FETCH_MILESTONE_RELATED_CONVERSATION_REQUEST =
  '[base] fetch milestone related conversation request';
export const FetchMilestoneRelatedConversationRequest = createAction(
  FETCH_MILESTONE_RELATED_CONVERSATION_REQUEST,props<any>()
);
const FETCH_MILESTONE_RELATED_CONVERSATION_SUCCESS =
  '[base] fetch milestone related conversation success';
export const FetchMilestoneRelatedConversationSuccess = createAction(
  FETCH_MILESTONE_RELATED_CONVERSATION_SUCCESS,
  props<any>()
);
const FETCH_MILESTONE_RELATED_CONVERSATION_FAIL =
  '[base] fetch milestone related conversation fail';
export const FetchMilestoneRelatedConversationFail = createAction(
  FETCH_MILESTONE_RELATED_CONVERSATION_FAIL,
  props<any>()
);

const FETCH_ALL_THREADS_CONVERSATION_FOR_MILESTONE_REQUEST = `[base] fetch all thread conversation for milestone request`;
export const FetchAllThreadsConversationForMilestoneRequest = createAction(
  FETCH_ALL_THREADS_CONVERSATION_FOR_MILESTONE_REQUEST,
  props<any>()
);
const FETCH_ALL_THREADS_CONVERSATION_FOR_MILESTONE_SUCCESS = `[base] fetch all thread conversation for milestone success`;
export const FetchAllThreadsConversationForMilestoneSuccess = createAction(
  FETCH_ALL_THREADS_CONVERSATION_FOR_MILESTONE_SUCCESS,
  props<any>()
);
const FETCH_ALL_THREADS_CONVERSATION_FOR_MILESTONE_FAIL = `[base] fetch all thread conversation for milestone fail`;
export const FetchAllThreadsConversationForMilestoneFail = createAction(
  FETCH_ALL_THREADS_CONVERSATION_FOR_MILESTONE_FAIL,
  props<any>()
);

const FETCH_ALL_CONVERSATION_MESSAGE_FOR_MILESTONE_REQUEST =
  '[base] fetch all conversation message for milestone request';
export const FetchAllConversationMessageForMilestoneRequest = createAction(
  FETCH_ALL_CONVERSATION_MESSAGE_FOR_MILESTONE_REQUEST,
  props<any>()
);
const FETCH_ALL_CONVERSATION_MESSAGE_FOR_MILESTONE_SUCCESS =
  '[base] fetch all conversation message for milestone success';
export const FetchAllConversationMessageForMilestoneSuccess = createAction(
  FETCH_ALL_CONVERSATION_MESSAGE_FOR_MILESTONE_SUCCESS,
  props<any>()
);
const FETCH_ALL_CONVERSATION_MESSAGE_FOR_MILESTONE_FAIL =
  '[base] fetch all conversation message for milestone fail';
export const FetchAllConversationMessageForMilestoneFail = createAction(
  FETCH_ALL_CONVERSATION_MESSAGE_FOR_MILESTONE_FAIL,
  props<any>()
);

const CREATE_NEW_CONVERSATION_CHANNEL_FOR_MILESTONE_REQUEST =
  '[base] create new conversation channel for the milestone request';
export const CreateNewConversationChannelForMilestoneRequest = createAction(
  CREATE_NEW_CONVERSATION_CHANNEL_FOR_MILESTONE_REQUEST,
  props<any>()
);
const CREATE_NEW_CONVERSATION_CHANNEL_FOR_MILESTONE_SUCCESS =
  '[base] create new conversation channel for the milestone success';
export const CreateNewConversationChannelForMilestoneSuccess = createAction(
  CREATE_NEW_CONVERSATION_CHANNEL_FOR_MILESTONE_SUCCESS,
  props<any>()
);
const CREATE_NEW_CONVERSATION_CHANNEL_FOR_MILESTONE_FAIL =
  '[base] create new conversation channel for the milestone fail';
export const CreateNewConversationChannelForMilestoneFail = createAction(
  CREATE_NEW_CONVERSATION_CHANNEL_FOR_MILESTONE_FAIL,
  props<any>()
);

const FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_REQUEST =
  '[base] fetch all conversation related to the threads milestone request';
export const FetchAllConversationRelatedToThreadsMilestoneRequest =
  createAction(
    FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_REQUEST,
    props<any>()
  );
const FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_SUCCESS =
  '[base] fetch all conversation related to the threads milestone success';
export const FetchAllConversationRelatedToThreadsMilestoneSuccess =
  createAction(
    FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_SUCCESS,
    props<any>()
  );
const FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_FAIL =
  '[base] fetch all conversation related to the threads milestone fail';
export const FetchAllConversationRelatedToThreadsMilestoneFail = createAction(
  FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_FAIL,
  props<any>()
);
const ADD_MESSAGE_TO_CONVERSATION_TO_RELATED_TO_MILESTONE =
  '[base] add message to conversation to related to milestone';
export const AddMessageToConversationToRelatedToMilestone = createAction(
  ADD_MESSAGE_TO_CONVERSATION_TO_RELATED_TO_MILESTONE,
  props<any>()
);

const FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_NEXT_PAGE_REQUEST =
  '[base] fetch all conversation related to the threads milestone next page request';
export const FetchAllConversationRelatedToThreadsMilestoneNextPageRequest =
  createAction(
    FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_NEXT_PAGE_REQUEST,
    props<any>()
  );
const FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_NEXT_PAGE_SUCCESS =
  '[base] fetch all conversation related to the threads milestone next page success';
export const FetchAllConversationRelatedToThreadsMilestoneNextPageSuccess =
  createAction(
    FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_NEXT_PAGE_SUCCESS,
    props<any>()
  );
const FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_NEXT_PAGE_FAIL =
  '[base] fetch all conversation related to the threads milestone next page fail';
export const FetchAllConversationRelatedToThreadsMilestoneNextPageFail =
  createAction(
    FETCH_ALL_CONVERSATION_RELATED_TO_THREADS_MILESTONE_NEXT_PAGE_FAIL,
    props<any>()
  );

const MARK_READ_TO_SIDE_NAC_ITEMS_IS_MILESTONE_CONVERSATION =
  '[base] mark read to side nav items is milestone conversation';
export const MarkReadToSideNavItemsInMilestoneConversation = createAction(
  MARK_READ_TO_SIDE_NAC_ITEMS_IS_MILESTONE_CONVERSATION,
  props<any>()
);
const MARK_SIDE_NAV_ITEMS_AS_UNREAD_FOR_NEW_MESSAGE_FOR_MILESTONE_CONVERSATION =
  '[base] mark side nac items as unread for new message for milestone conversation';
export const MarkSideItemAsUnreadForNewMessageMilestoneConversation =
  createAction(
    MARK_SIDE_NAV_ITEMS_AS_UNREAD_FOR_NEW_MESSAGE_FOR_MILESTONE_CONVERSATION,
    props<any>()
  );
const SEARCH_USER_FORM_MENTION_REQUEST =
  '[base] search user for mention request';
export const SearchUserForMentionRequest = createAction(
  SEARCH_USER_FORM_MENTION_REQUEST,
  props<any>()
);
const SEARCH_USER_FORM_MENTION_SUCCESS =
  '[base] search user for mention success';
export const SearchUserForMentionSuccess = createAction(
  SEARCH_USER_FORM_MENTION_SUCCESS,
  props<any>()
);
const SEARCH_USER_FORM_MENTION_FAIL = '[base] search user for mention fail';
export const SearchUserForMentionFail = createAction(
  SEARCH_USER_FORM_MENTION_FAIL,
  props<any>()
);

const FETCH_USER_PROFILE_REQUEST = '[base] fetch user profile request';
export const FetchUserProfileRequest = createAction(FETCH_USER_PROFILE_REQUEST);
const FETCH_USER_PROFILE_SUCCESS = '[base] fetch user profile success';
export const FetchUserProfileSuccess = createAction(
  FETCH_USER_PROFILE_SUCCESS,
  props<any>()
);
const FETCH_USER_PROFILE_FAIL = '[base] fetch user profile fail';
export const FetchUserProfileFail = createAction(
  FETCH_USER_PROFILE_FAIL,
  props<any>()
);

const FETCH_UNREAD_COUNT_GROUPS_ICONS_REQUEST =
  '[base] fetch unread count groups icons request';
export const FetchUnreadCountGroupsIconsRequest = createAction(
  FETCH_UNREAD_COUNT_GROUPS_ICONS_REQUEST
);
const FETCH_UNREAD_COUNT_GROUPS_ICONS_SUCCESS =
  '[base] fetch unread count groups icons success';
export const FetchUnreadCountGroupsIconsSuccess = createAction(
  FETCH_UNREAD_COUNT_GROUPS_ICONS_SUCCESS,
  props<any>()
);
const FETCH_UNREAD_COUNT_GROUPS_ICONS_FAIL =
  '[base] fetch unread count groups icons fail';
export const FetchUnreadCountGroupsIconsFail = createAction(
  FETCH_UNREAD_COUNT_GROUPS_ICONS_FAIL,
  props<any>()
);
const UPDATED_COLLECTION_PAGE_SELECTED_FILTER_BY_ROLE =
  '[base] updated collection page selected filter by role';
export const UpdateCollectionPageSelectedFilterByRole = createAction(
  UPDATED_COLLECTION_PAGE_SELECTED_FILTER_BY_ROLE,
  props<any>()
);

const RESET_MILESTONE_PHASES_TO_DEFAULT_STATE='[base] reset phases to default state'
export const ResetPhasesMilestoneToDefaultState=createAction(RESET_MILESTONE_PHASES_TO_DEFAULT_STATE)

const FETCH_FILES_ACCORDING_TO_MILESTONE_REQUEST =
  '[base] fetch files according to milestone request';
export const FetchFileAccordingToMilestoneRequest = createAction(
  FETCH_FILES_ACCORDING_TO_MILESTONE_REQUEST,
  props<any>()
);

const FETCH_FILES_ACCORDING_TO_MILESTONE_SUCCESS =
  '[base] fetch files according to milestone success';
export const FetchFileAccordingToMilestoneSuccess = createAction(
  FETCH_FILES_ACCORDING_TO_MILESTONE_SUCCESS,
  props<any>()
);

const FETCH_FILES_ACCORDING_TO_MILESTONE_FAIL =
  '[base] fetch files according to milestone fail';
export const FetchFileAccordingToMilestoneFail = createAction(
  FETCH_FILES_ACCORDING_TO_MILESTONE_FAIL,
  props<any>()
);

const CLEAR_THREADS =
  '[base] clear threads';
export const ClearThreads = createAction(
  CLEAR_THREADS,
  props<any>()
);


const RESET_MILESTONES_FILE_LIST='[base] reset milestone file list to initial state'
export const ResetMilestoneFileList=createAction(RESET_MILESTONES_FILE_LIST)

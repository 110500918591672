import {Routes} from '@angular/router';
import {BaseComponentsContainerComponent} from './base-components-page/container/container.component';
import {InputPanelComponent} from './base-components-page/components/input-panel/input-panel.component';
import {DatePickerPanelComponent} from './base-components-page/components/date-picker-panel/date-picker-panel.component';
import {DateTimePickerPanelComponent} from './base-components-page/components/date-time-picker-panel/date-time-picker-panel.component';
import {ButtonPanelComponent} from './base-components-page/components/button-panel/button-panel.component';
import {DropdownPanelComponent} from './base-components-page/components/dropdown-panel/dropdown-panel.component';
import {LoadingScreenPanelComponent} from './base-components-page/components/loading-screen-panel/loading-screen-panel.component';
import {DialogboxPanelComponent} from './base-components-page/components/dialogbox-panel/dialogbox-panel.component';

export const SamplesRoutes: Routes = [
  {
    path: '',
    redirectTo: 'base-components',
    pathMatch: 'full'
  },
  {
    path: 'base-components',
    component: BaseComponentsContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'input',
        pathMatch: 'full'
      },
      {
        path: 'input',
        component: InputPanelComponent
      },
      {
        path: 'button',
        component: ButtonPanelComponent
      },
      {
        path: 'dropdown',
        component: DropdownPanelComponent
      },
      {
        path: 'date-picker',
        component: DatePickerPanelComponent
      },
      {
        path: 'date-time-picker',
        component: DateTimePickerPanelComponent
      },
      {
        path: 'loading-screen',
        component: LoadingScreenPanelComponent
      },
      {
        path: 'dialog-box-screen',
        component: DialogboxPanelComponent
      }
    ]
  }
];

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as baseAction from '../../../core/base.actions';
import * as baseSelector from '../../../core/base.selectors';

@Component({
  selector: 'app-milestone-card',
  templateUrl: './milestone-card.component.html',
  styleUrls: ['./milestone-card.component.css'],
})
export class MilestoneCardComponent implements OnInit {
  milestoneSubscription: Subscription = new Subscription();
  shownMilestoneIndex: number = 0;
  milestoneLength: number = 0;
  hasMoreMilestone: boolean = true;
  shownMilestone: any;
  allMilestone: any[] = [];
  showImage = true;
  @Input() workspace: any;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.showImage = true;
    this.shownMilestoneIndex = 0;
    this.milestoneSubscription = this.store
      .select(baseSelector.getDashboardMilestone)
      .pipe(
        tap((milestone) => {
          this.milestoneLength = milestone.milestones.length;
          this.hasMoreMilestone = milestone.hasMore;
          this.shownMilestone = milestone.milestones[this.shownMilestoneIndex];
          this.showImage = true;
          this.allMilestone = milestone.milestones;
        })
      )
      .subscribe();
  }

  perviousMilestone() {
    if (this.shownMilestoneIndex > 0) {
      this.shownMilestoneIndex = this.shownMilestoneIndex - 1;
      this.shownMilestone = this.allMilestone[this.shownMilestoneIndex];
      this.showImage = true;
    }
  }

  nextMilestone() {
    if (this.shownMilestoneIndex < this.milestoneLength - 1) {
      this.shownMilestoneIndex = this.shownMilestoneIndex + 1;
      this.shownMilestone = this.allMilestone[this.shownMilestoneIndex];
      this.showImage = true;
    } else {
      if (this.hasMoreMilestone === true) {
        this.store.dispatch(
          baseAction.FetchMilestoneDashboardNextPageRequest({
            workspaceId: this.workspace,
          })
        );
      }
    }
  }

  navigateToMilestone(milestone) {
    if (milestone?.phase?.id) {
      const PhaseId = milestone?.phase?.id;
      this.store.dispatch(
        baseAction.SetSelectedPhase({ phaseId: milestone.phase.id })
      );
      this.router.navigate([
        '/changemakers/milestone-details/workspace/' +
          this.workspace +
          '/phase/' +
          PhaseId +
          '/milestone/' +
          milestone.id,
      ]);
    }
  }

  onErrorImg(event) {
    this.showImage = false;
  }
}

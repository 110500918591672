import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as BaseSelector from '../../../core/base.selectors';
import {Router} from '@angular/router';
import {MilestonePageService} from '../../service/milestonePage.service';

@Component({
  selector: 'app-milestone-action-steps',
  templateUrl: './milestone-action-steps.component.html',
  styleUrls: ['./milestone-action-steps.component.css'],
})
export class MilestoneActionStepsComponent implements OnInit {
  active: boolean = false;
  actionStep$: Observable<any> = new Observable();
  throttle = 300;
  scrollDistance = 1;
  @Input() workspaceId: string;
  @Input() phaseId: string;
  @Input() milestoneId;

  constructor(
    private store: Store,
    private router: Router,
    private milestonePageService: MilestonePageService
  ) {
  }

  ngOnInit(): void {
    this.actionStep$ = this.store.select(BaseSelector.getActionsSteps);
  }

  navigateActionsStep(id: string) {
    /* needs to be changed */
    this.router.navigate([
      `/changemakers/action-details/workspace/${this.workspaceId}/phase/${this.phaseId}/milestone/${this.milestoneId}/actionstep/${id}`,
    ]);
  }

  onScrollDown() {
    /* check */
    this.milestonePageService.fetchNextActionSteps();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';

// Modules
import { MaterialModule } from './material-module';

// Components
import { DatePickerComponent } from './components/form-fields/date-picker/date-picker.component';
import { DateTimePickerComponent } from './components/form-fields/date-time-picker/date-time-picker.component';
import { IconComponent } from './components/icon/icon.component';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TextInputComponent } from './components/form-fields/input/text-input/text-input.component';
import { NumberInputComponent } from './components/form-fields/input/number-input/number-input.component';
import { PasswordInputComponent } from './components/form-fields/input/password-input/password-input.component';
import { PhoneNumberInputComponent } from './components/form-fields/input/phone-number-input/phone-number-input.component';
import { UnauthorizedWrapperTypeOneComponent } from './components/application-wrappers/unauthorized-wrapper/unauthorized-wrapper-type-one/unauthorized-wrapper-type-one.component';
import { UnauthorizedWrapperTypeTwoComponent } from './components/application-wrappers/unauthorized-wrapper/unauthorized-wrapper-type-two/unauthorized-wrapper-type-two.component';

// Pipes
import { DateFormatPipe } from './pipes/date-format.pipe';

// Directives
import { DateDirective } from './directives/date.directive';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { PreventMultipleClickDirective } from './directives/prevent-multiple-click.directive';
import { PhoneNumberFormatPipe } from './pipes/phone-number-format.pipe';
import { PrimaryButtonComponent } from './components/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './components/buttons/secondary-button/secondary-button.component';
import { SingleSelectComponent } from './components/form-fields/dropdown/single-select/single-select.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedEffects } from './core/shared.effects';
import { reducer } from './core/shared.reducer';
import { PrimaryClickableTextComponent } from './components/buttons/primary-clickable-text/primary-clickable-text.component';
import { LabelTypeOneComponent } from './components/labels/label-type-one/label-type-one.component';
import { LabelTypeTwoComponent } from './components/labels/label-type-two/label-type-two.component';
import { LabelTypeThreeComponent } from './components/labels/label-type-three/label-type-three.component';
import { LabelTypeFourComponent } from './components/labels/label-type-four/label-type-four.component';
import { HorizontalDividerComponent } from './components/dividers/horizontal-divider/horizontal-divider.component';
import { AuthenticatedWrapperContainerComponent } from './components/application-wrappers/authenticated-wrapper/authenticated-wrapper-container/authenticated-wrapper-container.component';
import { SideNavItemsComponent } from './components/application-wrappers/authenticated-wrapper/components/side-nav-items/side-nav-items.component';
import { SidenavComponent } from './components/application-wrappers/authenticated-wrapper/components/sidenav/sidenav.component';
import { ToolbarComponent } from './components/application-wrappers/authenticated-wrapper/components/toolbar/toolbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PageWrapperComponent } from './components/application-wrappers/page-wrapper/page-wrapper.component';
import { BasicDialogComponent } from './components/dialogbox/basic-dialog/basic-dialog.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PhasesComponent } from './components/phases/phases.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { PhaseNameChipComponent } from './components/phase-name-chip/phase-name-chip.component';
import { MilestoneNameChipComponent } from './components/milestone-name-chip/milestone-name-chip.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ChatInputComponent } from '../base/groups-page/components/chat-input/chat-input.component';
import { TextAreaComponent } from './components/form-fields/input/text-area/text-area.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { CKEditorModule } from 'ckeditor4-angular';
import { ChatDateFormatPipe } from './pipes/chat-date-format.pipe';
import { MultiBreadcrumbsComponent } from './components/multi-breadcrumbs/multi-breadcrumbs.component';

@NgModule({
  declarations: [
    OnlyDigitsDirective,
    DatePickerComponent,
    DateTimePickerComponent,
    DateDirective,
    DateFormatPipe,
    OnlyDigitsDirective,
    PhoneNumberDirective,
    PreventMultipleClickDirective,
    IconComponent,
    PageNotFoundComponent,
    TextInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SingleSelectComponent,
    LoadingScreenComponent,
    PrimaryClickableTextComponent,
    LabelTypeOneComponent,
    LabelTypeTwoComponent,
    LabelTypeThreeComponent,
    LabelTypeFourComponent,
    HorizontalDividerComponent,
    UnauthorizedWrapperTypeOneComponent,
    UnauthorizedWrapperTypeTwoComponent,
    AuthenticatedWrapperContainerComponent,
    ToolbarComponent,
    SidenavComponent,
    SideNavItemsComponent,
    BreadcrumbsComponent,
    PageWrapperComponent,
    BasicDialogComponent,
    UploadFileComponent,
    ProgressBarComponent,
    PhasesComponent,
    PhaseNameChipComponent,
    MilestoneNameChipComponent,
    EditProfileComponent,
    ChatInputComponent,
    TextAreaComponent,
    SafeUrlPipe,
    ChatDateFormatPipe,
    MultiBreadcrumbsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDatepickerModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    FlexModule,
    StoreModule.forFeature('shared', reducer),
    EffectsModule.forFeature([SharedEffects]),
    MatTooltipModule,
    InfiniteScrollModule,
    FlexLayoutModule,
    CKEditorModule,
  ],
  exports: [
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerComponent,
    DateTimePickerComponent,
    TextInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PhoneNumberInputComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SingleSelectComponent,
    PrimaryClickableTextComponent,
    LabelTypeTwoComponent,
    HorizontalDividerComponent,
    LabelTypeThreeComponent,
    IconComponent,
    FlexModule,
    MatTooltipModule,
    BreadcrumbsComponent,
    PageWrapperComponent,
    LabelTypeFourComponent,
    InfiniteScrollModule,
    PhasesComponent,
    PhaseNameChipComponent,
    MilestoneNameChipComponent,
    ChatInputComponent,
    EditProfileComponent,
    TextAreaComponent,
    SafeUrlPipe,
    CKEditorModule,
    ChatDateFormatPipe,
  ],
  providers: [DatePipe, DateFormatPipe, PhoneNumberFormatPipe],
})
export class SharedModule {}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseComponentsContainerComponent} from './base-components-page/container/container.component';
import {BaseComponentsScreenComponent} from './base-components-page/screen/screen.component';
import {SamplesSidenavListComponent} from './base-components-page/components/sidenav-list/sidenav-list.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FlexModule} from '@angular/flex-layout';
import {InputPanelComponent} from './base-components-page/components/input-panel/input-panel.component';
import {SharedModule} from '../shared/shared.module';
import {DatePickerPanelComponent} from './base-components-page/components/date-picker-panel/date-picker-panel.component';
import {DateTimePickerPanelComponent} from './base-components-page/components/date-time-picker-panel/date-time-picker-panel.component';
import {RouterModule} from '@angular/router';
import {ButtonPanelComponent} from './base-components-page/components/button-panel/button-panel.component';
import {SamplesRoutes} from './samples.routing';
import {DropdownPanelComponent} from './base-components-page/components/dropdown-panel/dropdown-panel.component';
import {LoadingScreenPanelComponent} from './base-components-page/components/loading-screen-panel/loading-screen-panel.component';
import {DialogboxPanelComponent} from './base-components-page/components/dialogbox-panel/dialogbox-panel.component';
import {DummyComponent} from './base-components-page/components/dummy/dummy.component';

@NgModule({
  declarations: [
    BaseComponentsContainerComponent,
    BaseComponentsScreenComponent,
    SamplesSidenavListComponent,
    InputPanelComponent,
    DatePickerPanelComponent,
    DateTimePickerPanelComponent,
    ButtonPanelComponent,
    DropdownPanelComponent,
    LoadingScreenPanelComponent,
    DialogboxPanelComponent,
    DummyComponent,
  ],
  imports: [
    RouterModule,
    RouterModule.forChild(SamplesRoutes),
    CommonModule,
    MatSidenavModule,
    FlexModule,
    MatToolbarModule,
    SharedModule,
    RouterModule
  ]
})
export class SamplesModule {
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-action-details-screen',
  templateUrl: './action-details-screen.component.html',
  styleUrls: ['./action-details-screen.component.css'],
})
export class ActionDetailsScreenComponent implements OnInit {
  @Input() workspaceId: any;
  @Input() phaseId: any;
  @Input() milestoneId: any;

  constructor() {
  }

  ngOnInit(): void {
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {getAllWorkspaces, getProfile,} from '../../../core/authentication.selectors';
import * as AuthActions from '../../../core/authentication.actions'
import {FetchUserProfileRequest, SetSelectedWorkSpaceId} from '../../../../base/core/base.actions';

@Component({
  selector: 'app-workspace-selector-screen',
  templateUrl: './workspace-selector-screen.component.html',
  styleUrls: ['./workspace-selector-screen.component.css'],
})
export class WorkspaceSelectorScreenComponent implements OnInit, OnDestroy {
  allWorkspaceSubscription: Subscription;
  AllPossibleWorkspaces: any[] = [];
  userProfileData$: Observable<any> = new Observable();

  constructor(
    private store: Store,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.userProfileData$ = this.store.select(getProfile);
    this.allWorkspaceSubscription = this.store
      .select(getAllWorkspaces)
      .subscribe((data) => {
        this.AllPossibleWorkspaces = data;
      });
  }

  onWorkspaceSelection(selectedWorkspaceId) {
    const selectedWorkspace = this.AllPossibleWorkspaces.filter(
      (workspace) => workspace.id === selectedWorkspaceId
    );
    this.store.dispatch(
      AuthActions.ChangeSelectedWorkspace({workspace: {...selectedWorkspace[0]}})
    );
    this.store.dispatch(
      SetSelectedWorkSpaceId({SelectedWorkspaceId: selectedWorkspaceId})
    );

    this.router.navigate(
      ['/changemakers/dashboard/workspace/' + selectedWorkspaceId],
      {replaceUrl: true}
    );
  }

  ngOnDestroy(): void {
    this.allWorkspaceSubscription?.unsubscribe();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, tap } from 'rxjs';
import { take } from 'rxjs';
import * as BaseActions from '../../../core/base.actions';
import * as BaseSelector from '../../../core/base.selectors';
import { MilestoneConversationThreadService } from '../../service/milestone-conversation-thread.service';
@Component({
  selector: 'app-milestone-conversation-pannel',
  templateUrl: './milestone-conversation-pannel.component.html',
  styleUrls: ['./milestone-conversation-pannel.component.css'],
})
export class MilestoneConversationPannelComponent implements OnInit {
  constructor(
    private store: Store,
    private milestoneThreadService: MilestoneConversationThreadService
  ) {}
  conversationThreadDetailSubscription: Subscription = new Subscription();
  conversationExist: boolean = false;
  @Input() selectedGroupId: any = '';
  @Input() milestoneId: string = '';
  @Input() workspaceId: any = '';
  @Output() sendMessageHandler: EventEmitter<any> = new EventEmitter();
  @Output() lastMessageReadHandler: EventEmitter<any> = new EventEmitter();

  conversationId: any;

  ngOnInit(): void {
    this.conversationThreadDetailSubscription = this.store
      .select(BaseSelector.getAllConversationThreadForTheMilestone)
      .pipe(
        tap((thread) => {
          if (thread.conversations?.length > 0) {
            /* fetch all new Conversation */
            this.conversationId = thread?.conversations[0]?.id;
            this.milestoneThreadService.setCurrentConversationId(
              this.conversationId
            );
            this.store.dispatch(
              BaseActions.FetchAllConversationRelatedToThreadsMilestoneRequest({
                thread: thread.conversations[0],
              })
            );
            this.conversationExist = true;
          } else {
            /* create new conversation */
            this.conversationExist = false;
          }
        })
      )
      .subscribe();
  }
  onStartConversationHandler(event) {
    const data = {
      thread_type: 'group',
      group_id: this.selectedGroupId,
      milestone_id: this.milestoneId,
      messageBody: event.text,
      attachments: event.attachments,
    };
    this.store.dispatch(
      BaseActions.CreateNewConversationChannelForMilestoneRequest({
        payload: data,
      })
    );
  }
  sendMessage(event) {
    this.sendMessageHandler.emit(event);
  }
  readLastMessage(event) {
    this.lastMessageReadHandler.emit(event);
  }
}

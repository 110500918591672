import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {MenuItem} from '../../interface/sidenav';
import * as BaseAction from '../../../../../../base/core/base.actions';

@Component({
  selector: 'app-side-nav-items',
  templateUrl: './side-nav-items.component.html',
  styleUrls: ['./side-nav-items.component.css'],
})
export class SideNavItemsComponent implements OnInit {
  @Input() item: MenuItem;
  @Input() workspaceId;
  @Input() showBadge = false

  constructor(private store: Store) {
  }

  ngOnInit(): void {


  }

  getStyleClass() {
    if (
      this.item.icon === 'explore' ||
      this.item.icon === 'users' ||
      this.item.icon === 'settings'
    ) {
      return 'fill-icon';
    } else {
      return 'icon';
    }
  }

  getActiveLinkStyles() {
    if (this.item.icon === 'explore' || this.item.icon === 'users') {
      return 'fill-active';
    } else {
      return 'active';
    }
  }

  onNavigation() {
    this.store.dispatch(BaseAction.FetchUnreadCountGroupsIconsRequest());
  }
}

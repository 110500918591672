import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CommonService} from '../common/common.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private commonService: CommonService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.commonService.isAuthenticated(); // ... your authentication logic here
    if (isLoggedIn) {
      this.commonService.removeFromSessionStorage('redirectUrl');
      return true;
    } else {
      const redirectUrl = state.url;
      this.commonService.setToSessionStorage('redirectUrl', redirectUrl);

      this.router.navigate(['/login']);

      return false;
    }
  }
}

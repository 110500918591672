import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CommonService} from '../common/common.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {getSelectedWorkspace} from '../../../authentication/core/authentication.selectors';

export let InterceptorSkipHeader: string;
InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  messageQueue = [];
  processingMessage = false;

  excludedUrlsRegex: RegExp[];
  excludedUrls = ['.svg'];

  constructor(
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store,
  ) {
    this.excludedUrlsRegex =
      this.excludedUrls.map((urlPattern) => new RegExp(urlPattern, 'i')) || [];
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const passThrough: boolean = !!this.excludedUrlsRegex.find((regex) =>
      regex.test(req.url)
    );

    if (passThrough) {
      return next.handle(req);
    }

    let headers = req.headers;
    const body = req.body;

    let requestToSend;

    if (!headers.get('file-upload')) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (!headers.get('file-upload') && this.commonService.isAuthenticated()) {
      const token = this.commonService.getAuthenticationToken();
      headers = headers.set('Authorization', `Bearer ${token}`);

      let workspaceId = '';

      this.store.select(getSelectedWorkspace).subscribe((data) => {
        workspaceId = data?.id
      }).unsubscribe();

      if (workspaceId) {
        headers = headers.set('workspace', `${workspaceId}`);
      }
    }

    headers = headers.set('platform', 'web');

    requestToSend = req.clone({headers, body});
    return next.handle(requestToSend).pipe(
      map((response) => {
        if (
          (response['body'] && !response['body'].success) ||
          ('success' in response && response['success'] === false)
        ) {
          throw response;
        }

        return response;
      }),
      catchError((errorResponse) => this.handleError(errorResponse))
    );
  }

  handleError(response) {
    if (response.status === 401) {
      this.commonService.logout();
    }

    if (
      response.error?.code === 'WORKSPACE_HEADER_INVALID' ||
      response.body?.code === 'WORKSPACE_HEADER_INVALID'
    ) {
      if (this.router.url.includes('login')) {
        return;
      } else {
        this.commonService.logout();
        this.commonService.notification('You do not have access to the selected workspace. Please login again.', 'danger');
        return;
      }
    }

    let errorObject = response?.body ||
      response?.error || response?.message ||
      {
        message: 'Oops! Something went wrong.',
      };

    // here we are receiving plain object as a response which has success and message fields
    // for that we handled error with below mechanism
    if (response.hasOwnProperty('success') && response.hasOwnProperty('message') && !response.success) {
      errorObject = {
        message: response.message,
      };
    }

    this.addMessageToQueue(errorObject.message);

    return throwError(errorObject);
  }

  addMessageToQueue(message) {
    this.messageQueue.push(message);

    if (!this.processingMessage) {
      this.displaySnackbar();
    }
  }

  displaySnackbar() {
    const nextMessage = this.getNextMessage();

    if (!nextMessage) {
      this.processingMessage = false; // No message in the queue, set flag false
      return;
    }

    this.processingMessage = true; // A message was dequeue and is being processed

    this.snackBar
      .open(nextMessage, undefined, {
        duration: 1500,
        verticalPosition: 'bottom', // 'top' | 'bottom'
        horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: 'danger',
      })
      .afterDismissed()
      .subscribe(() => {
        this.displaySnackbar();
      });
  }

  getNextMessage(): string | undefined {
    return this.messageQueue.length ? this.messageQueue.shift() : null;
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import * as AuthenticationActions from '../../../core/authentication.actions';
import {ResetPasswordService} from '../services/reset-password.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-reset-password-container',
  templateUrl: './reset-password-container.component.html',
  styleUrls: ['./reset-password-container.component.css']
})
export class ResetPasswordContainerComponent implements OnInit, OnDestroy {
  routeChangeSubscription: Subscription;
  queryParamsSubscription: Subscription;

  resetPasswordRequestSubscription: Subscription;
  resendResetPasswordEmailSubscription: Subscription;
  resetPasswordSetSubscription: Subscription;

  iconMap = {
    'forgot-password': 'key',
    'email-sent': 'inbox',
    'reset-password': 'key',
    success: 'check-circle'
  }

  icon: string;

  constructor(
    private store: Store<fromRoot.State>,
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.routeChangeSubscription = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        const endPoint = data.url.split('?')[0].split('/').pop()
        this.icon = this.iconMap[endPoint];
      }
    });

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((values) => {
      const {organizationId} = values;
      this.authenticationService.setOrganizationId(organizationId);
    });

    this.resetPasswordRequestSubscription = this.resetPasswordService.request.subscribe((payload) => {
      this.store.dispatch(AuthenticationActions.ForgotPasswordRequest(payload));
    });

    this.resendResetPasswordEmailSubscription = this.resetPasswordService.resendEmail.subscribe((payload) => {
      this.store.dispatch(AuthenticationActions.ForgotPasswordRequest(payload));
    });

    this.resetPasswordSetSubscription = this.resetPasswordService.set.subscribe((payload) => {
      this.store.dispatch(AuthenticationActions.ResetPasswordRequest(payload));
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routeChangeSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
    this.resetPasswordRequestSubscription?.unsubscribe();
    this.resendResetPasswordEmailSubscription?.unsubscribe();
    this.resetPasswordSetSubscription?.unsubscribe();
  }
}

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import * as BaseSelector from '../../../core/base.selectors';
import * as BaseActions from '../../../core/base.actions';
import * as AuthenticationSelectors from '../../../../authentication/core/authentication.selectors';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';
import { Observable } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { MessageService } from '../../service/message.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-direct-chat',
  templateUrl: './direct-chat.component.html',
  styleUrls: ['./direct-chat.component.css'],
})
export class DirectChatComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatDiv') private chatDiv: ElementRef;

  @Input() conversationId = '';
  @Input() conversationName: any = '';
  @Input() conversationType = '';
  @Output() closeChatHandler: EventEmitter<any> = new EventEmitter();
  @Output() sendMessageHandler: EventEmitter<any> = new EventEmitter();
  @Output() readLastMessageHandler: EventEmitter<any> = new EventEmitter();
  threadData: any;

  allMessage$: Observable<any> = new Observable();

  allMessages: any[] = [];
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  hasMoreMessages = false;
  lastMessageId = 0;
  oldLastMessageId = 0;
  latestReadMessageId = '';

  isFirstLoad = true;

  oldMessagesFetched = false;
  @Input() workspaceId: any;
  lastReadMessage: any;

  constructor(
    private store: Store,
    private commonService: CommonService,
    private messageService: MessageService,
    public cdRef: ChangeDetectorRef
  ) {}

  showScrollLeftIcons() {
    if (
      document?.getElementById('chatDiv')?.scrollLeft >
      document?.getElementById('chatDiv')?.clientLeft
    ) {
      return true;
    } else return true;
  }

  ngOnInit(): void {
    this.allMessage$ = this.store.select(BaseSelector.getAllMessages).pipe(
      tap((message) => {
        if (message) {
          this.hasMoreMessages = message.hasMore;
          this.lastMessageId = message?.messages[0]?.id;
          this.latestReadMessageId =
            message.messages[message?.messages?.length - 1]?.id;

          this.threadData = message;

          this.messageService.setCurrentMessagePageNumber(this.lastMessageId);
          if (this.lastMessageId !== this.lastReadMessage) {
            this.readMessageHandler();
            this.lastReadMessage = this.lastMessageId;
          }
          if (this.lastMessageId === this.oldLastMessageId) {
            setTimeout(() => {
              this.scrollToBottom();
            }, 0);
          } else {
            this.oldLastMessageId = this.lastMessageId;
          }
        }
      }),
      pluck('messages'),
      tap((message) => {
        this.allMessages = message;
      })
    );
  }

  sendMessage(event) {
    this.sendMessageHandler.emit(event);
  }

  dateCheck(firstMessageDate, previousMessageDate) {
    return firstMessageDate !== previousMessageDate;
  }

  closeChat() {
    this.closeChatHandler.emit();
  }

  onScrollDown() {}

  onUp() {
    if (this.hasMoreMessages === true) {
      this.store.dispatch(
        BaseActions.fetchAllMessageForConversationIdRequestNextPage({
          conversationId: this.conversationId,
        })
      );
    }
  }

  readMessageHandler() {
    this.readLastMessageHandler.emit(this.latestReadMessageId);
  }

  scrollToBottom(): void {
    try {
      this.chatDiv.nativeElement.scrollTop =
        this.chatDiv.nativeElement.scrollHeight;
    } catch (err) {}
  }

  ngAfterViewChecked() {
    if (this.isFirstLoad) {
      this.scrollToBottom();
      this.isFirstLoad = false;
    }
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as AuthenticationActions from './authentication.actions';
import {AuthenticationService} from './authentication.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../../shared/services/common/common.service';
import {
  AuthenticatedWrapperService
} from '../../shared/components/application-wrappers/authenticated-wrapper/service/authenticated-wrapper.service';
import {DirectoryService} from '../../base/directory-page/service/directory.service';
import * as BaseActions from '../../base/core/base.actions';

@Injectable()
export class AuthenticationEffects {
  login$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.LoginRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.login(payload).pipe(
          map((response: any) => {
            let {profile} = response.data;
            const {token} = response.data;
            const {message} = response;
            // let currentWorkSpace;

            const organizations = profile.organizations || [];

            // profile.organizations.map((organization) => {
            //   if (organization.id === profile.currentOrganizationId) {
            //     organization.workspaces.map((workspace) => {
            //       if (workspace.id === profile.currentWorkspaceId) {
            //         currentWorkSpace = {
            //           ...workspace,
            //           logo: organization.logo,
            //           organizationName: organization.name,
            //         };
            //       }
            //     });
            //   }
            // });


            const workspaces = [];

            if (organizations?.length > 0) {
              organizations.map((organization) => {
                if (organization.workspaces) {
                  organization.workspaces.map((workspace) => {
                    workspaces.push({
                      ...workspace,
                      logo: organization.logo,
                      organizationName: organization.name,
                    });
                  });
                }
              });
            }

            profile = {
              firstName: profile.firstName,
              lastName: profile.lastName,
              title: profile.title,
              bio: profile.bio,
              email: profile.email,
              phoneNumber: profile.phoneNumber,
              displayImage: profile.displayImage,
              currentOrganizationId: profile.currentOrganizationId,
              currentWorkspaceId: profile.currentWorkspaceId,
              organizations: profile.organizations,
              ...(workspaces.length === 1 ? { selectedWorkspace: workspaces[0] } : {}),
              availableWorkspaces: workspaces
            };

            if (workspaces.length === 1) {
              this.store.dispatch(BaseActions.SetSelectedWorkSpaceId({SelectedWorkspaceId: workspaces[0]?.id}));
            }

            this.commonService.setAuthenticationToken(token);
            return AuthenticationActions.LoginSuccess({profile, message});
          }),
          catchError((error) => {
            return of(AuthenticationActions.LoginFailure(error.message));
          }),
          tap((action) => {
            if (action.type === AuthenticationActions.LoginSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');

              const redirectUrl = this.commonService.getFromSessionStorage('redirectUrl');

              if (redirectUrl) {
                const url = redirectUrl.split('?')[0];
                const qp = redirectUrl.split('?')[1]?.split('&');

                const queryParams = {};

                if (qp?.length) {
                  qp.map(eqp => {
                    queryParams[eqp.split('=')[0]] = eqp.split('=')[1]
                  });
                }
                this.router.navigate([url], {queryParams}).then(() => {
                  this.commonService.removeFromSessionStorage(redirectUrl);
                });
              } else {
                const workspaces = action.profile?.availableWorkspaces;
                if (workspaces?.length > 1) {
                  this.router.navigate([
                    `/workspace`,
                  ]);
                } else if (workspaces?.length === 1) {
                  this.router.navigate(
                    ['/changemakers/dashboard/workspace/' + action.profile?.selectedWorkspace?.id],
                    {replaceUrl: true}
                  );
                }
              }
            } else if (
              action.type === AuthenticationActions.LoginFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  createAccount$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.CreateAccountRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.createAccount(payload).pipe(
          map((response: any) => {
            let {profile} = response.data;
            const {token} = response.data;
            const {message} = response;
            const organizations = profile.organizations || [];


            const workspaces = [];

            if (organizations?.length > 0) {
              organizations.map((organization) => {
                if (organization.workspaces) {
                  organization.workspaces.map((workspace) => {
                    workspaces.push({
                      ...workspace,
                      logo: organization.logo,
                      organizationName: organization.name,
                    });
                  });
                }
              });
            }

            profile = {
              firstName: profile.firstName,
              lastName: profile.lastName,
              title: profile.title,
              bio: profile.bio,
              email: profile.email,
              phoneNumber: profile.phoneNumber,
              displayImage: profile.displayImage,
              currentOrganizationId: profile.currentOrganizationId,
              currentWorkspaceId: profile.currentWorkspaceId,
              organizations: profile.organizations,
              ...(workspaces.length === 1 ? { selectedWorkspace: workspaces[0] } : {}),
              availableWorkspaces: workspaces
            };

            this.commonService.setAuthenticationToken(token);
            return AuthenticationActions.CreateAccountSuccess({
              profile,
              message,
            });
          }),
          catchError((error) => {
            return of(
              AuthenticationActions.CreateAccountFailure(error.message)
            );
          }),
          tap((action) => {
            if (
              action.type === AuthenticationActions.CreateAccountSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');

              this.router.navigate([
                `/changemakers/dashboard/workspace/${action.profile.selectedWorkspace.id}`,
              ]);
            } else if (
              action.type === AuthenticationActions.CreateAccountFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  updateAccount$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.UpdateAccountRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.updateAccount(payload).pipe(
          map((response: any) => {
            const {data, message} = response;
            const profile = {
              firstName: data.firstName,
              lastName: data.lastName,
              title: data.title,
              organization: data.organization,
              email: data.email,
              phoneNumber: data.phoneNumber,
              displayImage: data.displayImage,
            };
            return AuthenticationActions.UpdateAccountSuccess({
              profile,
              message,
            });
          }),
          catchError((error) => {
            return of(
              AuthenticationActions.UpdateAccountFailure(error.message)
            );
          }),
          tap((action) => {
            if (
              action.type === AuthenticationActions.UpdateAccountSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
            } else if (
              action.type === AuthenticationActions.UpdateAccountFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  forgotPassword$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.ForgotPasswordRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.forgotPassword(payload).pipe(
          map((response: any) => {
            return AuthenticationActions.ForgotPasswordSuccess(response);
          }),
          catchError((error) => {
            return of(AuthenticationActions.ForgotPasswordFailure(error));
          }),
          tap((action) => {
            if (
              action.type === AuthenticationActions.ForgotPasswordSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              this.router.navigate(['/forgot-password/email-sent']).then(() => {
                this.commonService.notification(action.message, 'success');
              });
            } else if (
              action.type === AuthenticationActions.ForgotPasswordFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  resendForgotPasswordEmail: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.ResendForgotPasswordEmailRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.forgotPassword(payload).pipe(
          map((response: any) => {
            return AuthenticationActions.ResendForgotPasswordEmailSuccess(
              response
            );
          }),
          catchError((error) => {
            return of(
              AuthenticationActions.ResendForgotPasswordEmailFailure(error)
            );
          }),
          tap((action) => {
            if (
              action.type ===
              AuthenticationActions.ResendForgotPasswordEmailSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type ===
              AuthenticationActions.ResendForgotPasswordEmailFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  resetPassword$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.ResetPasswordRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.resetPassword(payload).pipe(
          map((response: any) => {
            return AuthenticationActions.ResetPasswordSuccess({
              ...payload,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(AuthenticationActions.ResetPasswordFailure(error));
          }),
          tap((action) => {
            if (
              action.type === AuthenticationActions.ResetPasswordSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              this.router
                .navigate(['/reset-password/success'], {
                  queryParams: {
                    organizationId: action.organizationId,
                    token: action.token,
                  },
                })
                .then(() => {
                  this.commonService.notification(action.message, 'success');
                });
            } else if (
              action.type === AuthenticationActions.ResetPasswordFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  updatePassword$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.UpdatePasswordRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.updatePassword(payload).pipe(
          map((response: any) => {
            return AuthenticationActions.UpdatePasswordSuccess({
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(AuthenticationActions.UpdatePasswordFailure(error));
          }),
          tap((action) => {
            if (
              action.type === AuthenticationActions.UpdatePasswordSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              this.commonService.notification(action.message, 'success');
              // logout user
              this.commonService.logout();
            } else if (
              action.type === AuthenticationActions.UpdatePasswordFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
              this.commonService.stopLoading();
              this.commonService.notification(action.message, 'error');
            }
          })
        )
      )
    )
  );
  updateUserProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.UpdateProfileDataRequest),
      map((payload) => {
        return payload.payload;
      }),
      mergeMap((payload) => {
        this.commonService.startLoading();

        return this.authenticationService.updateProfile(payload).pipe(
          map((response) => {
            return AuthenticationActions.UpdateProfileDataSuccess(response);
          }),
          catchError((err) => {
            return AuthenticationActions.UpdateProfileDataFail(err);
          }),
          tap((action) => {
            if (
              action.type ===
              AuthenticationActions.UpdateProfileDataSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              /*
              !need to change here add a service call which will execute the api updates
                */
              this.directoryService.updateDirectoryPage()
              this.commonService.notification(action.message, 'success');
            } else if (
              action.type === AuthenticationActions.UpdateProfileDataFail.type
            ) {
              // Code to execute on API Failure Action dispatch
              this.commonService.notification(action.message, 'error');
            }
            this.commonService.stopLoading();
          })
        );
      })
    )
  );

  GetImageUploadSasToken$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.FetchSasTokenForProfileImageRequest),
      mergeMap((payload) =>
        this.authenticationService.getSasTokenForTheProfileImageUpload().pipe(
          map((response) => {
            return AuthenticationActions.FetchSasTokenForProfileImageSuccess(
              response
            );
          }),
          catchError((err) =>
            AuthenticationActions.FetchSasTokenForProfileImageFail(err)
          ),
          tap((action) => {
            if (
              action.type ===
              AuthenticationActions.FetchSasTokenForProfileImageSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              // logout user
            } else if (
              action.type ===
              AuthenticationActions.FetchSasTokenForProfileImageFail.type
            ) {
              // Code to execute on API Failure Action dispatch
              this.commonService.notification(action.message, 'error');
            }
          })
        )
      )
    )
  );
  changePasswordRequest$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.ChangePasswordForEditProfileRequest),
      map((action) => {
        this.commonService.startLoading();
        const {type, ...payload} = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.forgotPassword(payload).pipe(
          map((response: any) => {
            return AuthenticationActions.ForgotPasswordSuccess(response);
          }),
          catchError((error) => {
            return of(AuthenticationActions.ForgotPasswordFailure(error));
          }),
          tap((action) => {
            if (
              action.type === AuthenticationActions.ForgotPasswordSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
              this.authenticatedWrapperService.changePasswordClickedHandler(
                true
              );
            } else if (
              action.type === AuthenticationActions.ForgotPasswordFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private authenticationService: AuthenticationService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService,
    private authenticatedWrapperService: AuthenticatedWrapperService, private directoryService: DirectoryService
  ) {
  }
}

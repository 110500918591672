import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollectionPageService {
  mileStoneCurrentPage: BehaviorSubject<number> = new BehaviorSubject(1);
  phasesCurrentPage: BehaviorSubject<number> = new BehaviorSubject(1);

  setCurrentMilestonePage(pageNumber) {
    this.mileStoneCurrentPage.next(pageNumber);

  }

  setCurrentPhasePage(pageNumber) {
    this.phasesCurrentPage.next(pageNumber);

  }
}

import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {SharedService} from '../../core/shared.service';
import {CommonService} from '../../services/common/common.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import * as fromRoot from '../../../../state/app.state';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as BaseSelectors from '../../../base/core/base.selectors';
import * as BaseActions from '../../../base/core/base.actions';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit, OnDestroy {
  /** UPLOAD STATUS
   * 1 :: Pending
   * 2 :: In Progress
   * 3 :: Successful
   * 4 :: Failed
   */

  uploadSubscription: Subscription;
  sasTokenSubscription: Subscription;
  configSubscription: Subscription;
  sasToken: any;
  config: any;

  maxAllowedUploads = 1;
  deliverableId;

  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  uploadProcessTracker = [];

  documentsList = [];
  preventDelete = [2];
  acceptedFileTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png'
  ];

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    this.maxAllowedUploads = this.data?.maxAllowedUploads;
    this.deliverableId = this.data?.deliverableId;

    this.commonService.getSasToken();
  }

  ngOnInit() {
    this.sasTokenSubscription = this.store.select(BaseSelectors.getSasToken).subscribe((sasToken) => {
      this.sasToken = sasToken;
    });

    this.configSubscription = this.store.select(BaseSelectors.getDetailsConfig).subscribe((detailConfig) => {
      this.config = {
        workspaceId: detailConfig.selectedWorkspaceId,
        phaseId: detailConfig.selectedPhaseId,
        milestoneId: detailConfig.selectedMilestoneId,
        deliverableId: this.deliverableId
      };
    });

    this.form = this.fb.group({
      link: new FormControl('')
    });
  }

  addLink() {
    if (this.documentsList.length >= this.maxAllowedUploads) {
      this.commonService.notification('You Can Upload maximum ' + this.maxAllowedUploads + ' file(s)', 'warning');
      return;
    } else {
      const {link} = this.form.getRawValue();
      const fileObject = {
        type: 'link',
        name: link,
        fileName: link,
        uploadStatus: 3
      };
      this.form.patchValue({link: ''}, {emitEvent: false})
      this.documentsList.push(fileObject);
    }
  }

  async fileBrowseHandler() {
    if (this.documentsList.length >= this.maxAllowedUploads) {
      this.commonService.notification('You Can Upload maximum ' + this.maxAllowedUploads + ' file(s)', 'warning');
      return;
    }
    const files = this.fileDropRef.nativeElement.files;
    let invalidTypeCount = 0;
    // tslint:disable-next-line:forin
    for (const key in files) {
      const file = files[key];
      if (file instanceof File) {
        if (!this.acceptedFileTypes.includes(file.type)) {
          invalidTypeCount++;
          continue;
        }
        if (file.size > 10000000) {
          this.commonService.notification('File size can not exceed 10Mb', 'warning');
          return;
        }
        if (this.documentsList.length >= this.maxAllowedUploads) {
          this.commonService.notification('You Can Upload maximum ' + this.maxAllowedUploads + ' file(s)', 'warning');
          return;
        } else {
          const suffix = new Date().getTime();
          const fileObject = {
            type: 'file',
            uploadStatus: 1,
            name: (file.name).substring(0, (file.name).lastIndexOf('.')) + '-' + suffix +
              (file.name).substring((file.name).lastIndexOf('.')),
            size: file.size,
            fileName: file.name,
            data: file,
            progress: 0,
            hasError: false,
            uploadUrl: null,
            loading: false,
            fileType: file.type
          };

          fileObject.uploadUrl = `${this.sasToken.host}/${this.sasToken.container}/${fileObject.name}?${this.sasToken.token}`;

          this.documentsList.push(fileObject);

          this.uploadFiles(fileObject)
        }
      }
    }

    if (invalidTypeCount) {
      this.commonService.notification(invalidTypeCount + ' file(s) have invalid file type', 'danger');
    }
    this.fileDropRef.nativeElement.value = '';
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFiles(fileObject) {
    this.uploadProcessTracker = [...this.uploadProcessTracker, 1];
    const payload = {
      url: fileObject.uploadUrl,
      data: fileObject.data,
      fileType: fileObject.fileType
    }
    fileObject.loading = true;
    this.uploadSubscription = this.commonService.uploadToBlobStorage(payload).subscribe((uploadResponse) => {
      if (uploadResponse.type === HttpEventType.UploadProgress) {
        fileObject.uploadStatus = 2;
        fileObject.progress = Math.round(100 * uploadResponse.loaded / uploadResponse.total);
      } else if (uploadResponse instanceof HttpResponse) {
        this.uploadProcessTracker = [...this.uploadProcessTracker].slice(1);
        fileObject.uploadStatus = 3;
      }
    }, err => {
      this.commonService.notification(`File (${fileObject.name}) could not be uploaded.`, 'danger');
      this.uploadProcessTracker = [...this.uploadProcessTracker].slice(1);
      fileObject.uploadStatus = 4;
    }, () => {
      fileObject.loading = false;
    });
  }

  deleteFile(index: number) {
    const file = this.documentsList[index];
    const fileName = file.name;
    this.commonService.startLoading();
    if (file.type === 'link') {
      this.documentsList.splice(index, 1);
      this.commonService.stopLoading();
      this.commonService.notification(`File (${fileName}) was deleted.`, 'success');
      return;
    }
    this.commonService.deleteFromBlobStorage(this.documentsList[index]?.uploadUrl).subscribe((deleteResponse) => {
      if (deleteResponse instanceof HttpResponse) {
        this.documentsList.splice(index, 1);
        this.commonService.stopLoading();
        this.commonService.notification(`File (${fileName}) was deleted.`, 'success');
      }
    })
  }

  handleSubmit() {
    const filesArray = this.documentsList.map((eachDocument) => {
      if (eachDocument.type === 'file') {
        return {
          fileName: eachDocument.fileName,
          blobName: eachDocument.name
        }
      } else {
        return {
          fileLink: eachDocument.name
        }
      }
    })

    this.store.dispatch(BaseActions.SubmitParticipantMilestoneDeliverableRequest({
      data: {
        fileUploadsAttributes: filesArray
      },
      urlParams: this.config
    }));
  }

  ngOnDestroy() {
    this.uploadSubscription?.unsubscribe();
    this.sasTokenSubscription?.unsubscribe();
    this.configSubscription?.unsubscribe();
  }

}

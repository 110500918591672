import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-base-components-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class BaseComponentsContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

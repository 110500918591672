import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MaterialModule} from '../shared/material-module';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {BaseRoutes} from './base.routing';
import {DashboardContainerComponent} from './dashboard-page/dashboard-container/dashboard-container.component';
import {DashboardScreenComponent} from './dashboard-page/dashboard-screen/dashboard-screen.component';
import {ActionDetailsContainerComponent} from './action-details-page/action-details-container/action-details-container.component';
import {ActionDetailsScreenComponent} from './action-details-page/action-details-screen/action-details-screen.component';
import {StoreModule} from '@ngrx/store';
import {reducer} from './core/base.reducer';
import {EffectsModule} from '@ngrx/effects';
import {BaseEffects} from './core/base.effects';
import {ActionDetailsContentComponent} from './action-details-page/components/action-details-content/action-details-content.component';
import {MilestonePageContainerComponent} from './milestone-page/milestone-page-container/milestone-page-container.component';
import {MilestonePageScreenComponent} from './milestone-page/milestone-page-screen/milestone-page-screen.component';
import {MilestoneResourceComponent} from './milestone-page/components/milestone-resource/milestone-resource.component';
import {MilestoneActionStepsComponent} from './milestone-page/components/milestone-action-steps/milestone-action-steps.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiInterceptorService} from '../shared/services/api-interceptor/api-interceptor.service';
import {CollectionContainerComponent} from './collection-page/collection-container/collection-container.component';
import {CollectionScreenComponent} from './collection-page/collection-screen/collection-screen.component';
import {CollectionPhasesComponent} from './collection-page/components/collection-phases/collection-phases.component';
import {PhaseMilestoneComponent} from './collection-page/components/phase-milestone/phase-milestone.component';
import {PhaseActionstepsComponent} from './collection-page/components/phase-actionsteps/phase-actionsteps.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SafeHTMLPipe} from '../shared/pipes/safe-html.pipe';
import {MilestoneCardComponent} from './dashboard-page/components/milestone-card/milestone-card.component';
import {CalanderCardComponent} from './dashboard-page/components/calander-card/calander-card.component';
import {ActionStepsComponent} from './dashboard-page/components/action-steps/action-steps.component';
import {TeamRolesComponent} from './collection-page/components/team-roles/team-roles.component';
import {DatesTableComponent} from './dashboard-page/components/dates-table/dates-table.component';
import {MilestoneContentItemComponent} from './dashboard-page/components/milestone-content-item/milestone-content-item.component';
import {GroupsContainerComponent} from './groups-page/groups-container/groups-container.component';
import {GroupsScreenComponent} from './groups-page/groups-screen/groups-screen.component';
import {InformationScreenComponent} from './information-page/information-screen/information-screen.component';
import {InformationContainerComponent} from './information-page/information-container/information-container.component';
import {SettingsContainerComponent} from './settings-page/settings-container/settings-container.component';
import {SettingsScreenComponent} from './settings-page/settings-screen/settings-screen.component';
import {DirectoryContainerComponent} from './directory-page/directory-container/directory-container.component';
import {DirectoryScreenComponent} from './directory-page/directory-screen/directory-screen.component';
import {DiscussionsComponent} from './groups-page/components/discussions/discussions.component';
import {AttachmentsComponent} from './groups-page/components/attachments/attachments.component';
import {MembersComponent} from './groups-page/components/members/members.component';
import {UsersComponent} from './directory-page/components/users/users.component';
import {MyGroupSideNavComponent} from './groups-page/components/my-group-side-nav/my-group-side-nav.component';
import {ChatThreadsComponent} from './groups-page/components/chat-threads/chat-threads.component';
import {ChatComponent} from './groups-page/components/chat/chat.component';
import {ChatDateComponent} from './groups-page/components/chat-date/chat-date.component';
import {OpenChatComponent} from './groups-page/components/open-chat/open-chat.component';
import {ClosedChatComponent} from './groups-page/components/closed-chat/closed-chat.component';
import {DirectChatComponent} from './groups-page/components/direct-chat/direct-chat.component';
import {NewGroupChatComponent} from './groups-page/components/new-group-chat/new-group-chat.component';
import {NewDirectChatComponent} from './groups-page/components/new-direct-chat/new-direct-chat.component';
import {UserProfileComponent} from './directory-page/components/user-profile/user-profile.component';
import { MilestoneConversationComponent } from './milestone-page/components/milestone-conversation/milestone-conversation.component';
import { MilestoneConversationSideNavComponent } from './milestone-page/components/milestone-conversation-side-nav/milestone-conversation-side-nav.component';
import { MilestoneConversationPannelComponent } from './milestone-page/components/milestone-conversation-pannel/milestone-conversation-pannel.component';
import { MilestoneNewConversationChannelComponent } from './milestone-page/components/milestone-new-conversation-channel/milestone-new-conversation-channel.component';
import { MilestoneConversationChannelComponent } from './milestone-page/components/milestone-conversation-channel/milestone-conversation-channel.component';
import { MentionsPipe } from './groups-page/pipe/mentions.pipe';
import { MentionUserComponent } from './groups-page/components/mention-user/mention-user.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { MilestoneStatusComponent } from './milestone-page/components/milestone-status/milestone-status.component';
import { MilestoneFilesComponent } from './milestone-page/components/milestone-files/milestone-files.component';
@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MaterialModule,
    SharedModule,
    StoreModule.forFeature('base', reducer),
    EffectsModule.forFeature([BaseEffects]),
    InfiniteScrollModule,
    CKEditorModule
  ],
  declarations: [
    DashboardContainerComponent,
    DashboardScreenComponent,
    ActionDetailsContainerComponent,
    ActionDetailsScreenComponent,
    ActionDetailsContentComponent,
    MilestonePageContainerComponent,
    MilestonePageScreenComponent,
    MilestoneResourceComponent,
    MilestoneActionStepsComponent,
    CollectionContainerComponent,
    CollectionScreenComponent,
    CollectionPhasesComponent,
    PhaseMilestoneComponent,
    PhaseActionstepsComponent,
    SafeHTMLPipe,
    MilestoneCardComponent,
    CalanderCardComponent,
    ActionStepsComponent,
    TeamRolesComponent,
    DatesTableComponent,
    MilestoneContentItemComponent,
    GroupsContainerComponent,
    GroupsScreenComponent,
    InformationScreenComponent,
    InformationContainerComponent,
    SettingsContainerComponent,
    SettingsScreenComponent,
    DirectoryContainerComponent,
    DirectoryScreenComponent,
    DiscussionsComponent,
    AttachmentsComponent,
    MembersComponent,
    UsersComponent,
    MyGroupSideNavComponent,
    ChatThreadsComponent,
    ChatComponent,
    ChatDateComponent,
    OpenChatComponent,
    ClosedChatComponent,
    DirectChatComponent,
    NewGroupChatComponent,
    NewDirectChatComponent,
    UserProfileComponent,
    MilestoneConversationComponent,
    MilestoneConversationSideNavComponent,
    MilestoneConversationPannelComponent,
    MilestoneNewConversationChannelComponent,
    MilestoneConversationChannelComponent,
    MentionsPipe,
    MentionUserComponent,
    MilestoneStatusComponent,
    MilestoneFilesComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
  ],
  exports: [
    ChatThreadsComponent,
    SafeHTMLPipe
  ]
})
export class BaseModule {}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as BaseAction from '../../core/base.actions';
import {GroupsNavigationService} from '../service/groups-navigation.service';
import { CommonService } from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-groups-container',
  templateUrl: './groups-container.component.html',
  styleUrls: ['./groups-container.component.css'],
})
export class GroupsContainerComponent implements OnInit, OnDestroy {
  workspaceId = '';
  paramsSubscription: Subscription = new Subscription();

  queryParamsSubscription: Subscription = this.activatedRoute.queryParams.subscribe(params => {
    if (Object.keys(params).length) {
      this.groupNavigationService.setGroupPreviousSelected(params);
    }
  })

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private groupNavigationService: GroupsNavigationService,
    private commonService:CommonService,
  ) {
  }

  ngOnInit(): void {
    this.paramsSubscription = this.activatedRoute.paramMap.subscribe(
      (params: any) => {
        this.workspaceId = params.params.workspaceId;
        this.store.dispatch(
          BaseAction.FetchAllGroupsForMyGroupsRequest({
            workspaceId: this.workspaceId,
          })
        );
        this.groupNavigationService.setWorkspaceId(this.workspaceId)
      }
    );

    // Fetch all direct conversations
    this.store.dispatch(BaseAction.fetchAllDirectConversationRequest());
  }

  ngOnDestroy() {
    this.paramsSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
    this.groupNavigationService.setGroupPreviousSelected(null);
  }
}

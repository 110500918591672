import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-closed-chat',
  templateUrl: './closed-chat.component.html',
  styleUrls: ['./closed-chat.component.css'],
})
export class ClosedChatComponent implements OnInit {
  @Output() openChatHandler: EventEmitter<any> = new EventEmitter();
  @Input() threadData: any
  @Input()workspaceId:any

  constructor() {
  }

  ngOnInit(): void {
  }

  openChat() {
    this.openChatHandler.emit();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThreadCountService {
  constructor() {}
  readUnreadEventSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  setReadOrUnreadCount(data) {
    this.readUnreadEventSubject.next(data);
  }
}

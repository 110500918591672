import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {
  AuthenticatedWrapperService
} from 'src/app/modules/shared/components/application-wrappers/authenticated-wrapper/service/authenticated-wrapper.service';
import * as BaseActions from '../../core/base.actions';
import {State} from '../../../../state/app.state';
import { CommonService } from '../../../shared/services/common/common.service';

@Component({
  selector: 'app-action-details-container',
  templateUrl: './action-details-container.component.html',
  styleUrls: ['./action-details-container.component.css'],
})
export class ActionDetailsContainerComponent implements OnInit, OnDestroy {
  activatedRouteSubscription: Subscription = new Subscription();
  workspaceId: any;
  phaseId: any;
  milestoneId: any;

  constructor(
    private store: Store<State>,
    private authWrapperService: AuthenticatedWrapperService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) {
    this.activatedRouteSubscription = this.activatedRoute.paramMap.subscribe(
      (params: any) => {
        this.workspaceId = params.params.workspaceId;
        this.phaseId = params.params.phaseId;
        this.milestoneId = params.params.milestoneId;
        this.fetchActionDetails(
          params.params.workspaceId,
          params.params.phaseId,
          params.params.milestoneId,
          params.params.actionId
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.authWrapperService.clearMultipleBreakCrumb();
    if (this.activatedRouteSubscription) {
      this.activatedRouteSubscription?.unsubscribe();
    }
  }

  fetchActionDetails(workspaceId, phaseId, milestoneId, actionId) {
    this.store.dispatch(
      BaseActions.FetchActionDetailsRequest({
        payload: {
          actionId: actionId,
          workspaceId: workspaceId,
          phaseId: phaseId,
          milestoneId: milestoneId,
        },
      })
    );
  }

  ngOnInit(): void {
  }
}

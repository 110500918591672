import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './modules/shared/services/auth-guard/auth-guard.service';

import {PageNotFoundComponent} from './modules/shared/components/page-not-found/page-not-found.component';
import {
  AuthenticatedWrapperContainerComponent
} from './modules/shared/components/application-wrappers/authenticated-wrapper/authenticated-wrapper-container/authenticated-wrapper-container.component';
import {DashboardContainerComponent} from './modules/base/dashboard-page/dashboard-container/dashboard-container.component';
import {
  ActionDetailsContainerComponent
} from './modules/base/action-details-page/action-details-container/action-details-container.component';
import {MilestonePageContainerComponent} from './modules/base/milestone-page/milestone-page-container/milestone-page-container.component';
import {CollectionContainerComponent} from './modules/base/collection-page/collection-container/collection-container.component';
import {DirectoryContainerComponent} from './modules/base/directory-page/directory-container/directory-container.component';
import {InformationContainerComponent} from './modules/base/information-page/information-container/information-container.component';
import {SettingsContainerComponent} from './modules/base/settings-page/settings-container/settings-container.component';
import {GroupsContainerComponent} from './modules/base/groups-page/groups-container/groups-container.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },

  {
    path: 'changemakers',
    component: AuthenticatedWrapperContainerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard/workspace/:workspaceId',
        component: DashboardContainerComponent,
      },
      {
        path: 'action-details/workspace/:workspaceId/phase/:phaseId/milestone/:milestoneId/actionstep/:actionId',
        component: ActionDetailsContainerComponent,
      },
      {
        path: 'milestone-details/workspace/:workspaceId/phase/:phaseId/milestone/:milestoneId',
        component: MilestonePageContainerComponent,
      },
      {
        path: 'collection/workspace/:workspaceId',
        component: CollectionContainerComponent,
      },
      {
        path: 'directory/workspace/:workspaceId',
        component: DirectoryContainerComponent,
      },
      {
        path: 'info/workspace/:workspaceId',
        component: InformationContainerComponent,
      },
      {
        path: 'settings/workspace/:workspaceId',
        component: SettingsContainerComponent,
      },
      // {
      //   path: 'groups/workspace/:workspaceId',
      //   component: GroupsContainerComponent,
      // },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ]
  },
  {
    path: 'workspace/:workspaceId',
    component: AuthenticatedWrapperContainerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'groups'
      },
      {
        path: 'groups',
        loadChildren: () =>
          import(`./modules/groups/groups.module`).then((m) => m.GroupsModule),
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: 'samples',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./modules/samples/samples.module').then((m) => m.SamplesModule),
  },

  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

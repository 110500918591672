import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-information-container',
  templateUrl: './information-container.component.html',
  styleUrls: ['./information-container.component.css']
})
export class InformationContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

import * as AuthenticationActions from './authentication.actions';
import { createReducer, on } from '@ngrx/store';

// State for this feature (User)
export interface AuthenticationState {
  profile: {
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: string;
    email: string;
    displayImage: string;
    organization: {
      id: any;
      name: string;
      role?: {
        id: number;
        name: string;
      };
    };
    currentOrganizationId: number;
    currentWorkspaceId: number;
    selectedWorkspace: any;
    organizations: any;
    availableWorkspaces: any[];
  };
  resetPasswordEmail: string;
  profileSasToken: any;
}

const initialState: AuthenticationState = {
  profile: {
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phoneNumber: '',
    displayImage: '',
    organization: {
      id: '',
      name: '',
    },
    currentOrganizationId: 0,
    currentWorkspaceId: 0,
    organizations: {},
    selectedWorkspace: {},
    availableWorkspaces: [],
  },
  resetPasswordEmail: '',
  profileSasToken: {},
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(AuthenticationActions.ClearState, (state) => initialState),

  // LOGIN
  on(AuthenticationActions.LoginRequest, (state) => state),
  on(AuthenticationActions.LoginSuccess, (state, props) => {
    return ({
      ...state,
      profile: props.profile,
    });
  }),
  on(AuthenticationActions.LoginFailure, (state) => state),

  // LOGOUT
  on(AuthenticationActions.LogoutRequest, (state) => state),
  on(AuthenticationActions.LogoutSuccess, (state) => initialState),
  on(AuthenticationActions.LogoutFailure, (state) => state),

  // CREATE ACCOUNT
  on(AuthenticationActions.CreateAccountRequest, (state) => state),
  on(AuthenticationActions.CreateAccountSuccess, (state, props) => ({
    ...state,
    profile: props.profile,
  })),
  on(AuthenticationActions.CreateAccountFailure, (state) => state),

  // UPDATE ACCOUNT
  on(AuthenticationActions.UpdateAccountRequest, (state) => state),
  on(AuthenticationActions.UpdateAccountSuccess, (state, props) => ({
    ...state,
    profile: props.profile,
  })),
  on(AuthenticationActions.UpdateAccountFailure, (state) => state),

  // FORGOT PASSWORD
  on(AuthenticationActions.ForgotPasswordRequest, (state, props) => ({
    ...state,
    resetPasswordEmail: props.email,
  })),
  on(AuthenticationActions.ForgotPasswordSuccess, (state) => state),
  on(AuthenticationActions.ForgotPasswordFailure, (state) => state),

  // RESET PASSWORD
  on(AuthenticationActions.ResetPasswordRequest, (state) => state),
  on(AuthenticationActions.ResetPasswordSuccess, (state) => state),
  on(AuthenticationActions.ResetPasswordFailure, (state) => state),

  // UPDATE PASSWORD
  on(AuthenticationActions.UpdatePasswordRequest, (state) => state),
  on(AuthenticationActions.UpdatePasswordSuccess, (state) => state),
  on(AuthenticationActions.UpdatePasswordFailure, (state) => state),
  on(AuthenticationActions.UpdateProfileDataSuccess, (state, props) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        ...props.data,
      },
    };
  }),
  on(AuthenticationActions.ChangeSelectedWorkspace, (state, props) => {
    return {
      ...state,
      profile: { ...state.profile, selectedWorkspace: props.workspace },
    };
  }),
  on(
    AuthenticationActions.FetchSasTokenForProfileImageSuccess,
    (state, props) => {
      return { ...state, profileSasToken: { ...props.data } };
    },
  ),
);

export default {
  'application/pdf': 'PDF',  // PDF
  'image/jpeg': 'JPEG', // JPG / JPEG
  'image/png': 'PNG',  // PNG
  'application/msword': 'WORD DOCUMENT', // DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'WORD DOCUMENT',  // DOCX
  'application/vnd.ms-excel': 'MS EXCEL', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX', // XLSX
  'application/vnd.ms-powerpoint': 'PowerPoint',  // PPT
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX', // PPTX
  'application/zip': 'ZIP',  // ZIP
  'application/x-zip-compressed': 'ZIP',  // ZIP
  'multipart/x-zip': 'ZIP',  // ZIP
  'application/octet-stream': 'ZIP',  // ZIP / RAR
  'application/x-rar-compressed': 'RAR',  // RAR
  'image/svg+xml': 'SVG', // SVG
  'text/csv': 'CSV', // CSV
}

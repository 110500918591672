/* NgRx */
import { createAction, props } from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[authentication] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** LOGIN ***********/
const LOGIN_REQUEST = '[authentication] login requested';
export const LoginRequest = createAction(LOGIN_REQUEST, props<any>());

const LOGIN_SUCCESS = '[authentication] login successful';
export const LoginSuccess = createAction(LOGIN_SUCCESS, props<any>());

const LOGIN_FAILURE = '[authentication] login failure';
export const LoginFailure = createAction(LOGIN_FAILURE, props<any>());

/******** LOGOUT ***********/
const LOGOUT_REQUEST = '[authentication] logout requested';
export const LogoutRequest = createAction(LOGOUT_REQUEST, props<any>());

const LOGOUT_SUCCESS = '[authentication] logout successful';
export const LogoutSuccess = createAction(LOGOUT_SUCCESS, props<any>());

const LOGOUT_FAILURE = '[authentication] logout failure';
export const LogoutFailure = createAction(LOGOUT_FAILURE, props<any>());

/******** CREATE ACCOUNT ***********/
const CREATE_ACCOUNT_REQUEST = '[authentication] create account requested';
export const CreateAccountRequest = createAction(
  CREATE_ACCOUNT_REQUEST,
  props<any>()
);

const CREATE_ACCOUNT_SUCCESS = '[authentication] create account successful';
export const CreateAccountSuccess = createAction(
  CREATE_ACCOUNT_SUCCESS,
  props<any>()
);

const CREATE_ACCOUNT_FAILURE = '[authentication] create account failure';
export const CreateAccountFailure = createAction(
  CREATE_ACCOUNT_FAILURE,
  props<any>()
);

/******** UPDATE ACCOUNT ***********/
const UPDATE_ACCOUNT_REQUEST = '[authentication] update account requested';
export const UpdateAccountRequest = createAction(
  UPDATE_ACCOUNT_REQUEST,
  props<any>()
);

const UPDATE_ACCOUNT_SUCCESS = '[authentication] update account successful';
export const UpdateAccountSuccess = createAction(
  UPDATE_ACCOUNT_SUCCESS,
  props<any>()
);

const UPDATE_ACCOUNT_FAILURE = '[authentication] update account failure';
export const UpdateAccountFailure = createAction(
  UPDATE_ACCOUNT_FAILURE,
  props<any>()
);

/******** FORGOT PASSWORD ***********/
const FORGOT_PASSWORD_REQUEST = '[authentication] forgot password requested';
export const ForgotPasswordRequest = createAction(
  FORGOT_PASSWORD_REQUEST,
  props<any>()
);

const FORGOT_PASSWORD_SUCCESS = '[authentication] forgot password successful';
export const ForgotPasswordSuccess = createAction(
  FORGOT_PASSWORD_SUCCESS,
  props<any>()
);

const FORGOT_PASSWORD_FAILURE = '[authentication] forgot password failure';
export const ForgotPasswordFailure = createAction(
  FORGOT_PASSWORD_FAILURE,
  props<any>()
);

/******** RESEND RESET PASSWORD REQUEST EMAIL ***********/
const RESEND_FORGOT_PASSWORD_EMAIL_REQUEST =
  '[authentication] resend forgot password email requested';
export const ResendForgotPasswordEmailRequest = createAction(
  RESEND_FORGOT_PASSWORD_EMAIL_REQUEST,
  props<any>()
);

const RESEND_FORGOT_PASSWORD_EMAIL_SUCCESS =
  '[authentication] resend forgot password email successful';
export const ResendForgotPasswordEmailSuccess = createAction(
  RESEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  props<any>()
);

const RESEND_FORGOT_PASSWORD_EMAIL_FAILURE =
  '[authentication] resend forgot password email failure';
export const ResendForgotPasswordEmailFailure = createAction(
  RESEND_FORGOT_PASSWORD_EMAIL_FAILURE,
  props<any>()
);

/******** RESET PASSWORD ***********/
const RESET_PASSWORD_REQUEST = '[authentication] reset password requested';
export const ResetPasswordRequest = createAction(
  RESET_PASSWORD_REQUEST,
  props<any>()
);

const RESET_PASSWORD_SUCCESS = '[authentication] reset password successful';
export const ResetPasswordSuccess = createAction(
  RESET_PASSWORD_SUCCESS,
  props<any>()
);

const RESET_PASSWORD_FAILURE = '[authentication] reset password failure';
export const ResetPasswordFailure = createAction(
  RESET_PASSWORD_FAILURE,
  props<any>()
);

/******** UPDATE PASSWORD ***********/
const UPDATE_PASSWORD_REQUEST = '[authentication] update password requested';
export const UpdatePasswordRequest = createAction(
  UPDATE_PASSWORD_REQUEST,
  props<any>()
);

const UPDATE_PASSWORD_SUCCESS = '[authentication] update password successful';
export const UpdatePasswordSuccess = createAction(
  UPDATE_PASSWORD_SUCCESS,
  props<any>()
);

const UPDATE_PASSWORD_FAILURE = '[authentication] update password failure';
export const UpdatePasswordFailure = createAction(
  UPDATE_PASSWORD_FAILURE,
  props<any>()
);

const UPDATE_PROFILE_REQUEST = '[authentication] updated profile request';
export const UpdateProfileDataRequest = createAction(
  UPDATE_PROFILE_REQUEST,
  props<any>()
);
const UPDATE_PROFILE_SUCCESS = '[authentication] updated profile success';
export const UpdateProfileDataSuccess = createAction(
  UPDATE_PROFILE_SUCCESS,
  props<any>()
);
const UPDATE_PROFILE_FAIL = '[authentication] updated profile fail';
export const UpdateProfileDataFail = createAction(
  UPDATE_PROFILE_FAIL,
  props<any>()
);

const FETCH_SAS_TOKEN_FOR_PROFILE_REQUEST =
  '[authentication] fetch sas token for profile request';
export const FetchSasTokenForProfileImageRequest = createAction(
  FETCH_SAS_TOKEN_FOR_PROFILE_REQUEST
);
const FETCH_SAS_TOKEN_FOR_PROFILE_SUCCESS =
  '[authentication] fetch sas token for profile success';
export const FetchSasTokenForProfileImageSuccess = createAction(
  FETCH_SAS_TOKEN_FOR_PROFILE_SUCCESS,
  props<any>()
);
const FETCH_SAS_TOKEN_FOR_PROFILE_FAIL =
  '[authentication] fetch sas token for profile fail';
export const FetchSasTokenForProfileImageFail = createAction(
  FETCH_SAS_TOKEN_FOR_PROFILE_FAIL,
  props<any>()
);
const CHANGE_PASSWORD_FOR_THE_EDIT_PROFILE_REQUEST =
  '[authentication] change password for the edit profile request';
export const ChangePasswordForEditProfileRequest = createAction(
  CHANGE_PASSWORD_FOR_THE_EDIT_PROFILE_REQUEST,
  props<any>()
);

const CHANGE_SELECTED_WORKSPACE = '[authentication] change selected workspace';
export const ChangeSelectedWorkspace = createAction(
  CHANGE_SELECTED_WORKSPACE,
  props<any>()
);

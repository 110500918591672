import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CommonService} from '../../services/common/common.service';
import * as BaseSelectors from '../../../base/core/base.selectors';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;
  detailConfigSubscription: Subscription;
  orgId: any;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.commonService.isAuthenticated();
    this.detailConfigSubscription = this.store
      .select(BaseSelectors.getDetailsConfig)
      .subscribe((detailConfig) => {
        this.orgId = detailConfig.selectedWorkspaceId;
      });
  }

  navigateToDashboard() {
    if (this.isLoggedIn === true) {
      this.router.navigate([`/changemakers/dashboard/workspace/${this.orgId}`]);
    } else {
      this.router.navigate(['/login']);
    }
  }

  buttonLabel() {
    return this.isLoggedIn === true ? 'Back to Dashboard' : 'Back to Login';
  }

  ngOnDestroy() {
    this.detailConfigSubscription.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/modules/shared/services/common/common.service';
import * as AuthenticationSelector from '../../../core/authentication.selectors';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-login-page-screen',
  templateUrl: './login-page-screen.component.html',
  styleUrls: ['./login-page-screen.component.css'],
})
export class LoginPageScreenComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private router: Router,
    private  store:Store
  ) {
    const token = this.commonService.getAuthenticationToken();
    if (token) {
      this.store.select(AuthenticationSelector.getSelectedWorkspace).subscribe(data=>{
        if (data) {
          this.router.navigate(
            ['/changemakers/dashboard/workspace/' + data.id],
            {replaceUrl: true}
          );
        } else {
          this.router.navigate(
            ['/workspace'],
            { replaceUrl: true }
          );
        }
      }).unsubscribe();
    }
  }

  ngOnInit() {}
}

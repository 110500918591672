/* NgRx */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaseState } from './base.reducer';

// Selector functions
const BaseState = createFeatureSelector<BaseState>('base');
export const getActionsStepContent = createSelector(
  BaseState,
  (state) => state.actionStepPage
);

export const getActionsSteps = createSelector(
  BaseState,
  (state) => state.milestonePage.actionSteps
);

export const getDetailsConfig = createSelector(BaseState, (state) => {
  return state.detailConfig;
});
export const getMilestoneDetails = createSelector(
  BaseState,
  (state) => state.milestonePage.milestoneDetails
);
export const getMilestoneActionStepsNextPageExist = createSelector(
  BaseState,
  (state) => state.milestonePage.hasMore
);

export const getActionStepsDetails = createSelector(BaseState, (state) => {
  return {
    next: state.actionStepPage.nextActionStep,
    pervious: state.actionStepPage.previousActionStep,
  };
});

export const getAllActionSteps = createSelector(
  BaseState,
  (state) => state.milestonePage.actionSteps
);

export const getCollectionPageDetails = createSelector(
  BaseState,
  (state) => state.collectionPage
);

export const getAllPhases = createSelector(
  BaseState,
  (state) => state.collectionPage.phase
);

export const getAllPhasesMilestone = createSelector(
  BaseState,
  (state) => state.collectionPage.milestones
);

export const getConfigDetails = createSelector(
  BaseState,
  (state) => state.detailConfig
);

export const PhasesMilestoneHasMore = createSelector(
  BaseState,
  (state) => state.collectionPage.milestones.hasMore
);

export const getDashboardPhases = createSelector(
  BaseState,
  (state) => state.dashboardPage.phase
);

export const getDashboardMilestone = createSelector(
  BaseState,
  (state) => state.dashboardPage.milestone
);
export const getPhasesForMilestonePage = createSelector(
  BaseState,
  (state) => state.milestonePage.phase
);

export const getSasToken = createSelector(BaseState, (state) => state.sasToken);

export const getAllTeamRoles = createSelector(
  BaseState,
  (state) => state.collectionPage.teamRoles
);
export const getCalenderDetails = createSelector(
  BaseState,
  (state) => state.dashboardPage.calender
);
export const getPreSelectedPhase = createSelector(
  BaseState,
  (state) => state.collectionPage.phase.preSelected
);

export const getDirectoryTeams = createSelector(
  BaseState,
  (state) => state.directoryPage.team
);
export const getDirectoryCohort = createSelector(
  BaseState,
  (state) => state.directoryPage.cohort
);
export const getDirectoryTeamRoles = createSelector(
  BaseState,
  (state) => state.directoryPage.teamRoles
);
export const getDirectoryTeamsOption = createSelector(
  BaseState,
  (state) => state.directoryPage.teams
);
export const getAllDirectMessages = createSelector(
  BaseState,
  (state) => state.groupPage.directMessage
);

export const getAllMessages = createSelector(
  BaseState,
  (state) => state.groupPage.message
);
export const getAllGroupsConversation = createSelector(
  BaseState,
  (state) => state.groupPage.groupsMessage
);
export const getAllTeamsConversationForWorkspaceManager = createSelector(
  BaseState,
  (state) => state.groupPage.allTeams
);
export const getMyTeamsConversation = createSelector(
  BaseState,
  (state) => state.groupPage.myTeam
);
export const getMyCohortConversation = createSelector(
  BaseState,
  (state) => state.groupPage.myCohort
);
export const getAllThreadsForAConversation = createSelector(
  BaseState,
  (state) => state.groupPage.threads
);
export const getAllSearchedUsersResult = createSelector(
  BaseState,
  (state) => state.groupPage.searchUsers
);
export const getUserProfileForDirectoryPage = createSelector(
  BaseState,
  (state) => state.directoryPage.userProfileData
);

export const getUserProfileLoadingState = createSelector(
  BaseState,
  (state) => state.directoryPage.userProfileLoadingState
);

export const getChatSasToken = createSelector(
  BaseState,
  (state) => state.chatSasToken
);

export const fetchAllMilestoneRelatedConversationGroups = createSelector(
  BaseState,
  (state) => state.milestoneConversation
);
export const getAllConversationThreadForTheMilestone = createSelector(
  BaseState,
  (state) => state.milestoneConversation.thread
);
export const getAllConversationMessagesRelatedToMilestone = createSelector(
  BaseState,
  (state) => state.milestoneConversation.conversation
);
export const getCurrentUserProfile = createSelector(
  BaseState,
  (state) => state.userProfile
);
export const getAllMentionOptions = createSelector(
  BaseState,
  (state) => state.groupPage.mentionsOptions
);
export const getIsCollectionPageMilestoneLoaded = createSelector(
  BaseState,
  (state) => state.collectionPage.milestoneLoaded
);
export const getAllSideNavUnreadMessageCount = createSelector(
  BaseState,
  (state) => state.workspaceUnreadMessages
);
export const getCurrentSelectedFilterByRoleForCollectionPage = createSelector(
  BaseState,
  (state) => state.collectionPage.selectedFilterRole
);

export const getAllMilestoneDeliverable = createSelector(
  BaseState,
  (state) => state.milestonePage.milestoneDetails.deliverables
);

export const getAllFilesRelatedToMilestone=createSelector(
  BaseState,
  (state)=>state.milestonePage.milestoneFiles
)
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ResetPasswordService} from '../../services/reset-password.service';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as AuthenticationSelectors from '../../../../core/authentication.selectors';
import * as fromRoot from '../../../../../../state/app.state';

@Component({
  selector: 'app-reset-password-email-sent',
  templateUrl: './reset-password-email-sent.component.html',
  styleUrls: ['./reset-password-email-sent.component.css']
})
export class ResetPasswordEmailSentComponent implements OnInit, OnDestroy {
  resetPasswordEmailSubscription: Subscription
  resetPasswordEmail: string;

  constructor(
    private store: Store<fromRoot.State>,
    private resetPasswordService: ResetPasswordService
  ) {
  }

  ngOnInit(): void {
    this.resetPasswordEmailSubscription = this.store.pipe(select(AuthenticationSelectors.getResetPasswordEmail))
      .subscribe((data) => {
        this.resetPasswordEmail = data;
      })
  }

  resendEmail() {
    this.resetPasswordService.resendEmail.emit({email: this.resetPasswordEmail})
  }

  ngOnDestroy() {
    this.resetPasswordEmailSubscription?.unsubscribe();
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardPageService {
  phasesCurrentPage: BehaviorSubject<number> = new BehaviorSubject(1);
  dashBoardMilestoneCurrentPage: BehaviorSubject<number> =
    new BehaviorSubject(1);

  constructor() {
  }

  setCurrentPage(pageNumber) {
    this.phasesCurrentPage.next(pageNumber);
  }

  setDashboardMilestoneCurrentPage(page) {
    this.dashBoardMilestoneCurrentPage.next(page);
  }
}

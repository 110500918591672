import {DatePipe} from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as BaseActions from '../../core/base.actions';
import {ActivatedRoute} from '@angular/router';
import { CommonService } from '../../../shared/services/common/common.service';
@Component({
  selector: 'app-dashboard-container',
  templateUrl: './dashboard-container.component.html',
  styleUrls: ['./dashboard-container.component.css'],
})
export class DashboardContainerComponent implements OnInit, OnDestroy {
  profileSubscription: Subscription;
  workspaceId;
  activatedRouteSubscription: Subscription;

  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    const date = this.datePipe.transform(new Date(), 'yyyyMMdd');

    this.activatedRouteSubscription = this.activatedRoute.paramMap.subscribe(
      (params: any) => {
        if (params.params.workspaceId) {
          this.workspaceId = params.params.workspaceId;
          this.fetchDashboardWeekData(date, params.params.workspaceId);
          this.store.dispatch(BaseActions.FetchMilestoneDashboardPageRequest({organizationId: this.workspaceId}));
          this.fetchPhases(params.params.workspaceId)
        }
      }
    );
  }

  fetchDashboardWeekData(date, workspace) {
    const payload = {
      date,
      workspace,
    };
    this.store.dispatch(BaseActions.fetchDashboardWeekDataRequest(payload));
  }

  fetchPhases(workspaceId) {
    this.store.dispatch(BaseActions.FetchDashboardPhasesRequest({organizationId: workspaceId}));
  }

  fetchMilestones() {
  }

  ngOnDestroy() {
    this.activatedRouteSubscription?.unsubscribe();
  }
}

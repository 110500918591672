import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['../styles.css', './primary-button.component.css',]
})
export class PrimaryButtonComponent implements OnInit {
  @Input() text: string;
  @Input() isDisabled: boolean;
  @Output() handleClick = new EventEmitter();
  @Input() type="button"

  constructor() {
  }

  ngOnInit(): void {
  }

}

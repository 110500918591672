import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginPageService } from '../services/login-page.service';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../../../state/app.state';
import * as AuthenticationActions from '../../../core/authentication.actions';
import { Store } from '@ngrx/store';
import { LocationStrategy } from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-login-page-container',
  templateUrl: './login-page-container.component.html',
  styleUrls: ['./login-page-container.component.css'],
})
export class LoginPageContainerComponent implements OnInit, OnDestroy {
  loginSubscription: Subscription;

  redirectUrl: string;

  queryParamsSubscription: Subscription = this.route.queryParams.subscribe(params => {
    if (params.redirectUrl) {
      this.redirectUrl = params.redirectUrl;
    }
  })

  constructor(
    private store: Store<fromRoot.State>,
    private loginServices: LoginPageService,
    private location: LocationStrategy,
    private route: ActivatedRoute
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.loginSubscription = this.loginServices.login.subscribe((payload) => {
      this.store.dispatch(AuthenticationActions.LoginRequest({
          ...payload,
          ...(this.redirectUrl ? {redirectUrl: this.redirectUrl} : {})
      }));
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.loginSubscription?.unsubscribe();
  }
}

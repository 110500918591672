import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'chatDateFormat'
})
export class ChatDateFormatPipe implements PipeTransform {
  transform(value) {
    value = new Date(value);
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const formattedDate = (new DatePipe('en-US')).transform(value, 'MMM dd')

    if (
      value.getFullYear() === today.getFullYear() &&
      value.getMonth() === today.getMonth() &&
      value.getDate() === today.getDate()
    )
      return 'Today, ' + formattedDate;
    else if (
      value.getFullYear() === yesterday.getFullYear() &&
      value.getMonth() === yesterday.getMonth() &&
      value.getDate() === yesterday.getDate()
    )
      return 'Yesterday, ' + formattedDate;
    else {
      return (new DatePipe('en-US')).transform(value, 'MMM dd, YYYY');
    }
  }
}

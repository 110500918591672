import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-phase-name-chip',
  templateUrl: './phase-name-chip.component.html',
  styleUrls: ['./phase-name-chip.component.css'],
})
export class PhaseNameChipComponent implements OnInit {
  @Input() phaseName: string;
  @Input() workspaceId: string;
  @Input() phaseId: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigateToPhase() {
    const params = {phase: this.phaseId};
    this.router.navigate(
      [`/changemakers/collection/workspace/${this.workspaceId}`],
      {queryParams: params}
    );
  }
}

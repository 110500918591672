import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseService } from './base.service';
import * as fromRoot from '../../../state/app.state';
import { CommonService } from '../../shared/services/common/common.service';
import * as BaseActions from './base.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import * as BaseSelector from '../core/base.selectors';
import { AuthenticatedWrapperService } from '../../shared/components/application-wrappers/authenticated-wrapper/service/authenticated-wrapper.service';
import { MilestonePageService } from '../milestone-page/service/milestonePage.service';
import * as AuthSelector from '../../authentication/core/authentication.selectors';
import { CollectionPageService } from '../collection-page/service/collectionPage.service';
import * as AuthenticationActions from '../../authentication/core/authentication.actions';
import { AuthenticationService } from '../../authentication/core/authentication.service';
import { DashboardPageService } from '../dashboard-page/services/dashboard.service';
import { DirectoryService } from '../directory-page/service/directory.service';
import { MessageService } from '../groups-page/service/message.service';
import { MilestoneMessageService } from '../milestone-page/service/milestone-message.service';
import { MilestoneConversationThreadService } from '../milestone-page/service/milestone-conversation-thread.service';
import { GroupsNavigationService } from '../groups-page/service/groups-navigation.service';
import { MilestoneFilesService } from '../milestone-page/service/milestone-files.service';
import { MilestoneDocuments } from './base.reducer';
import { logging } from 'protractor';
import { getSelectedWorkspace } from '../../authentication/core/authentication.selectors';

@Injectable()
export class BaseEffects {
  FetchActionDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchActionDetailsRequest),
      map((actions: any) => {
        this.commonService.startLoading();

        return actions.payload;
      }),
      mergeMap((state: any) => {
        return this.baseService
          .fetchActionDetails(
            state.workspaceId,
            state.phaseId,
            state.milestoneId,
            state.actionId
          )
          .pipe(
            map((response) => {
              this.authWrapperService.setMultipleBreadCrumbs([
                {
                  title: response?.data?.phaseName,
                  path: `/changemakers/collection/workspace/${state.workspaceId}`,
                  queryParams: { phase: state.phaseId },
                },
                {
                  title: response?.data?.milestoneName,
                  path: `/changemakers//milestone-details/workspace/${state.workspaceId}/phase/${state.phaseId}/milestone/${state.milestoneId}`,
                },
              ]);
              return BaseActions.FetchActionDetailsSuccess(response.data);
            }),
            catchError((error) => {
              return of(BaseActions.FetchActionDetailsFailure(error.message));
            }),
            tap((action: any) => {
              if (action.type === BaseActions.FetchActionDetailsSuccess.type) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type === BaseActions.FetchActionDetailsFailure.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            })
          );
      })
    )
  );

  /* needs to be rewritten */
  FetchMilestoneDetails$ = createEffect(() => {
    return this.actions.pipe(
      ofType(BaseActions.FetchMilestonesRequest),
      map((actions) => {
        this.commonService.startLoading();
        return actions;
      }),
      mergeMap((actions: any) => {
        const teamRoles = actions.teamRoles.join(',');

        return this.baseService
          .fetchMilestoneDetails(
            actions.organizationId,
            actions.phaseId,
            actions.milestoneId,
            actions.page ? actions.page : 1,
            teamRoles
          )
          .pipe(
            map((response) => {
              return BaseActions.FetchMilestonesSuccess(response);
            }),
            catchError((error) => {
              return of(BaseActions.FetchMilestoneFailure(error.message));
            }),
            tap((action: any) => {
              if (action.type === BaseActions.FetchMilestonesSuccess.type) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type === BaseActions.FetchMilestoneFailure.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            })
          );
      })
    );
  });

  FetchMilestoneActionstepsNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchMilestoneActionStepsNextPageRequest),
      map((actions) => {
        return actions;
      }),
      mergeMap((action: any) => {
        return this.store
          .select(BaseSelector.getMilestoneActionStepsNextPageExist)
          .pipe(map((hasNext) => ({ hasNext, action })));
      }),
      mergeMap((hasMore: any) => {
        if (hasMore.hasNext) {
          const teamRoles = hasMore.action.teamRoles.join(',');
          this.commonService.startLoading();
          const pageNo = this.milestonePageService.pageSubject.value + 1;
          return this.baseService
            .fetchMilestoneActionStepsNextPage(
              hasMore.action.organizationId,
              hasMore.action.phaseId,
              hasMore.action.milestoneId,
              pageNo,
              teamRoles
            )
            .pipe(
              map((response) => {
                this.milestonePageService.setCurrentPageNumber(pageNo);
                return BaseActions.FetchMilestoneActionStepsNextPageSuccess(
                  response
                );
              }),
              catchError((error) => {
                return of(
                  BaseActions.FetchMilestoneActionStepsNextPageFailure(
                    error.message
                  )
                );
              })
            );
        }

        return of(
          BaseActions.FetchMilestoneActionStepsNextPageFailure('No Page Left')
        );
      }),
      catchError((error) => {
        return of(
          BaseActions.FetchMilestoneActionStepsNextPageFailure(error.message)
        );
      }),
      tap((action: any) => {
        if (
          action.type ===
          BaseActions.FetchMilestoneActionStepsNextPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.FetchMilestoneActionStepsNextPageFailure.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );

  FetchCollectionPhaseDetails$ = createEffect(() => {
    return this.actions.pipe(
      ofType(BaseActions.FetchPhasesRequest),
      mergeMap((action: any) => {
        this.commonService.startLoading();
        return this.baseService.fetchAllPhases(action.organizationId).pipe(
          map((response) => {
            const updatedPhases = response.data.phases.map((phase, index) => {
              if (phase.id == action?.phase) {
                return {
                  ...phase,
                  selected: true,
                };
              } else {
                return {
                  ...phase,
                  selected: false,
                };
              }
            });

            const updatedResponse = {
              ...response,
              data: {
                ...response.data,
                phases: updatedPhases,
                preselect: response.data.preselect,
              },
            };

            return BaseActions.FetchPhasesSuccess(updatedResponse);
          }),
          catchError((error) => {
            return of(BaseActions.FetchPhasesFailure(error.message));
          }),
          tap((action: any) => {
            if (action.type === BaseActions.FetchPhasesSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === BaseActions.FetchPhasesFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        );
      })
    );
  });

  FetchPhaseMilestones$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchPhasesMilestonesRequest),

      map((actions) => {
        this.commonService.startLoading();
        return actions;
      }),
      mergeMap((actions) => {
        return this.baseService
          .fetchPhasesMilestone(
            actions.organizationId,
            actions.phase,
            1,
            actions.teamRoles
          )
          .pipe(
            map((response) => {
              this.collectionPageService.setCurrentMilestonePage(1);
              return BaseActions.FetchPhasesMilestonesSuccess({
                ...response,
                organizationId: actions.organizationId,
                phaseId: actions.phase,
                teamRoles: actions.teamRoles,
              });
            }),
            catchError((error) => {
              return of(BaseActions.FetchPhasesMilestonesFail(error.message));
            }),
            tap((action: any) => {
              if (
                action.type === BaseActions.FetchPhasesMilestonesSuccess.type
              ) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type === BaseActions.FetchPhasesMilestonesFail.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            })
          );
      })
    )
  );

  fetchPhaseMilestoneActionSteps$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BaseActions.FetchPhasesMilestonesSuccess),
        mergeMap((actions) => {
          if (actions.data.milestones.length > 0) {
            return actions?.data?.milestones?.map((mileStone) => {
              if (mileStone) {
                /*   teamRoles: */
                const teamRoles = actions.teamRoles.join(',');

                this.store.dispatch(
                  BaseActions.FetchPhasesActionStepsRequest({
                    milestoneId: mileStone.id,
                    phaseId: actions.phaseId,
                    orgId: actions.organizationId,
                    teamRoles: teamRoles,
                  })
                );
              }
            });
          } else {
            return of([]);
          }
        }),
        catchError((error) => {
          return BaseActions.FetchPhasesActionStepsFail(error);
        })
      ),
    { dispatch: false }
  );

  fetchActionsStepsAccordingToPhases$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchPhasesActionStepsRequest),

      map((actions) => {
        return actions;
      }),
      mergeMap((action) => {
        const initialPageNumber: number = 1;
        return this.baseService
          .fetchActionsStepsAccordingToPhases(
            action.orgId,
            action.phaseId,
            action.milestoneId,
            initialPageNumber,
            action.teamRoles
          )
          .pipe(
            map((response) => {
              return BaseActions.FetchPhasesActionStepsSuccess({
                ...response,
                milestoneId: action.milestoneId,
              });
            }),
            catchError((error) => {
              return BaseActions.FetchPhasesActionStepsFail(error);
            }),
            tap((action: any) => {
              if (
                action.type === BaseActions.FetchPhasesActionStepsSuccess.type
              ) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type === BaseActions.FetchPhasesActionStepsFail.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
            })
          );
      })
    )
  );

  FetchPhaseNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchPhasesNextPageRequest),
      map((action) => {
        this.commonService.startLoading();
        const { type, ...payload } = action;
        const nextPage = this.collectionPageService.phasesCurrentPage.value + 1;
        return { ...payload, nextPage };
      }),
      mergeMap((payload) =>
        this.baseService
          .fetchAllNextPhases(payload.organizationId, payload.nextPage)
          .pipe(
            map((response) => {
              this.collectionPageService.setCurrentPhasePage(payload.nextPage);
              return BaseActions.FetchPhasesNextPageSuccess(response);
            }),
            catchError((error) => BaseActions.FetchPhasesNextPageFail(error)),
            tap((action: any) => {
              if (action.type === BaseActions.FetchPhasesNextPageSuccess.type) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type === BaseActions.FetchPhasesNextPageFail.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            })
          )
      )
    )
  );

  FetchPhaseMilestoneNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchPhasesMilestoneNextPageRequest),
      mergeMap((actions) => {
        const currentPageMilestone =
          this.collectionPageService.mileStoneCurrentPage.value + 1;
        return this.baseService
          .fetchPhasesMilestone(
            actions.organizationId,
            actions.phase,
            currentPageMilestone,
            actions.teamRoles
          )
          .pipe(
            map((response) => {
              this.collectionPageService.setCurrentMilestonePage(
                currentPageMilestone
              );
              return BaseActions.FetchPhasesMilestoneNextPageSuccess({
                ...response,
                phaseId: actions.phase,
                organizationId: actions.organizationId,
              });
            }),
            catchError((error) => {
              return of(
                BaseActions.FetchPhasesMilestoneNextPageFail(error.message)
              );
            }),
            tap((action: any) => {
              if (
                action.type ===
                BaseActions.FetchPhasesMilestoneNextPageSuccess.type
              ) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type ===
                BaseActions.FetchPhasesMilestoneNextPageFail.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
            })
          );
      })
    )
  );

  fetchPhaseMilestoneNextPageActionSteps$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BaseActions.FetchPhasesMilestoneNextPageSuccess),
        map((actions) => {
          return {
            phaseId: actions.phaseId,
            orgId: actions.organizationId,
            milestones: actions.data.milestones,
            type: actions.type,
          };
        }),
        mergeMap((actions) => {
          if (actions.milestones.length > 0) {
            return actions.milestones.map((mileStone) => {
              if (mileStone) {
                this.store.dispatch(
                  BaseActions.FetchPhasesActionStepsRequest({
                    milestoneId: mileStone.id,
                    phaseId: actions.phaseId,
                    orgId: actions.orgId,
                  })
                );
              }
            });
          }
        }),
        catchError((error) => {
          return BaseActions.FetchPhasesActionStepsFail(error);
        })
      ),
    { dispatch: false }
  );

  FetchDashboardPhaseDetails$ = createEffect(() => {
    return this.actions.pipe(
      ofType(BaseActions.FetchDashboardPhasesRequest),
      mergeMap((action: any) => {
        this.commonService.startLoading();
        return this.baseService.fetchAllPhases(action.organizationId).pipe(
          map((response) => {
            const updatedPhases = response.data.phases.map((phase, index) => ({
              ...phase,
              active: index === 0,
            }));

            const updatedResponse = {
              ...response,
              data: {
                ...response.data,
                phases: updatedPhases,
              },
            };

            return BaseActions.FetchDashboardPhasesSuccess(updatedResponse);
          }),
          catchError((error) => {
            return of(BaseActions.FetchDashboardPhasesFail(error.message));
          }),
          tap((action: any) => {
            if (action.type === BaseActions.FetchDashboardPhasesSuccess.type) {
              // Code to execute on API Success Action dispatch

              this.dashboardService.setCurrentPage(1);
            } else if (
              action.type === BaseActions.FetchDashboardPhasesFail.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        );
      })
    );
  });
  Logout$ = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthenticationActions.LogoutRequest),

      map((action) => {
        return action;
      }),
      mergeMap((action) => {
        this.commonService.startLoading();
        return this.authenticationService.logout().pipe(
          map((response) => {
            return AuthenticationActions.LogoutSuccess(response);
          }),
          catchError((error) => {
            return AuthenticationActions.LogoutFailure(error);
          })
        );
      }),
      tap((action: any) => {
        if (action.type === AuthenticationActions.LogoutSuccess.type) {
          // Code to execute on API Success Action dispatch

          this.commonService.logout();
        } else if (action.type === AuthenticationActions.LogoutFailure.type) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    );
  });
  fetchNextActionStepsCollectionPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchActionStepCollectionPageRequest),

      mergeMap((action) => {
        const nextPage = action.currentPage + 1;
        const teamRoles = action.teamRoles.join(',');

        return this.baseService
          .fetchActionsStepsAccordingToPhases(
            action.organizationId,
            action.phaseId,
            action.milestoneId,
            nextPage,
            teamRoles
          )
          .pipe(
            map(
              (response) => {
                return BaseActions.FetchActionStepCollectionPageSuccess({
                  ...response,
                  ...action,
                });
              },
              catchError((error) =>
                BaseActions.FetchActionStepCollectionPageFail(error)
              )
            )
          );
      }),
      tap((action: any) => {
        if (
          action.type === BaseActions.FetchActionStepCollectionPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchActionStepCollectionPageFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );
  FetchDashboardPhaseNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDashboardPhasesNextPageRequest),

      map((actions) => {
        this.commonService.startLoading();
        const nextPage = this.dashboardService.phasesCurrentPage.value + 1;

        return { ...actions, nextPage };
      }),
      mergeMap((action) =>
        this.baseService
          .fetchAllNextPhases(action.organizationId, action.nextPage)
          .pipe(
            map((response) => {
              this.dashboardService.setCurrentPage(action.nextPage);
              return BaseActions.FetchDashboardPhasesNextPageSuccess(response);
            }),
            catchError((error) =>
              BaseActions.FetchDashboardPhasesNextPageFail(error)
            ),
            tap((action: any) => {
              if (
                action.type ===
                BaseActions.FetchDashboardPhasesNextPageSuccess.type
              ) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type ===
                BaseActions.FetchDashboardPhasesNextPageFail.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            })
          )
      )
    )
  );

  FetchDashBoardMileStone$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchMilestoneDashboardPageRequest),
      mergeMap((action: any) => {
        this.dashboardService.setDashboardMilestoneCurrentPage(1);
        return this.baseService
          .fetchDashboardMileStones(action.organizationId)
          .pipe(
            map((response) => {
              return BaseActions.FetchMilestoneDashboardPageSuccess(response);
            }),
            catchError((error) =>
              BaseActions.FetchMilestoneDashboardPageFail(error)
            )
          );
      }),
      tap((action: any) => {
        if (
          action.type === BaseActions.FetchMilestoneDashboardPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchMilestoneDashboardPageFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  /* needs to be updated from */
  FetchDashBoardNextMilestone$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchMilestoneDashboardNextPageRequest),

      mergeMap((action: any) => {
        this.commonService.startLoading();
        const nextPage =
          this.dashboardService.dashBoardMilestoneCurrentPage.value + 1;
        // this.dashboardService.setDashboardMilestoneCurrentPage(1);
        return this.baseService
          .fetchDashboardMileStones(action.workspaceId, nextPage)
          .pipe(
            map((response) => {
              this.dashboardService.setDashboardMilestoneCurrentPage(nextPage);
              return BaseActions.FetchMilestoneDashboardNextPageSuccess(
                response
              );
            }),
            catchError((error) =>
              BaseActions.FetchMilestoneDashboardNextPageFail(error)
            )
          );
      }),
      tap((action: any) => {
        if (
          action.type ===
          BaseActions.FetchMilestoneDashboardNextPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchMilestoneDashboardNextPageFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );

  fetchSasToken$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchSasTokenRequest),
      map((action) => {
        this.commonService.startLoading();
        const { type, ...payload } = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.baseService.fetchSasToken(payload).pipe(
          map((response: any) => {
            const { data } = response;
            return BaseActions.FetchSasTokenSuccess({
              host: data.host,
              container: data.container,
              token: data.sasToken,
            });
          }),
          catchError((error) => {
            return of(BaseActions.FetchSasTokenFailure(error.message));
          }),
          tap((action) => {
            if (action.type === BaseActions.FetchSasTokenSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === BaseActions.FetchSasTokenFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  FetchMilestonePagePhaseDetails$ = createEffect(() => {
    return this.actions.pipe(
      ofType(BaseActions.FetchPhasesMilestonePagesRequest),

      mergeMap((actions) => {
        this.commonService.startLoading();
        let workspaceId;
        this.store
          .select(getSelectedWorkspace)
          .subscribe((data) => {
            workspaceId = data.id;
          })
          .unsubscribe();
        return of({ workspaceId });
      }),
      mergeMap((payload: any) => {
        return this.baseService.fetchAllPhases(payload.workspaceId).pipe(
          map((response) => {
            const updatedPhases = response.data.phases.map((phase, index) => ({
              ...phase,
            }));

            const updatedResponse = {
              ...response,
              data: {
                ...response.data,
                phases: updatedPhases,
              },
            };

            return BaseActions.FetchPhasesMilestonePagesSuccess(
              updatedResponse
            );
          }),
          catchError((error) => {
            return of(BaseActions.FetchPhasesMilestonePagesFail(error.message));
          })
        );
      }),
      tap((action: any) => {
        if (action.type === BaseActions.FetchPhasesMilestonePagesSuccess.type) {
          // Code to execute on API Success Action dispatch

          this.milestonePageService.setCurrentPage(1);
        } else if (
          action.type === BaseActions.FetchPhasesMilestonePagesFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    );
  });
  FetchMilestonePagePhaseNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchPhasesNextPageMilestonePageRequest),
      map((actions) => {
        const nextPage = this.milestonePageService.phasesCurrentPage.value + 1;

        return { ...actions, nextPage };
      }),
      mergeMap((action) =>
        this.baseService
          .fetchAllNextPhases(action.organizationId, action.nextPage)
          .pipe(
            map((response) => {
              this.milestonePageService.setCurrentPage(action.nextPage);
              return BaseActions.FetchPhasesNextPageMilestonePageSuccess(
                response
              );
            }),
            catchError((error) =>
              BaseActions.FetchPhasesNextPageMilestonePageFail(error)
            ),
            tap((action: any) => {
              if (
                action.type ===
                BaseActions.FetchPhasesNextPageMilestonePageSuccess.type
              ) {
                // Code to execute on API Success Action dispatch
              } else if (
                action.type ===
                BaseActions.FetchPhasesNextPageMilestonePageFail.type
              ) {
                // Code to execute on API Failure Action dispatch
              }
              this.commonService.stopLoading();
            })
          )
      )
    )
  );
  FetchAllTeamRole$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchAllTeamRolesRequest),
      map((actions) => actions),
      mergeMap((profile) => {
        return this.baseService.fetchAllTeamRoles(profile.organizationId).pipe(
          map((response) => {
            return BaseActions.FetchAllTeamRolesSuccess(response);
          }),
          catchError((err) => {
            return BaseActions.FetchAllTeamRolesFail(err);
          })
        );
      }),
      tap((action: any) => {
        if (action.type === BaseActions.FetchAllTeamRolesSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (action.type === BaseActions.FetchAllTeamRolesFail.type) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );

  FetchDashboardWeekData$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.fetchDashboardWeekDataRequest),
      map((actions) => actions),
      mergeMap((payload) => {
        return this.baseService
          .fetchDashboardWeekData(payload.workspace, payload.date)
          .pipe(
            map((response) => {
              return BaseActions.fetchDashboardWeekDataSuccess(response);
            }),
            catchError((err) => {
              return BaseActions.fetchDashboardWeekDataFail(err);
            })
          );
      }),
      tap((action: any) => {
        if (action.type === BaseActions.fetchDashboardWeekDataSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.fetchDashboardWeekDataFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );
  SubmitParticipantMilestoneDeliverable$: Observable<Action> = createEffect(
    () =>
      this.actions.pipe(
        ofType(BaseActions.SubmitParticipantMilestoneDeliverableRequest),
        map((action) => {
          this.commonService.startLoading();
          const { type, ...payload } = action;
          return payload;
        }),
        mergeMap((payload) =>
          this.baseService
            .submitParticipantMilestoneDeliverableSuccess(payload)
            .pipe(
              map((response: any) => {
                const { data } = response;
                let milestoneDeliverable: any[] = [];
                this.store
                  .select(BaseSelector.getAllMilestoneDeliverable)
                  .subscribe((deliverable) => {
                    milestoneDeliverable = [...deliverable];
                  })
                  .unsubscribe();
                let updatedMilestoneDeliverables: any[] = [];
                milestoneDeliverable.forEach((deliverables) => {
                  if (deliverables.id === data.id) {
                    updatedMilestoneDeliverables.push({
                      ...deliverables,
                      participantSubmissions: [...data.participantSubmissions],
                    });
                  } else {
                    updatedMilestoneDeliverables.push({ ...deliverables });
                  }
                });

                return BaseActions.SubmitParticipantMilestoneDeliverableSuccess(
                  { data: updatedMilestoneDeliverables }
                );
              }),
              catchError((error) => {
                return of(
                  BaseActions.SubmitParticipantMilestoneDeliverableFailure(
                    error.message
                  )
                );
              }),
              tap((action) => {
                if (
                  action.type ===
                  BaseActions.SubmitParticipantMilestoneDeliverableSuccess.type
                ) {
                  // Code to execute on API Success Action dispatch

                  this.commonService.closeDialog();
                } else if (
                  action.type ===
                  BaseActions.SubmitParticipantMilestoneDeliverableFailure.type
                ) {
                  // Code to execute on API Failure Action dispatch
                }
                this.commonService.stopLoading();
              })
            )
        )
      )
  );
  fetchDirectoryTeams$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDirectoryTeamsRequest),
      map((payload) => {
        return payload.workspaceId;
      }),
      mergeMap((workspaceId) => {
        this.commonService.startLoading();
        return this.baseService.fetchDirectoryTeams(workspaceId, 1).pipe(
          map((response) => {
            this.directoryService.setTeamsCurrentPageNumber(1);
            return BaseActions.FetchDirectoryTeamsSuccess(response);
          }),
          catchError((err) => BaseActions.FetchDirectoryTeamsFail(err))
        );
      }),
      tap((action) => {
        if (action.type === BaseActions.FetchDirectoryTeamsSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (action.type === BaseActions.FetchDirectoryTeamsFail.type) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchDirectoryTeamsNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDirectoryTeamsNextPageRequest),
      map((payload) => {
        return payload.workspaceId;
      }),
      mergeMap((workspaceId) => {
        this.commonService.startLoading();

        const nextPage = this.directoryService.teamsCurrentPage.value + 1;
        return this.baseService.fetchDirectoryTeams(workspaceId, nextPage).pipe(
          map((response) => {
            const nextPage = this.directoryService.teamsCurrentPage.value + 1;
            this.directoryService.setTeamsCurrentPageNumber(nextPage);

            return BaseActions.FetchDirectoryTeamsNextPageSuccess(response);
          }),
          catchError((err) => BaseActions.FetchDirectoryTeamsNextPageFail(err))
        );
      }),
      tap((action) => {
        if (
          action.type === BaseActions.FetchDirectoryTeamsNextPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchDirectoryTeamsNextPageFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchDirectoryCohort$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDirectoryCohortRequest),
      map((payload) => {
        return payload;
      }),
      mergeMap((payload: any) => {
        this.commonService.startLoading();
        if (payload.filter) {
          return this.baseService
            .fetchDirectoryCohort(payload.workspaceId, 1, { ...payload.filter })
            .pipe(
              map((response) => {
                this.directoryService.setTeamsCurrentPageNumber(1);
                return BaseActions.FetchDirectoryCohortSuccess(response);
              }),
              catchError((err) => BaseActions.FetchDirectoryCohortFail(err)),
              tap((action) => {
                if (
                  action.type === BaseActions.FetchDirectoryCohortSuccess.type
                ) {
                  // Code to execute on API Success Action dispatch
                } else if (
                  action.type === BaseActions.FetchDirectoryCohortFail.type
                ) {
                  // Code to execute on API Failure Action dispatch
                }
                this.commonService.stopLoading();
              })
            );
        } else {
          return this.baseService
            .fetchDirectoryCohortWithoutFilters(payload.workspaceId, 1)
            .pipe(
              map((response) => {
                /* const nextPage =
                  this.directoryService.cohortCurrentPage.value + 1; */
                this.directoryService.setTeamsCurrentPageNumber(1);
                return BaseActions.FetchDirectoryCohortSuccess(response);
              }),
              catchError((err) => BaseActions.FetchDirectoryCohortFail(err)),
              tap((action) => {
                if (
                  action.type === BaseActions.FetchDirectoryCohortSuccess.type
                ) {
                  // Code to execute on API Success Action dispatch
                } else if (
                  action.type === BaseActions.FetchDirectoryCohortFail.type
                ) {
                  // Code to execute on API Failure Action dispatch
                }
                this.commonService.stopLoading();
              })
            );
        }
      })
    )
  );
  fetchDirectoryCohortNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDirectoryCohortNextPageRequest),
      map((payload) => {
        return payload;
      }),
      mergeMap((payload: any) => {
        this.commonService.startLoading();
        if (payload.filter) {
          const nextPage = this.directoryService.cohortCurrentPage.value + 1;
          return this.baseService
            .fetchDirectoryCohort(payload.workspaceId, nextPage, {
              ...payload.filter,
            })
            .pipe(
              map((response) => {
                const nextPage =
                  this.directoryService.cohortCurrentPage.value + 1;
                this.directoryService.setTeamsCurrentPageNumber(nextPage);
                return BaseActions.FetchDirectoryCohortNextPageSuccess(
                  response
                );
              }),
              catchError((err) =>
                BaseActions.FetchDirectoryCohortNextPageFail(err)
              ),
              tap((action) => {
                if (
                  action.type ===
                  BaseActions.FetchDirectoryCohortNextPageSuccess.type
                ) {
                  // Code to execute on API Success Action dispatch
                } else if (
                  action.type ===
                  BaseActions.FetchDirectoryCohortNextPageFail.type
                ) {
                  // Code to execute on API Failure Action dispatch
                }
                this.commonService.stopLoading();
              })
            );
        } else {
          const nextPage = this.directoryService.cohortCurrentPage.value + 1;
          return this.baseService
            .fetchDirectoryCohortWithoutFilters(payload.workspaceId, nextPage)
            .pipe(
              map((response) => {
                const nextPage =
                  this.directoryService.cohortCurrentPage.value + 1;
                this.directoryService.setTeamsCurrentPageNumber(nextPage);

                return BaseActions.FetchDirectoryCohortNextPageSuccess(
                  response
                );
              }),
              catchError((err) =>
                BaseActions.FetchDirectoryCohortNextPageFail(err)
              ),
              tap((action) => {
                if (
                  action.type ===
                  BaseActions.FetchDirectoryCohortNextPageSuccess.type
                ) {
                  // Code to execute on API Success Action dispatch
                } else if (
                  action.type ===
                  BaseActions.FetchDirectoryCohortNextPageFail.type
                ) {
                  // Code to execute on API Failure Action dispatch
                }
                this.commonService.stopLoading();
              })
            );
        }
      })
    )
  );

  fetchDirectoryTeamRoles$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDirectoryTeamRoleRequest),
      map((payload) => payload.workspaceId),
      mergeMap((workspaceId) => {
        return this.baseService.fetchAllTeamRoles(workspaceId).pipe(
          map((response) =>
            BaseActions.FetchDirectoryTeamRoleSuccess(response)
          ),
          catchError((err) => BaseActions.FetchDirectoryTeamRoleFail(err))
        );
      }),
      tap((action) => {
        if (action.type === BaseActions.FetchDirectoryTeamRoleSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchDirectoryTeamRoleFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );
  fetchDirectoryTeam$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchDirectoryTeamsOptionRequest),
      map((payload) => payload.workspaceId),
      mergeMap((workspaceId) => {
        return this.baseService.fetchAllTeams(workspaceId).pipe(
          map((response) =>
            BaseActions.FetchDirectoryTeamsOptionSuccess(response)
          ),
          catchError((err) => BaseActions.FetchDirectoryTeamsOptionFail(err))
        );
      }),
      tap((action) => {
        if (action.type === BaseActions.FetchDirectoryTeamsOptionSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchDirectoryTeamsOptionFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );
  fetchAllDirectMessage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.fetchAllDirectConversationRequest),
      mergeMap((payload) => {
        this.commonService.startLoading();
        return this.baseService.fetchAllDirectMessage().pipe(
          map((res) => BaseActions.fetchAllDirectConversationSuccess(res.data)),
          catchError((err) => BaseActions.fetchAllDirectConversationFail(err))
        );
      }),
      tap((action) => {
        if (
          action.type === BaseActions.fetchAllDirectConversationSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.fetchAllDirectConversationFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchAllMessageForConversationId$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.fetchAllMessageForConversationIdRequest),
      map((payload) => payload.conversationId),
      mergeMap((conversationId) => {
        this.commonService.startLoading();
        return this.baseService.fetchAllMessages(conversationId).pipe(
          map((res) => {
            return BaseActions.fetchAllMessageForConversationIdSuccess(
              res.data
            );
          }),
          catchError((err) => {
            return of(BaseActions.fetchAllMessageForConversationIdFail(err));
          }),
          tap((action) => {
            if (
              action.type ===
              BaseActions.fetchAllMessageForConversationIdSuccess.type
            ) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type ===
              BaseActions.fetchAllMessageForConversationIdFail.type
            ) {
              // Code to execute on API Failure Action dispatch
              // Route to baseURL and remove query Params
              const currentRoute = this.router.url.split('?')[0];
              this.router
                .navigateByUrl('/dummy-url', { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([currentRoute]);
                });
            }
            this.commonService.stopLoading();
          })
        );
      })
    )
  );
  navigationToDirectMessageOnceAllTheDirectMessagesAreFetched$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BaseActions.fetchAllDirectConversationSuccess),
        map((payload) => {
          const previousParams =
            this.groupsNavigationService.groupsPreviousNavigationSubject.value;
          if (previousParams !== null) {
            if (previousParams.conversationType === 'direct') {
              this.messageService.threadCurrentPage.next(0);
              const workspaceId =
                this.groupsNavigationService.currentWorkspaceIdSubject.value;

              this.router.navigate(
                [`/changemakers/groups/workspace/${workspaceId}`],
                previousParams
              );
            }
          }
        })
      ),
    { dispatch: false }
  );
  /* message next page */
  fetchAllMessageForConversationIdNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.fetchAllMessageForConversationIdRequestNextPage),
      map((payload) => payload),
      mergeMap((conversationId: any) => {
        this.commonService.startLoading();

        return this.baseService
          .fetchAllMessagesNextPage(
            conversationId.conversationId,
            this.messageService.messageCurrentPage.value
          )
          .pipe(
            map((res) => {
              return BaseActions.fetchAllMessageForConversationIdSuccessNextPage(
                res.data
              );
            }),
            catchError((err) =>
              BaseActions.fetchAllMessageForConversationIdFailNextPage(err)
            )
          );
      }),
      tap((action) => {
        if (
          action.type ===
          BaseActions.fetchAllMessageForConversationIdSuccessNextPage.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.fetchAllMessageForConversationIdFailNextPage.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchAllGroupsForMyGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchAllGroupsForMyGroupsRequest),
      mergeMap((payload) => {
        this.commonService.startLoading();
        return this.baseService.fetchAllGroups().pipe(
          map((res) =>
            BaseActions.FetchAllGroupsForMyGroupsSuccess({
              ...res.data,
              workspaceId: payload.workspaceId,
            })
          ),
          catchError((err) => BaseActions.FetchAllGroupsForMyGroupsFail(err))
        );
      }),
      tap((action) => {
        if (action.type === BaseActions.FetchAllGroupsForMyGroupsSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchAllGroupsForMyGroupsFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  defaultSelectionForMyCohort$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BaseActions.FetchAllGroupsForMyGroupsSuccess),
        map((payload) => {
          if (Object.keys(payload.myCohort).length > 0) {
            this.messageService.threadCurrentPage.next(0);
            const previousParams =
              this.groupsNavigationService.groupsPreviousNavigationSubject
                .value;
            if (previousParams !== null) {
              if (previousParams.conversationType !== 'direct') {
                this.router
                  .navigate(
                    [`/changemakers/groups/workspace/${payload.workspaceId}`],
                    previousParams
                  )
                  .then(() => {
                    if (previousParams.fragment) {
                      setTimeout(() => {
                        document
                          .getElementById(previousParams.fragment)
                          ?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'nearest',
                          });
                      }, 2000);
                    }
                  });
              }
            } else {
              const params: any = {
                groupId: payload.myCohort.id,
                conversationType: 'groups',
              };
              this.groupsNavigationService.setGroupPreviousSelected(params);
              this.router.navigate(
                [`/changemakers/groups/workspace/${payload.workspaceId}`],
                {
                  queryParams: params,
                }
              );
            }
          }
        })
      ),
    { dispatch: false }
  );
  /*
  need to add the logic to navigate the user to the cohort


  */
  fetchAllThreadForGroupId$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchAllThreadsForGroupsConversationRequest),
      map((payload) => payload),
      mergeMap((payload) => {
        this.commonService.startLoading();
        const nextPage = this.messageService.threadCurrentPage.value + 1;
        return this.baseService
          .fetchAllThreadsForGroupsConversation(
            payload.groupId,
            nextPage,
            payload.limit
          )
          .pipe(
            map((res) => {
              this.messageService.setThreadCurrentPage(nextPage);
              return BaseActions.FetchAllThreadsForGroupsConversationSuccess(
                res.data
              );
            }),
            catchError((err) =>
              BaseActions.FetchAllThreadsForGroupsConversationFail(err)
            )
          );
      }),
      tap((action) => {
        if (
          action.type ===
          BaseActions.FetchAllThreadsForGroupsConversationSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.FetchAllThreadsForGroupsConversationFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchAllThreadForGroupIdNextPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchAllThreadsForGroupsConversationNextPageRequest),
      map((payload) => payload.groupId),
      mergeMap((groupId) => {
        this.commonService.startLoading();
        let nextPage = this.messageService.threadCurrentPage.value + 1;
        if (nextPage === 1) {
          nextPage = 2;
        }
        return this.baseService
          .fetchAllThreadsForGroupsConversation(groupId, nextPage)
          .pipe(
            map((res) => {
              this.messageService.setThreadCurrentPage(nextPage);
              return BaseActions.FetchAllThreadsForGroupsConversationNextPageSuccess(
                res.data
              );
            }),
            catchError((err) =>
              BaseActions.FetchAllThreadsForGroupsConversationNextPageFAIL(err)
            )
          );
      }),
      tap((action) => {
        if (
          action.type ===
          BaseActions.FetchAllThreadsForGroupsConversationNextPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.FetchAllThreadsForGroupsConversationNextPageFAIL.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );

  createNewThreadForTheGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.CreateNewChatThreadForGroupRequest),
      map((payload) => payload.payload),
      mergeMap((payload) => {
        this.commonService.startLoading();
        return this.baseService.createNewConversation(payload).pipe(
          map((res) => {
            return BaseActions.CreateNewChatThreadForGroupSuccess(res.data);
          }),
          catchError((err) => BaseActions.CreateNewChatThreadForGroupFail(err))
        );
      }),
      tap((action) => {
        if (
          action.type === BaseActions.CreateNewChatThreadForGroupSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.CreateNewChatThreadForGroupFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchSearchedUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.SearchUserRequest),
      mergeMap((payload: any) => {
        this.commonService.startLoading();
        return this.baseService
          .searchUser(payload.workspaceId, payload.searchedTerm)
          .pipe(
            map((res) => {
              return BaseActions.SearchUserSuccess(res.data);
            }),
            catchError((err) => BaseActions.SearchUserFail(err))
          );
      }),
      tap((action) => {
        if (action.type === BaseActions.SearchUserSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (action.type === BaseActions.SearchUserFail.type) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  StartNewDirectConversation$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.StartNewDirectMessageConversationRequest),
      map((payload) => {
        this.commonService.startLoading();
        return payload;
      }),
      mergeMap((payload) =>
        this.baseService.createNewConversation(payload.payload).pipe(
          map((res) => {
            return BaseActions.StartNewDirectMessageConversationSuccess({
              ...res.data,
              workspaceId: payload.workspaceId,
            });
          }),
          catchError((err) =>
            BaseActions.StartNewDirectMessageConversationFail(err)
          )
        )
      ),
      tap((action) => {
        if (
          action.type ===
          BaseActions.StartNewDirectMessageConversationSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
          this.store.dispatch(BaseActions.fetchAllDirectConversationRequest());
        } else if (
          action.type === BaseActions.StartNewDirectMessageConversationFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  navigateToNewConversationAdded$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BaseActions.StartNewDirectMessageConversationSuccess),
        map((payload) => {
          const params = {
            conversationType: 'direct',
            conversationId: payload.id,
            conversationName: payload.name,
          };
          this.router.navigate(
            [`/changemakers/groups/workspace/${payload.workspaceId}`],
            { queryParams: params }
          );
        })
      ),
    { dispatch: false }
  );
  FetchUserDetailsForDirectoryPage$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchUserProfileForDirectoryPageRequest),
      mergeMap((payload) => {
        this.commonService.startLoading();
        return this.baseService
          .fetchUserProfileForDirectoryPage(payload.workspace, payload.userId)
          .pipe(
            map((res) =>
              BaseActions.FetchUserProfileForDirectoryPageSuccess(res.data)
            ),
            catchError((err) =>
              BaseActions.FetchUserProfileForDirectoryPageFail(err)
            )
          );
      }),
      tap((action) => {
        if (
          action.type ===
          BaseActions.FetchUserProfileForDirectoryPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchUserProfileForDirectoryPageFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );

  fetchChatSasToken$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchChatSasTokenRequest),
      map((action) => {
        this.commonService.startLoading();
        const { type, ...payload } = action;
        return payload;
      }),
      mergeMap((payload) =>
        this.baseService.fetchChatSasToken(payload).pipe(
          map((response: any) => {
            const { data } = response;
            return BaseActions.FetchChatSasTokenSuccess({
              host: data.host,
              container: data.container,
              token: data.sasToken,
            });
          }),
          catchError((error) => {
            return of(BaseActions.FetchChatSasTokenFailure(error.message));
          }),
          tap((action) => {
            if (action.type === BaseActions.FetchChatSasTokenSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (
              action.type === BaseActions.FetchChatSasTokenFailure.type
            ) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );
  fetchMilestoneRelatedConversation$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchMilestoneRelatedConversationRequest),
      map((payload) => {
        this.commonService.startLoading();
        return payload;
      }),
      mergeMap((payload) => {
        return this.baseService
          .fetchMilestoneRelatedConversation(payload.milestoneId)
          .pipe(
            map((res) =>
              BaseActions.FetchMilestoneRelatedConversationSuccess(res?.data)
            ),
            catchError((err) =>
              BaseActions.FetchMilestoneRelatedConversationFail(err)
            )
          );
      }),
      tap((action) => {
        if (
          action.type ===
          BaseActions.FetchMilestoneRelatedConversationSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchMilestoneRelatedConversationFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  FetchAllMilestoneAllThread$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchAllThreadsConversationForMilestoneRequest),
      map((payload) => {
        this.commonService.startLoading();
        return payload;
      }),
      mergeMap((payload) =>
        this.baseService
          .fetchAllThreadForMilestoneConversation(
            payload.groupId,
            payload.milestoneId
          )
          .pipe(
            map((res) => {
              return BaseActions.FetchAllThreadsConversationForMilestoneSuccess(
                res.data
              );
            }),
            catchError((err) =>
              BaseActions.FetchAllThreadsConversationForMilestoneFail(err)
            )
          )
      ),
      tap((action) => {
        if (
          action.type ===
          BaseActions.FetchAllThreadsConversationForMilestoneSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.FetchAllThreadsConversationForMilestoneFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  createNewMilestoneConversationChannel$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.CreateNewConversationChannelForMilestoneRequest),
      map((payload: any) => {
        this.commonService.startLoading();
        return payload?.payload;
      }),
      mergeMap((payload) =>
        this.baseService.createNewConversation(payload).pipe(
          map((res) => {
            this.milestoneConversationThreadService.newConversationCreated(
              res.data
            );
            return BaseActions.CreateNewConversationChannelForMilestoneSuccess(
              res.data
            );
          }),
          catchError((err) =>
            BaseActions.CreateNewConversationChannelForMilestoneFail(err)
          )
        )
      ),
      tap((action) => {
        if (
          action.type ===
          BaseActions.CreateNewConversationChannelForMilestoneSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.CreateNewConversationChannelForMilestoneFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchAllMessageForAThreadRealatedToMilestone$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchAllConversationRelatedToThreadsMilestoneRequest),
      map((payload) => {
        this.commonService.startLoading();
        return payload.thread;
      }),
      mergeMap((payload) =>
        this.baseService.fetchAllMessages(payload.id).pipe(
          map((res) => {
            this.milestoneMessageService.setMilestoneConversationThreadMessageCurrentPageNumber(
              1
            );
            return BaseActions.FetchAllConversationRelatedToThreadsMilestoneSuccess(
              res.data
            );
          }),
          catchError((err) =>
            BaseActions.FetchAllConversationRelatedToThreadsMilestoneFail(err)
          )
        )
      ),
      tap((action) => {
        if (
          action.type ===
          BaseActions.FetchAllConversationRelatedToThreadsMilestoneSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.FetchAllConversationRelatedToThreadsMilestoneFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchNextPageMessagesForMilestoneConversation$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        BaseActions.FetchAllConversationRelatedToThreadsMilestoneNextPageRequest
      ),
      map((payload) => {
        this.commonService.startLoading();
        return payload;
      }),
      mergeMap((payload) => {
        const nextPage =
          this.milestoneMessageService
            .milestoneConversationThreadMessagesCurrentPage.value + 1;
        return this.baseService
          .fetchAllMessagesNextPage(
            payload?.conversationId,
            payload?.lastMessageId
          )
          .pipe(
            map((res) => {
              return BaseActions.FetchAllConversationRelatedToThreadsMilestoneNextPageSuccess(
                res.data
              );
            }),
            catchError((err) =>
              BaseActions.FetchAllConversationRelatedToThreadsMilestoneNextPageFail(
                err
              )
            )
          );
      }),
      tap((action) => {
        if (
          action.type ===
          BaseActions
            .FetchAllConversationRelatedToThreadsMilestoneNextPageSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type ===
          BaseActions.FetchAllConversationRelatedToThreadsMilestoneNextPageFail
            .type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );
  fetchUsersProfileForEditProfile$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchUserProfileRequest),
      map((payload) => {
        this.commonService.startLoading();
        return payload;
      }),
      mergeMap((payload) => {
        return this.baseService.fetchCurrentLoggedInUserProfile().pipe(
          map((res) => BaseActions.FetchUserProfileSuccess(res.data)),
          catchError((err) => BaseActions.FetchUserProfileFail(err))
        );
      }),
      tap((action) => {
        if (action.type === BaseActions.FetchUserProfileSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (action.type === BaseActions.FetchUserProfileFail.type) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );

  fetchUserForTheMention$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.SearchUserForMentionRequest),
      map((payload) => {
        return payload;
      }),
      mergeMap((userName) => {
        if (userName.searchAccordingToGroup === true) {
          return this.baseService
            .searchUserForMentionInTheGroup(
              userName.workspaceId,
              userName.username,
              userName.groupId
            )
            .pipe(
              map((res) => {
                return BaseActions.SearchUserForMentionSuccess(res.data);
              }),
              catchError((err) => of(BaseActions.SearchUserForMentionFail(err)))
            );
        } else {
          return this.baseService
            .searchUserForMentions(
              userName.workspaceId,
              userName.username,
              userName.conversationId
            )
            .pipe(
              map((res) => {
                return BaseActions.SearchUserForMentionSuccess(res.data);
              }),
              catchError((err) => of(BaseActions.SearchUserForMentionFail(err)))
            );
        }
        /*
        !working here
        */
      }),
      tap((action) => {
        if (action.type === BaseActions.SearchUserForMentionSuccess.type) {
          // Code to execute on API Success Action dispatch
        } else if (action.type === BaseActions.SearchUserForMentionFail.type) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );
  FetchUnreadCountGroupsIconsRequest$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchUnreadCountGroupsIconsRequest),
      mergeMap((payload) => {
        return this.baseService.getTheUnreadCountForTheGroups().pipe(
          map(
            (res) => BaseActions.FetchUnreadCountGroupsIconsSuccess(res.data),
            catchError((err) =>
              BaseActions.FetchUnreadCountGroupsIconsFail(err)
            )
          )
        );
      }),
      tap((action) => {
        if (
          action.type === BaseActions.FetchUnreadCountGroupsIconsSuccess.type
        ) {
          // Code to execute on API Success Action dispatch
        } else if (
          action.type === BaseActions.FetchUnreadCountGroupsIconsFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
      })
    )
  );

  fetchFilesRelatedToMilestone$ = createEffect(() =>
    this.actions.pipe(
      ofType(BaseActions.FetchFileAccordingToMilestoneRequest),
      map((payload) => {
        this.commonService.startLoading();
        return payload;
      }),
      mergeMap((payload) => {
        const currentPage = this.milestoneFilesService.currentPage.value;
        const nextPage = currentPage + 1;
        return this.baseService
          .getFilesAccordingToMilestone(payload.milestoneId, nextPage)
          .pipe(
            map((res) =>
              BaseActions.FetchFileAccordingToMilestoneSuccess({
                data: res.data,
              })
            ),
            catchError((err) =>
              BaseActions.FetchFileAccordingToMilestoneFail(err)
            )
          );
      }),
      tap((action) => {
        if (
          action.type === BaseActions.FetchFileAccordingToMilestoneSuccess.type
        ) {
          const currentPage = this.milestoneFilesService.currentPage.value;
          const nextPage: number = currentPage + 1;
          // Code to execute on API Success Action dispatch
          this.milestoneFilesService.setCurrentPageNumber(nextPage);
        } else if (
          action.type === BaseActions.FetchFileAccordingToMilestoneFail.type
        ) {
          // Code to execute on API Failure Action dispatch
        }
        this.commonService.stopLoading();
      })
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private baseService: BaseService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService,
    private authWrapperService: AuthenticatedWrapperService,
    private milestonePageService: MilestonePageService,
    private authenticationService: AuthenticationService,
    private collectionPageService: CollectionPageService,
    private dashboardService: DashboardPageService,
    private directoryService: DirectoryService,
    private messageService: MessageService,
    private milestoneMessageService: MilestoneMessageService,
    private milestoneConversationThreadService: MilestoneConversationThreadService,
    private groupsNavigationService: GroupsNavigationService,
    private milestoneFilesService: MilestoneFilesService,
    private activatedRoute: ActivatedRoute
  ) {}
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-settings-container',
  templateUrl: './settings-container.component.html',
  styleUrls: ['./settings-container.component.css']
})
export class SettingsContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-button-panel',
  templateUrl: './button-panel.component.html',
  styleUrls: ['./button-panel.component.css']
})
export class ButtonPanelComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  handleClick(message) {
    alert(message);
  }
}

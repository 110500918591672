import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GroupsNavigationService {
  constructor(private activatedRoute: ActivatedRoute) {}
  groupsPreviousNavigationSubject: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  currentWorkspaceIdSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  setGroupPreviousSelected(data) {
    this.groupsPreviousNavigationSubject.next({
      queryParams: data,
      fragment: this.activatedRoute.snapshot.fragment
    });
  }
  setWorkspaceId(id) {
    this.currentWorkspaceIdSubject.next(id);
  }
}

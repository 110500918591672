import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as BaseAction from '../../../core/base.actions';
import * as BaseSelector from '../../../core/base.selectors';
import {CommonService} from '../../../../shared/services/common/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-phase-milestone',
  templateUrl: './phase-milestone.component.html',
  styleUrls: ['./phase-milestone.component.css'],
})
export class PhaseMilestoneComponent implements OnInit, OnChanges, OnDestroy {
  @Input() milestoneDetails: any;
  @Input() phase: any;
  @Input() workspaceId: string;
  throttle = 300;
  scrollDistance = 1;
  milestonePage = 0;
  teamRoles:number[]=[]
  currentTeamRolesSubscription:Subscription=new Subscription();

  constructor(
    private router: Router,
    private store: Store,
    public commonService: CommonService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.milestoneDetails) {
      let updatedDeliverables = [];
      this.milestoneDetails.deliverables?.forEach(
        (deliverables, deliverableIndex) => {
          deliverables.adminDeliverables.forEach(
            (adminDeliverables, adminDeliverablesIndex) => {
              if (deliverableIndex == 0 && adminDeliverablesIndex == 0) {
                updatedDeliverables.push({adminDeliverables: []});
              }
              updatedDeliverables[0].adminDeliverables.push(adminDeliverables);
            }
          );
        }
      );
      this.milestoneDetails = {
        ...this.milestoneDetails,
        deliverables: updatedDeliverables,
      };
    }

    this.currentTeamRolesSubscription = this.store.select(BaseSelector.getCurrentSelectedFilterByRoleForCollectionPage).subscribe(data => {
      if (data) {
        this.teamRoles = data;
      }
    })
  }

  navigateToMilestone() {
    this.router.navigate([
      `/changemakers/milestone-details/workspace/${this.workspaceId}/phase/${this.phase}/milestone/${this.milestoneDetails.id}`,
    ]);
  }

  loadMoreActions() {
    if (this.milestoneDetails.actionHasMore === true) {
      this.store.dispatch(
        BaseAction.FetchActionStepCollectionPageRequest({
          milestoneId: this.milestoneDetails.id,
          currentPage: this.milestoneDetails.ActionStepPage,
          phaseId: this.phase,
          organizationId: this.workspaceId,
          teamRoles:this.teamRoles
        })
      );
    }
  }

  nextPage() {
    this.milestonePage = this.milestonePage + 2;
  }

  previousPage() {
    this.milestonePage = this.milestonePage - 2;
  }

  
  ngOnDestroy(): void {
    this.currentTeamRolesSubscription?.unsubscribe();
  }
}

import {EventEmitter, Inject, Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import * as SharedActions from '../../core/shared.actions';
import * as GroupsActions from '../../../groups/core/groups.actions';
import * as AuthenticationActions from '../../../authentication/core/authentication.actions';
import * as fromRoot from '../../../../state/app.state';
import {Store} from '@ngrx/store';
import * as BaseActions from '../../../base/core/base.actions';
import {BasicDialogComponent} from '../../components/dialogbox/basic-dialog/basic-dialog.component';
import * as BaseSelectors from '../../../base/core/base.selectors';
import {getAllWorkspaces, getSelectedWorkspace} from '../../../authentication/core/authentication.selectors';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
  passwordRegex = new RegExp(/(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,20}$/);

  isLoadingScreenOpen = new BehaviorSubject(false);

  closeLoadingScreen = new EventEmitter();
  // Used for copyToClipboard(value)
  private dom: Document;

  constructor(
    private store: Store<fromRoot.State>,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) dom: Document
  ) {
    // Used for copyToClipboard(value)
    this.dom = dom;
  }

  setToLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  getFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  setToSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  getFromSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
  }

  setAuthenticationToken(token) {
    this.setToLocalStorage('token', token);
  }

  getAuthenticationToken() {
    return this.getFromLocalStorage('token');
  }

  isAuthenticated() {
    return !!this.getAuthenticationToken();
  }

  logout() {
    this.clearAppState();
    this.clearAppStorage();
    this.router.navigate(['/login'], {replaceUrl: true});
  }

  clearAppState() {
    this.store.dispatch(AuthenticationActions.ClearState());
    this.store.dispatch(SharedActions.ClearState());
    this.store.dispatch(BaseActions.ClearState());
    this.store.dispatch(GroupsActions.ClearState());
  }

  clearAppStorage() {
    /* const appStorageKeys = ['authentication', 'shared', 'base', 'token']

    appStorageKeys.forEach((key, index) => {
      this.removeFromLocalStorage(key)
    }) */
    localStorage.clear();
    sessionStorage.clear();
  }

  callAPI(type, url, payload?, options = {}) {
    return this.http[type]<any>(url, payload, {
      observe: 'response',
      ...options,
    }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body || response;
      }),
      catchError((error) => throwError(error))
    );
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  openInCurrentTab(url) {
    window.open(url, '_self');
  }

  startLoading() {
    this.store.dispatch(SharedActions.StartLoading());
  }

  stopLoading() {
    this.store.dispatch(SharedActions.StopLoading());
  }

  openDialog(config: any = {}) {
    const dialogConfig = new MatDialogConfig();
    const dialogClassNames = config?.data?.dialogClassNames;
    dialogConfig.backdropClass = dialogClassNames?.backdropClass || '';
    dialogConfig.panelClass = dialogClassNames?.panelClass || 'dialog';

    delete config.dialogClassNames;

    this.dialog.open(BasicDialogComponent, {
      ...dialogConfig,
      ...config,
      disableClose: false,
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkFormValidation(form, errorMessageMap, currentField?) {
    const errorMessages = {};
    const formControls = form.controls || null;

    if (currentField) {
      const errors = form.controls[currentField].errors;
      const firstErrorType = errors ? Object.keys(errors)[0] : null;
      errorMessages[currentField] =
        errorMessageMap[currentField][firstErrorType];
      return errorMessages;
    }

    for (const eachControlName in formControls) {
      const isControlValid = form.controls[eachControlName].valid;
      if (!isControlValid) {
        const errors = form.controls[eachControlName].errors;
        const firstErrorType = errors ? Object.keys(errors)[0] : null;
        if (firstErrorType) {
          form.controls[eachControlName].markAsTouched();
          errorMessages[eachControlName] =
            errorMessageMap[eachControlName][firstErrorType];
        }
      }
    }
    return errorMessages;
  }

  notification(message, type?, actionText?, notificationDuration?) {
    let duration = 2000;
    if (notificationDuration) {
      duration = notificationDuration;
    }
    this.snackBar.open(message, actionText, {
      duration,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: [type],
    });
  }

  getSasToken() {
    this.store
      .select(BaseSelectors.getDetailsConfig)
      .subscribe((detailConfig) => {
        const payload = {
          workspaceId: detailConfig.selectedWorkspaceId,
          phaseId: detailConfig.selectedPhaseId,
          milestoneId: detailConfig.selectedMilestoneId,
        };

        this.store.dispatch(BaseActions.FetchSasTokenRequest(payload));
      })
      .unsubscribe();
  }

  uploadToBlobStorage(payload, allowDownload = true): Observable<any> {
    const req = new HttpRequest('PUT', payload.url, payload.data, {
      reportProgress: true,
      responseType: 'json',
    });

    let headers = req.headers;
    headers = headers.append('x-ms-blob-type', 'BlockBlob');
    headers = headers.append('file-upload', 'true');
    headers = headers.append('Content-Type', payload.fileType);
    if (allowDownload) {
      headers = headers.append('x-ms-blob-content-disposition', 'attachment');
    }
    const requestToSend = req.clone({headers});
    return this.http.request(requestToSend);
  }

  /*
  TODO remove this use params
  */
  uploadImageToCloud(payload): Observable<any> {
    const req = new HttpRequest('PUT', payload.url, payload.data, {
      reportProgress: true,
      responseType: 'json',
    });

    let headers = req.headers;
    headers = headers.append('x-ms-blob-type', 'BlockBlob');
    headers = headers.append('file-upload', 'true');
    headers = headers.append('Content-Type', payload.fileType);
    /* headers = headers.append('x-ms-blob-content-disposition', 'attachment'); */
    const requestToSend = req.clone({headers});
    return this.http.request(requestToSend);
  }

  deleteFromBlobStorage(url): Observable<any> {
    const req = new HttpRequest('DELETE', url, {
      reportProgress: true,
      responseType: 'json',
    });

    let headers = req.headers;
    headers = headers.append('x-ms-blob-type', 'BlockBlob');
    headers = headers.append('file-upload', 'true');
    const requestToSend = req.clone({headers});
    return this.http.request(requestToSend);
  }

  getSasTokenForProfileImage() {
    this.store.dispatch(
      AuthenticationActions.FetchSasTokenForProfileImageRequest()
    );
  }

  setCurrentWorkspace(workspaceId) {
    let oldWorkspace;
    this.store.select(getSelectedWorkspace).subscribe(data => {
      oldWorkspace = data;
    });

    let newWorkspace;
    this.store.select(getAllWorkspaces).subscribe(data => {
      newWorkspace = data.filter((eachWorkspace) => {
        if (eachWorkspace.id === Number(workspaceId)) {
          return eachWorkspace;
        }
      })[0];
    }).unsubscribe();

    if (!oldWorkspace ||
      (newWorkspace && oldWorkspace && newWorkspace.id !== oldWorkspace.id)
    ) {
      this.store.dispatch(AuthenticationActions.ChangeSelectedWorkspace({workspace: newWorkspace}));
      this.store.dispatch(BaseActions.SetSelectedWorkSpaceId({SelectedWorkspaceId: newWorkspace.id}));
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {CommonService} from './modules/shared/services/common/common.service';
import * as fromRoot from './state/app.state';
import * as SharedSelectors from '../app/modules/shared/core/shared.selectors';
import {filter, Subscription} from 'rxjs';
import {LoadingScreenComponent} from './modules/shared/components/loading-screen/loading-screen.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {ClearLoading} from './modules/shared/core/shared.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ChangeMakers';

  isLoadingSubscription: Subscription;
  breakPointObserverSubscription: Subscription;
  isLoadingScreenOpenSubscription: Subscription;
  routerSubscription: Subscription;
  isLoadingScreenOpen = false;
  isAppDisabled = false;

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    public breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.store.dispatch(ClearLoading());

    this.isLoadingScreenOpenSubscription = this.commonService.isLoadingScreenOpen.subscribe(data => {
      this.isLoadingScreenOpen = data;
    });

    this.isLoadingSubscription = this.store.pipe(select(SharedSelectors.isLoading)).subscribe(data => {
      if (!!data.length && !this.isLoadingScreenOpen) {
        this.commonService.isLoadingScreenOpen.next(true);
        setTimeout(() => {
          this.commonService.openDialog({
            data: {
              component: LoadingScreenComponent,
              dialogClassNames: {
                backdropClass: 'spinner-backdrop',
                panelClass: 'spinner-panel'
              }
            }
          });
        }, 100)
      }
    });

    this.breakPointObserverSubscription = this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .subscribe((state: BreakpointState) => {
        this.isAppDisabled = !state.matches;
      });

    this.routerSubscription = this.router.events
      .pipe(
        filter(e => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.params).length > 0)),
        map(e => e instanceof ActivationEnd ? e.snapshot.params : {})
      )
      .subscribe(params => {
        const {workspaceId} = params;
        if (workspaceId) {
          this.commonService.setCurrentWorkspace(workspaceId);
        }
      });
  }

  ngOnDestroy() {
    this.isLoadingSubscription?.unsubscribe();
    this.breakPointObserverSubscription?.unsubscribe();
    this.isLoadingScreenOpenSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }
}

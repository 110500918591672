import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Observable, Subscription, tap} from 'rxjs';
import * as BaseSelector from '../../../core/base.selectors';
import * as BaseActions from '../../../core/base.actions';
import {Router} from '@angular/router';
import {DirectoryService} from '../../service/directory.service';
import {getSelectedWorkspace} from '../../../../authentication/core/authentication.selectors';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  sendIcon = 'send';
  userProfileData$: Observable<any> = new Observable();
  userProfileLoadingState$: Observable<any> = new Observable();
  workspaceId: string = ''

  selectedWorkspaceSubscription: Subscription = this.store.select(getSelectedWorkspace).subscribe((data) => {
    this.workspaceId = data.id
  })

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<UserProfileComponent>,
    private router: Router,
    private directoryService: DirectoryService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }


  ngOnInit(): void {
    this.userProfileData$ = this.store.select(
      BaseSelector.getUserProfileForDirectoryPage
    ).pipe(tap(res => {

    }));
    this.userProfileLoadingState$ = this.store.select(
      BaseSelector.getUserProfileLoadingState
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  addDirectMessage(userData) {
    this.directoryService.setDirectMessageToUser(userData);
    const params = {
      newConversation: 'true',
      userId: userData.id
    };
    this.router.navigate(
      [`/workspace/${this.workspaceId}/groups`],
      {
        queryParams: params,
      }
    );
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.selectedWorkspaceSubscription?.unsubscribe();
    this.store.dispatch(BaseActions.ClearUserProfileForDirectoryPage());
  }
}

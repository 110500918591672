import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MilestoneFilesService {
  constructor() {}
  currentPage: BehaviorSubject<number> = new BehaviorSubject(0);
  setCurrentPageNumber(currentPageNumber: number) {
    this.currentPage.next(currentPageNumber);
  }
}
